<header>
  <div class="detail-page-header" *transloco="let t; read: 'dossier'">
    <div class="topbar">
      <div class="left-side">
        <app-back-button [times]="2"></app-back-button>
        <h1>{{t('viewDocument')}}</h1>
      </div>
      <div class="right-side controls">

      </div>
    </div>
  </div>
</header>
<div class="document_full_page">
  <div class="cv-viewer">
    <img src="/assets/images/spinner.svg" width="60" height="60" class="loading_spinner" *ngIf="!docFrameDoneLoading">
    <iframe [src]="documentTempUrl" #iframeElement *ngIf="documentTempUrl" [ngClass]="{'loading': !docFrameDoneLoading}"></iframe>
  </div>
</div>


