import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from './../../core/auth/auth.service';
import { EmailFolderService } from './../../core/services/email-folder.service';
import { AlertService } from './../../core/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { EmailsService } from '../../core/services/emails.service';
import { ListComponent } from './list/list.component';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.sass'],
})

export class EmailsComponent implements OnInit, OnDestroy{

  current_user:User;

  constructor(
    private emailsService: EmailsService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private emailFolderService: EmailFolderService,
    private authService: AuthService,
    private translateService: TranslocoService,
    private readonly tracker: MatomoTracker
  ) {

    this.authService.user.subscribe(res => {
      this.current_user = res;
    })

    // When the component is loaded trough an URL including the folder param in the route, we should read it
    this.route.params.subscribe((params) => {
      if (params['folder']) {
        // If folder is present in the route and it isn't equal to the current folder
        if (this.folder != params['folder']) {
          // Set this as the current folder
          this.folder = params['folder'];
          // Reset the emails object and reset the page
          this.page = 1;
          this.emails = [];
          // And retrieve the emails
          this.loadMails();
        }
      }
    });

  }


  // Add a reference to the list component so we can call methods within it
  @ViewChild(ListComponent, { static: true }) listComponent: ListComponent;

  emails: any = [];
  showLoadMoreButton = true;
  folder: number = -1;
  page: number = 1;
  pageLoading = false;
  // Store the subscriptions inside a variable so we can unsub
  $subscription1: Subscription;
  $subscription2: Subscription;
  $subscription3: Subscription;
  $subscription4: Subscription;
  $subscription5: Subscription;

  searchResults: Subject<any> = new Subject<any>();

  batchItems: number[] = [];
  batchMode: boolean = false;
  batchLoading: boolean = false;

  showFlagDrawer:boolean = false
  currentFlagFilter:any = '0';

  ngOnInit(): void {
    this.loadMails();

  }

  onFlagFilterClicked(){
    this.showFlagDrawer = true;
  }
  onNewFlag(flag){
    this.tracker.trackGoal(12);
    this.currentFlagFilter = flag;
    this.loadMails();
  }

  onNextPage(){
    if(this.pageLoading == false){
      this.page++;
      this.loadMails();
      this.pageLoading = true;
    }
  }


  loadMails() {
    this.$subscription1 = this.emailsService
      .getMailPage(this.folder, this.page,this.currentFlagFilter)
      .subscribe((resData) => {
        if (resData) {
          if(resData.items.length < 20){
            this.showLoadMoreButton = false;
          }else{
            this.showLoadMoreButton = true;
          }
          if(this.emails.find(o => o.page === this.page)){
            this.emails.find((o:{page:number, items:[]}, i:number) => {
              if (o.page === this.page) {
                  this.emails[i] = resData;
                  return true;
              }else{
                return false;
              }
            });
          }else{
            this.emails.push(resData);
          }
          this.addPhotoUrlToEmails();
        }
        this.pageLoading = false;
      });
  }


  addPhotoUrlToEmails(){
    for(let email in this.emails[0].items){
      if(this.emails[0].items[email].Person && this.emails[0].items[email].Person.photoFileOuid){
        this.emails[0].items[email].photourl = environment.fileServiceDownload + '?ouid=' + this.emails[0].items[email].Person.photoFileOuid + '&class=' + this.emails[0].items[email].Person.photoFileClass + '&sessionId=' + this.current_user.sessionId;
      }else{
        this.emails[0].items[email].photourl = false;
      }
    }
  }

  onSingleActionClicked(){
    this.router.navigate(['/emails/compose']);
  }

  onSearchInput(searchInput: string) {
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    // There should be at least 2 characters
    if (searchInput.length > 2) {
      this.$subscription2 = this.emailsService
        .getSearchResults(searchInput)
        .subscribe((resData) => {
          if (resData) {
            this.searchResults.next(resData);
          } else {
            this.searchResults.next([]);
          }
        });
    } else {
      this.searchResults.next([]);
    }
  }

  // The applist component outputs an updated flag so we can change the list
  onFlagUpdated($event: any) {
    let page = $event.page - 1;
    this.emails[page].items[$event.index].tag = $event.flag;
  }

  // The appList component outputs the batchitems using this funtion
  onBatchUidsUpdate(batchItems: number[]) {
    this.batchItems = batchItems;
    if (this.batchItems.length) {
      this.batchMode = true;
    } else {
      this.batchMode = false;
    }
  }

  // When the remove button in the batch toolbar is clicked
  onBatchRemove() {
    this.batchLoading = true;
    this.$subscription3 = this.emailsService
      .moveEmails(this.batchItems, '-3')
      .subscribe((response) => {
        if (response) {
          this.alertService.success(this.translateService.translate('emails.batchRemovedSucces'));
          this.loadMails();
          this.batchMode = false;
          this.batchLoading = false;
          this.listComponent.onResetBatch();
        }
      });
  }

  // When the move button in the batch toolbar is clicked
  onBatchMove() {
    // Reset the clickedItemVariable to be sure
    this.emailFolderService.resetClickedItem();
    // Set the behaviour for the folder drawer to movingMails
    this.emailFolderService.updateClickBehaviour('moveMails');
    // Open the drawer so the user can select a folder
    this.emailFolderService.updateDrawerState(true);
    // Subscribe to the clicking on a folder
    this.$subscription4 = this.emailFolderService.clickedItem.subscribe(clickedFolder => {
      // If this isn't null a folder was selected
      if(clickedFolder){
        // Someone clicked on a folder so we can now ask the email service to move
        this.onBatchMoveToFolder(clickedFolder);
        // Lets show the user something is happening
        this.batchLoading = true;
        // Now unsubscribe from the clickEvents
        this.$subscription4.unsubscribe();
      }
    });
  }

  onBatchMoveToFolder(folder:string){
    // Lets do an HTTP request for moving the mails
    this.$subscription5 = this.emailsService
      .moveEmails(this.batchItems, folder)
      .subscribe((response) => {
        if (response) {
          // Show the user a message it's done succesfully
          this.alertService.success(this.translateService.translate('emails.batchMovedSucces'));
          // Reload the list of emails
          this.loadMails();
          // Close batchmode
          this.batchLoading = false;
          this.batchMode = false;
          this.listComponent.onResetBatch();
        }
      });
  }

  onExitBatchMode(){
    this.batchLoading = false;
    this.batchMode = false;
    this.listComponent.onResetBatch();
  }

  // Unsubscribe ondestroy
  ngOnDestroy(): void {
    if (this.$subscription1) {
      this.$subscription1.unsubscribe();
    }
    if (this.$subscription2) {
      this.$subscription2.unsubscribe();
    }
    if (this.$subscription3) {
      this.$subscription3.unsubscribe();
    }
    if (this.$subscription4) {
      this.$subscription4.unsubscribe();
    }
    if (this.$subscription5) {
      this.$subscription5.unsubscribe();
    }
  }

}
