import { Router } from '@angular/router';
import { drawerListItem } from './../../../core/interfaces/drawer-list.interface';
import { Subscription } from 'rxjs';
import { InteractionService } from './../../../core/services/interaction.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-create-candidate',
  templateUrl: './create-candidate.component.html',
  styleUrls: ['./create-candidate.component.sass']
})
export class CreateCandidateComponent implements OnInit {

  $candidateFormsSubscription:Subscription;
  candidateInteractionForms:drawerListItem[] = [];
  showFormsDrawer:boolean = false;

  constructor(private interactionService: InteractionService, private router: Router) {}

  ngOnInit(): void {
    // Enable when ready
    //this.getcandidateInteractionForms();
  }

  getcandidateInteractionForms(){
    this.$candidateFormsSubscription = this.interactionService.getCandidateInsertionForms().subscribe(res => {
      if(res){
        let interactionFormList = [];
        res.forEach(function(formItem){
          interactionFormList.push({
            title: formItem.name,
            icon: '<i class="fa-solid fa-pen-field"></i>',
            uid: formItem.uid
          });
        });
        this.candidateInteractionForms = interactionFormList;
      }
    });
  }


  onShowForms(){
    this.showFormsDrawer = true;
  }

  onUpdateDrawerState(newstate:boolean){
    this.showFormsDrawer = newstate;
  }

  onDrawerItemClicked(formId:number){
    this.router.navigate(['/interactions/' + formId]);
  }

  ngOnDestroy(): void {
    if(this.$candidateFormsSubscription){
      this.$candidateFormsSubscription.unsubscribe();
    }
  }

}
