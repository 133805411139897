import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { StoredQueryTransformerService } from './storedquery-transformer.service';

@Injectable()
export class RelationContactService {
  private relationContactList = new BehaviorSubject<any>(null);
  private relationContactSearchResultList = new BehaviorSubject<any>(null);
  private relationContactDetail = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private cachingService: CachingService,
    private storedQueryTransformerService: StoredQueryTransformerService
  ) {}

  getRelationContactList(page: number = 1, storedQueryData:any = null) {
    let limit = 20;
    let offset = 0;

    if (page != 1) {
      offset = page * limit - limit;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationContactService.getListEx';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        what: {
          uid: 1,
          Person: {
            lastName: 1,
            firstName: 1,
            infix: 1,
            photoFileName: 1
          },
          relation: 1
        },
        limit: limit,
        offset: offset,
        getTotalCount: 1,
        sort: {
          entryDateTime: "DESC"
        },
        excludeLimitCheck: true,
        relationConditions: {
          ACTONOMY: {
            DATA: {},
            VERSION: 2,
            SUB_VERSION: 0
          }
        }
      }
    ];

    if(storedQueryData){
      let transformed = this.storedQueryTransformerService.transform(storedQueryData);
      if(transformed.conditional){
        requestBody[0].condition = transformed.conditional;
      }
      requestBody[0].relationConditions.ACTONOMY.DATA = transformed.actonomy;
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    if (page == 1) {
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(
        requestMethod,
        requestBody
      );
      if (cached_data) {
        if (cached_data.result.listOutput.length) {
          this.relationContactList.next({
            page: page,
            items: cached_data.result.listOutput,
          });
        }
      } else {
        this.relationContactList.next(null);
      }
    } else {
      this.relationContactList.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result.listOutput) {
            this.relationContactList.next({
              page: page,
              items: resData.result.listOutput,
            });
          }else{
            if (resData.result.listOutput) {
              this.relationContactList.next({
                page: page,
                items: [],
              });
            }
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.relationContactList.asObservable();
  }

  getSearchResults(searchInput: string) {

    this.relationContactSearchResultList.next(null);

    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationContactService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          Person: {
            lastName: 1,
            firstName: 1,
            infix: 1,
            photoFileName: 1
          },
          relation: 1
        },
        limit: 75,
        offset: 0,
        getTotalCount: 1,
        sort: {
          entryDateTime: "DESC"
        },
        excludeLimitCheck: true,
        relationConditions: {
          ACTONOMY: {
            DATA: {},
            VERSION: 2,
            SUB_VERSION: 0
          }
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "OR",
              items: [
                {
                  type: "COND",
                  field: "Person.firstName",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                },
                {
                  type: "COND",
                  field: "Person.infix",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                },
                {
                  type: "COND",
                  field: "Person.lastName",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                },
                {
                  type: "COND",
                  field: "Person.emailPrimary",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                },
                {
                  type: "COND",
                  field: "Person.phoneMobile",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                },
                {
                  type: "COND",
                  field: "directNr",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                },
                {
                  type: "COND",
                  field: "phonePrimaryNumber",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                }
              ]
            }
          ]
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    this.http
    .post<any>(environment.owsUrl, requestBody, requestHeaders)
    .subscribe((resData) => {
      if (resData) {
        if (resData.result.listOutput) {
          this.relationContactSearchResultList.next({
            page: 1,
            items: resData.result.listOutput,
          });
        }
      }
    });

    // Return the relation list as an observable so other components can listen to any updates
    return this.relationContactSearchResultList.asObservable();
  }

  getRelationContactDetail(uid: string) {
    // Define the OWS method
    const requestMethod = 'Otys.Services.RelationContactService.getDetail';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        uid: 1,
        entryDateTime: 1,
        lastModified: 1,
        user: 1,
        userId: 1,
        lastModifiedBy: 1,
        isPassive: 1,
        jobTitle: 1,
        relation: 1,
        relationId: 1,
        Person: {
          title: 1,
          initials: 1,
          firstName: 1,
          infix: 1,
          lastName: 1,
          suffix: 1,
          gender: 1,
          statusId: 1,
          statusName: 1,
          PhoneNumbers: {
            type: 1,
            phoneNumber: 1
          },
          photoFileName: 1,
          emailPrimary: 1
        }
      },
      null,
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.relationContactDetail.next(cached_data.result);
    } else {
      this.relationContactDetail.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
            this.relationContactDetail.next(resData.result);
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.relationContactDetail.asObservable();
  }





  getVacancies(relationContactUid:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.VacancyService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          title: 1,
          applyCount:1,
          status: 1,
          user: 1,
          location: 1
        },
        limit: 100,
        offset: 0,
        getTotalCount: 1,
        sort: {
          entryDateTime: "DESC"
        },
        excludeLimitCheck: true,
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "relationContactId",
              op: "EQ",
              param: relationContactUid
            }
          ]
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
 }

}
