import { TranslocoService } from '@ngneat/transloco';
import { FileUploadService } from './../../core/services/file-upload.service';
import { drawerListItem } from './../../core/interfaces/drawer-list.interface';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-picture-detail',
  templateUrl: './picture-detail.component.html',
  styleUrls: ['./picture-detail.component.sass']
})
export class PictureDetailComponent implements OnInit, OnChanges {

  @Input() photoUrl:string;
  @Input() showDetailed:boolean = false;

  loadStep1:boolean = false;
  loadStep2:boolean = false;

  openDrawer:boolean = false;

  documentFileUid:string;
  documentFileName:string;
  @ViewChild('fileinput') fileinput:ElementRef;

  @Output() pictureState: EventEmitter<boolean> = new EventEmitter();

  @Output() pictureUploadStart: EventEmitter<boolean> = new EventEmitter();
  @Output() uploadedFileName: EventEmitter<string> = new EventEmitter();
  @Output() pictureRemove: EventEmitter<boolean> = new EventEmitter();

  $subscription1:Subscription;

  constructor(private fileUploadService: FileUploadService, private translationService: TranslocoService,private readonly tracker: MatomoTracker) {}


   drawerList:drawerListItem[] = [
    {
     title: this.translationService.translate('shared.uploadANewPhoto'),
     icon: '<i class="fa-solid fa-camera"></i>',
     uid: '1'
    },
    {
      title: this.translationService.translate('shared.deletePhoto'),
      icon: '<i class="fa-solid fa-trash"></i>',
      uid: '2'
     }
  ];

  ngOnInit(): void {
  }


  ngOnChanges(): void {
    if(this.showDetailed == true){

      this.tracker.trackGoal(14);

      if(!this.photoUrl){
        this.drawerList[1].disabled = true;
      }else{
        this.drawerList[1].disabled = false;
      }


      setTimeout(() => {
        this.loadStep1 = true;
      }, 100)
      setTimeout(() => {
        this.loadStep2 = true;
        this.openDrawer = true;
      }, 200)
    }
  }

  onCloseDetail(){
    this.loadStep2 = false;
    setTimeout(() => {
      this.loadStep1 = false;
    }, 100)
    setTimeout(() => {
      this.pictureState.emit(false);
    }, 200)
  }

  onAddAttachment() {
    let el: HTMLElement = this.fileinput.nativeElement;
    el.click();
  }

  onFileSelected(files: FileList) {
    this.pictureUploadStart.emit(true);
    const file = files[0];
    const filename = file.name;

    this.$subscription1 = this.fileUploadService.uploadFile(file).subscribe((response) => {
      this.documentFileUid = response[filename].ouid;
      this.documentFileName = response[filename].name;
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(this.documentFileName)[1];
      this.uploadedFileName.emit(this.documentFileUid + '.' + ext);

      this.tracker.trackGoal(15);

      this.documentFileName = null;
      this.documentFileUid = null;
      this.$subscription1.unsubscribe();
    });
  }



  onDrawerStateChange(newstate:boolean){
    this.openDrawer = newstate;
    this.onCloseDetail();
  }

  onDrawerItemClicked(itemUid:string){
    if(itemUid == '1'){
      this.onAddAttachment();
    }

    if(itemUid == '2'){
      this.pictureRemove.emit(true);
    }
  }


}
