import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from './../../../core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { RelationContactService } from 'src/app/core/services/relation-contact.service';
import { relationContactResult } from 'src/app/core/interfaces/relation-contact.interface';
import { Subscription } from 'rxjs';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-relations-contact-detail',
  templateUrl: './relations-contact-detail.component.html',
  styleUrls: ['./relations-contact-detail.component.sass']
})


export class RelationsContactDetailComponent implements OnInit,OnDestroy {

  relationContactLoaded: boolean = false;
  relationContactUid: string;
  relationContact: relationContactResult;
  relationContactPhotoUrl: string;
  vacanciesEnabled:boolean = false;
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = ['General', 'Dossier', 'Vacancies'];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    },
  };
  current_user: User;
  relationContactVacancies:any = [];
  showDossier:boolean = false;
  documentTypeDrawer:any;

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;
  $subscription5:Subscription;


  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(
    private relationContactService: RelationContactService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslocoService
  ) {
    this.$subscription1 = this.authService.user.subscribe((res) => {
      this.current_user = res;
    });

    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.$subscription2 = this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.relationContactUid != params['uid']) {
          // Set this as the current uid
          this.relationContactUid = params['uid'];
        }
      }
    });
  }


  ngOnInit(): void {
    this.loadRelationContact();
    this.getVacancies();
    this.loadTabTranslations();
  }

  loadRelationContact() {
    this.$subscription3 = this.relationContactService
      .getRelationContactDetail(this.relationContactUid)
      .subscribe((response) => {
        if (response) {
          this.relationContact = response;
          this.addFullName();
          this.addSinglePhone();
          this.addPhotoUrl();
          this.relationContactLoaded = true;
          this.showDossier = true;
        }
      });
  }

  addFullName(){
    this.relationContact.fullname = '';
    if(this.relationContact.Person.firstName)
      this.relationContact.fullname += this.relationContact.Person.firstName;
    if(this.relationContact.Person.infix)
      this.relationContact.fullname += ' ' + this.relationContact.Person.infix;
    if(this.relationContact.Person.lastName)
      this.relationContact.fullname += ' ' + this.relationContact.Person.lastName;
  }

  addSinglePhone(){
    this.relationContact.phone = '';
    if(this.relationContact.Person.PhoneNumbers["1"].phoneNumber && this.relationContact.phone == '')
      this.relationContact.phone = this.relationContact.Person.PhoneNumbers["1"].phoneNumber
    if(this.relationContact.Person.PhoneNumbers["2"].phoneNumber && this.relationContact.phone == '')
      this.relationContact.phone = this.relationContact.Person.PhoneNumbers["2"].phoneNumber
    if(this.relationContact.Person.PhoneNumbers["3"].phoneNumber && this.relationContact.phone == '')
      this.relationContact.phone = this.relationContact.Person.PhoneNumbers["3"].phoneNumber
    if(this.relationContact.Person.PhoneNumbers["4"].phoneNumber && this.relationContact.phone == '')
      this.relationContact.phone = this.relationContact.Person.PhoneNumbers["4"].phoneNumber
    if(this.relationContact.Person.PhoneNumbers["5"].phoneNumber && this.relationContact.phone == '')
      this.relationContact.phone = this.relationContact.Person.PhoneNumbers["5"].phoneNumber
    if(this.relationContact.Person.PhoneNumbers["6"].phoneNumber && this.relationContact.phone == '')
      this.relationContact.phone = this.relationContact.Person.PhoneNumbers["6"].phoneNumber
  }

  addPhotoUrl() {
    let sess = this.current_user.sessionId;
    this.relationContactPhotoUrl =
      environment.fileServiceDownload +
      '?ouid=' +
      this.relationContactUid +
      '&class=RelationContactPhoto&sessionId=' +
      sess;
  }

  getVacancies(){
    if(this.current_user.modules.includes('Vacancies')){
      this.$subscription4 = this.relationContactService.getVacancies(this.relationContactUid).subscribe(res => {
        if(res && res.result && res.result.listOutput.length){
          this.relationContactVacancies = res.result.listOutput;
        }
      });
    }
  }

  loadTabTranslations(){
    this.$subscription5 = this.translationService.selectTranslate('relationContacts.tabGeneral').subscribe(res => {
      // Get the translation values for the tabs
      let tabs = [
        this.translationService.translate('relationContacts.tabGeneral'),
        this.translationService.translate('relationContacts.tabDossier'),
        this.translationService.translate('relationContacts.tabVacancies')
      ];
      // Set the new renderBullet function for the new translations
      this.config.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + tabs[index] + '</span>';
        },
      };
    });
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }
    if(this.$subscription5){
      this.$subscription5.unsubscribe();
    }
  }

}




