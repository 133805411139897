<ng-container *transloco="let t; read: 'emails'">
<div class="calendar-item" *ngIf="calItem.show">
  <div class="calendar-item-inner">
    <div class="icon-holder">
      <div class="icon basic">
        <i class="fa-solid fa-calendar"></i>
      </div>
    </div>
    <div class="content-holder">
      <div class="calender-item-title">{{calItem.title}}</div>
      <div class="calendar-item-date">{{calItem.start | customDate}} - {{calItem.end | customDate}}</div>
      <div class="calendar-item-buttons" *ngIf="calItem.status == 'pending' && loading == false">
        <button (click)="onAcceptInvite()">{{t('acceptCalendarItemInMail')}}</button>
        <button class="small-secundary" (click)="onDeclineInvite()">{{t('declineCalendarItemInMail')}}</button>
      </div>

      <div class="statuswrap" *ngIf="calItem.status == 'accepted' || calItem.status == 'rejected'">
        <div class="calendar-item-status accepted" *ngIf="calItem.status == 'accepted' && loading == false">
          <i class="fa-solid fa-circle-check"></i>
          <span class="status-text">{{t('acceptedCalendarItemInMail')}}</span>
        </div>
        <div class="calendar-item-status rejected" *ngIf="calItem.status == 'rejected' && loading == false">
          <i class="fa-sharp fa-solid fa-circle-xmark"></i>
          <span class="status-text">{{t('rejectedCalendarItemInMail')}}</span>
        </div>
      </div>


      <div class="loading" *ngIf="loading">
        <img src="/assets/images/spinner.svg" width="40" height="40" class="loading_spinner">
      </div>
    </div>
  </div>
  <div class="conflict-status" *ngIf="calItem.show && calItem.status == 'pending' && !loading">
    <div class="status-text checking" *ngIf="callConflict === 0">
      {{t('checkingForConflicts')}}
    </div>
    <div class="status-text conflict" *ngIf="callConflict === 1" (click)="onShowEvents()">
      <span class="icon-arrow" *ngIf="!showItemsCloseBy">
        <i class="fa-solid fa-circle-chevron-down"></i>
      </span>
      <span class="icon-arrow" *ngIf="showItemsCloseBy">
        <i class="fa-solid fa-circle-chevron-up"></i>
      </span>
      &nbsp;&nbsp;{{t('conflictingEventsFound')}}
    </div>
  </div>
  <div class="close-by-events" *ngIf="calItemsCloseBy.length && showItemsCloseBy">
    <div class="events">
      <div class="event" *ngFor="let event of calItemsCloseBy">
        <div class="event-start">
          {{event.startDateTime|date:'shortTime'}}
        </div>
        <div class="event-color" [ngStyle]="event.typeColor != '#' && {backgroundColor: '#' + event.typeColor}"></div>
        <div class="event-content">
          <div class="event-title">{{event.title}}</div>
          <div class="event-time">
            <span class="start">{{event.startDateTime|date:'shortTime'}}</span> - <span class="end">{{event.endDateTime|date:'shortTime'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
