<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of contacts;">
    <div class="list-item list-item-relation" *ngFor="let contact of page.items" (click)="onContactClicked(contact)">
      <div class="list-image" [ngStyle]="{'background-image': (contact.Person.photoFileName) ? 'url('+contact.logoUrl+')' : ''}">
        <ng-template [ngIf]="!contact.Person.photoFileName">
          <span *ngIf="contact.Person.firstName">
            {{ contact.Person.firstName | getInitials}}
          </span>
        </ng-template>
      </div>
      <div class="list-item-content">
        <div class="list-item-content-left" *transloco="let t; read: 'entities'">
          <div class="list-item-subject semibold">{{contact.Person.firstName}} {{contact.Person.lastName}}</div>
          <div class="list-item-subtitle" *ngIf="contact.entityId == 14">{{t('consultant') | capitalize}}</div>
          <div class="list-item-subtitle" *ngIf="contact.entityId == 4">{{contact.relation}}</div>
          <div class="list-item-subtitle" *ngIf="contact.entityId == 2">{{t('candidate') | capitalize}}</div>
        </div>
        <div class="list-item-content-right" *ngIf="contact.invitatationState !== -2">
          <div class="listicon" [ngClass]="{'accepted' : contact.invitatationState == 1, 'pending' : (contact.invitatationState == 0 || contact.invitatationState == -1), 'declined' : contact.invitatationState == 2}">
            <i class="fa-sharp fa-solid fa-circle-check" *ngIf="contact.invitatationState == 1"></i>
            <i class="fa-sharp fa-solid fa-clock" *ngIf="contact.invitatationState == 0 || contact.invitatationState == -1"></i>
            <i class="fa-solid fa-circle-xmark" *ngIf="contact.invitatationState == 2"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
