import { AuthService } from 'src/app/core/auth/auth.service';
import { Injectable } from "@angular/core";
import { theme } from "../interfaces/settings.interface";
import { User } from '../auth/user.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Injectable()
export class SettingsService{

  currentSettingVersion = '0.96';
  themeObservable = new BehaviorSubject<boolean>(false);
  langObservable = new BehaviorSubject<string>(null);

  private settings:any = {
    version: this.currentSettingVersion,
    values: {
      '01theme': {
        name: "appTheme",
        value: {
          themeMain: "#0c88e0",
          themeBackground: "#F3F5FF"
        },
        type: "theme"
      },
      '02dashboardLayout': {
        name: "dashboardLayout",
        value: [
          {
            name: "email",
            component: "app-mailwidget",
            limit: 5,
            enabled: true
          },
          {
            name: "reminders",
            component: "app-reminderwidget",
            limit: 15,
            enabled: true
          },
          {
            name: "candidates",
            component: "app-candidatewidget",
            limit: 5,
            enabled: true
          }
        ],
        type: "dashboardLayout"
      },
      '03candidateFilterOnDashboard': {
        name: 'candidatePreference',
        value: 0,
        answers: ["favoriteCandidates","latestCandidates"],
        type: 'candidateWidgetFilter'
      },
      '04emailAutoResponder': {
        name: 'emailAutoResponder',
        value: 'GE38',
        type: 'emailAutoResponder'
      },
      '05darkMode': {
        name: 'darkMode',
        value: 2,
        answers: ['enabled', 'system', 'disabled'],
        type: 'darkMode'
      },
      '06language': {
        name: 'language',
        value: 0,
        answers: ['Default', 'en', 'nl', 'de', 'fr', 'cs', 'es', 'us', 'pl', 'ro', 'ru', 'sk', 'tr', 'uk'],
        type: 'select'
      },
      '07calendarShowWeekends': {
        name: 'calendarShowWeekends',
        value: true,
        type: 'trueFalse'
      }
    }
  };

  current_user:User;

  constructor(private authService: AuthService,private http: HttpClient, private readonly tracker: MatomoTracker){
    this.authService.user.subscribe(res => {
      this.current_user = res;
      if(this.current_user && this.current_user.userSettings){
        // When there is a value for this users settings we can check if they have the correct setting version otherwise we will ignore
        if(this.current_user.userSettings.version == this.currentSettingVersion){
          this.settings = this.current_user.userSettings;
        }else{
          if(this.current_user.canUseApp){
            this.saveInLocalStorage();
          }
        }
      }
    });
  }

  getSettings(){
    return this.settings.values;
  }

  getSetting(key:string){
    return this.settings.values[key];
  }

  setSetting(key:string,value:boolean|string|number|theme){
    this.settings.values[key].value =  value;
    this.saveInLocalStorage();
    if(key == '01theme' || key == '05darkMode'){
      this.triggerThemeChange();
      //window.location.href = window.location.href;
    }

    if(key == '06language'){
      if(value !== 0){
        let setVal:number = parseInt(value.toString());
        let settingTemp = this.getSetting('06language');
        let langCode = settingTemp.answers[setVal];
        localStorage.setItem('lastLanguage', langCode);
        this.langObservable.next(langCode);
      }
    }

    if(key == '05darkMode'){
      this.tracker.trackGoal(3);
    }

    if(key == '02dashboardLayout'){
      this.tracker.trackGoal(5);
    }

  }

  triggerThemeChange(){
    this.themeObservable.next(true);
  }

  saveInLocalStorage(){
    this.authService.setUserSettings(this.settings);
    this.saveInPartnerSettings().subscribe(res => {
      // No action needed
    });
  }

  saveInPartnerSettings(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CsmService.setValue';
    // Define the body of the request (params)
    const requestBody:any = [
      "PA81",
      JSON.stringify(this.settings),
      this.current_user.clientId,
      this.current_user.id,
      0
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

}
