import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-dashboard-contactlist',
  templateUrl: './dashboard-contactlist.component.html',
  styleUrls: ['./dashboard-contactlist.component.sass']
})
export class DashboardContactlistComponent{

  @Input() contacts:any;

  constructor() { }
}
