<app-header *transloco="let t; read: 'reminders'" listType="reminder" [title]="t('pageTitle') | capitalize"
  [searchPlaceHolder]="t('searchTitle')" [additionalSection]="reminderSwitch" (onSearchInput)="onSearchInput($event)"
  [searchResults]="searchResults.asObservable()"></app-header>

<ng-template #reminderSwitch>
  <div class="tabs-nav reminders-nav">
    <div class="swiper-pagination"></div>
  </div>
</ng-template>

<swiper #swiper [config]="config" *transloco="let t; read: 'reminders'">
  <ng-template swiperSlide>
    <div class="page reminder-page">
      <app-reminder-list [reminders]="pastReminders"></app-reminder-list>
      <app-no-results *ngIf="pastReminders && pastReminders.length && !pastReminders[0].items.length"></app-no-results>
      <app-load-more-button [showLoadMoreButton]="showLoadMoreButtonPast" (buttonClicked)="onNextPagePast();">
      </app-load-more-button>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="page reminder-page">
      <app-reminder-list [reminders]="futureReminders"></app-reminder-list>
      <app-no-results *ngIf="futureReminders && futureReminders.length && !futureReminders[0].items.length"></app-no-results>
      <app-load-more-button [showLoadMoreButton]="showLoadMoreButtonFuture" (buttonClicked)="onNextPageFuture();">
      </app-load-more-button>
    </div>
  </ng-template>
</swiper>


<app-action-button-single (onClick)="onSingleActionClicked()" [icon]="floatingActionIcon"></app-action-button-single>
