import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-criteria-item',
  templateUrl: './candidate-criteria-item.component.html',
  styleUrls: ['./candidate-criteria-item.component.sass']
})
export class CandidateCriteriaItemComponent implements OnInit {

  @Input() criteriaName:string;
  @Input() criteriaValues:any;

  showItem:boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(this.criteriaValues.length === 0 || this.criteriaValues.length === null){
      this.showItem = false;
    }else{
      this.showItem = true;
    }
  }

}
