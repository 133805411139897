<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of contacts;">

    <ng-container *ngFor="let contact of page.items">

      <ng-template [ngIf]="contact.entityId == 14">
        <div class="list-item list-item-relation" [routerLink]="['/emails/compose']" [queryParams]="{email : contact.Person.emailPrimary}">
          <div class="list-image" [ngStyle]="{'background-image': (contact.Person.photoFileName) ? 'url('+contact.logoUrl+')' : ''}">
            <ng-template [ngIf]="!contact.Person.photoFileName">
              <span *ngIf="contact.Person.firstName">
                {{ contact.Person.firstName | getInitials}}
              </span>
            </ng-template>
          </div>
          <div class="list-item-content">
            <div class="list-item-content-left" *transloco="let t; read: 'entities'">
              <div class="list-item-subject semibold">{{contact.Person.firstName}} {{contact.Person.lastName}}</div>
              <div class="list-item-subtitle">{{t('consultant') | capitalize}}</div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="contact.entityId == 4">
        <div class="list-item list-item-relation" [routerLink]="['/relation-contacts/' + contact.uid]">
          <div class="list-image" [ngStyle]="{'background-image': (contact.Person.photoFileName) ? 'url('+contact.logoUrl+')' : ''}">
            <ng-template [ngIf]="!contact.Person.photoFileName">
              <span *ngIf="contact.Person.firstName">
                {{ contact.Person.firstName | getInitials}}
              </span>
            </ng-template>
          </div>
          <div class="list-item-content">
            <div class="list-item-content-left" *transloco="let t; read: 'entities'">
              <div class="list-item-subject semibold">{{contact.Person.firstName}} {{contact.Person.lastName}}</div>
              <div class="list-item-subtitle">{{contact.relation}}</div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="contact.entityId == 2">
        <div class="list-item list-item-relation" [routerLink]="['/candidates/' + contact.uid]">
          <div class="list-image" [ngStyle]="{'background-image': (contact.Person.photoFileName) ? 'url('+contact.logoUrl+')' : ''}">
            <ng-template [ngIf]="!contact.Person.photoFileName">
              <span *ngIf="contact.Person.firstName">
                {{ contact.Person.firstName | getInitials}}
              </span>
            </ng-template>
          </div>
          <div class="list-item-content">
            <div class="list-item-content-left" *transloco="let t; read: 'entities'">
              <div class="list-item-subject semibold">{{contact.Person.firstName}} {{contact.Person.lastName}}</div>
              <div class="list-item-subtitle">{{t('candidate') | capitalize}}</div>
            </div>
          </div>
        </div>
      </ng-template>

    </ng-container>
  </div>
</div>
