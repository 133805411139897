import { AuthService } from './../../core/auth/auth.service';
import { Component, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.sass']
})

export class ProfilePictureComponent implements OnDestroy {

  profile_picture_url:string;
  $subscription1:Subscription;

  constructor(private authService: AuthService) {
    this.$subscription1 = this.authService.user.subscribe(res => {
      if(res.id){
        this.profile_picture_url = environment.fileServiceDownload + '?ouid=' + res.id + '&class=UserPhoto' + '&sessionId=' + res.sessionId;
      }
    });
  }
  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
  }

}
