import { reminderDetail } from './../interfaces/reminder.interface';
import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { BehaviorSubject, map } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/user.model';
import { reminder } from '../interfaces/reminder.interface';
import { MatomoTracker } from '@ngx-matomo/tracker';


@Injectable()
export class ReminderService{

  private dashboardReminders = new BehaviorSubject<reminder[]>([]);

  private pastReminders = new BehaviorSubject<any>([]);
  private futureReminders = new BehaviorSubject<any>([]);

  private reminderSearchResultsList = new BehaviorSubject<any>(null);

  private userOptionList = new BehaviorSubject<[]>([]);
  current_user:User;


  constructor(private http: HttpClient, private cachingService: CachingService,private authService: AuthService, private readonly tracker: MatomoTracker){
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }

  getFutureReminders(page:number){
    let limit = 20;
    let offset = 0;

    if(page != 1){
      offset = page * limit - limit;
    }

    var date_1 = new Date();
    date_1.setHours(0,0,0,0);
    var date_2 = new Date();
    date_2.setHours(23,59,59,59);
    date_2.setDate(date_2.getDate() + 3000);
    var sort = 'ASC';

    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        condition: {
          type: "AND",
          invert: false,
          items: [
            {
              type: "COND",
              op: "GT",
              field: "dateTime",
              param: date_1
            },
            {
              type: "COND",
              op: "LT",
              field: "dateTime",
              param: date_2
            },
            {
              type: "COND",
              op: "EQ",
              field: "forUserId",
              param: this.current_user.id
            }
          ]
        },
        what: {
          uid: 1,
          title: 1,
          dateTime: 1,
          description: 1
        },
        offset: offset,
        limit: limit,
        sort: {
          dateTime: sort
        },
        getTotalCount: 1
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };


    if(page == 1){
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(requestMethod, requestBody);
      if(cached_data){
        if(cached_data.result.listOutput.length){
          this.futureReminders.next({
            page: page,
            items: cached_data.result.listOutput
          });
        }
      }else{
        this.futureReminders.next(null);
      }
    }else{
      this.futureReminders.next(null);
    }


    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData && resData.result){
        this.futureReminders.next({
          page: page,
          items: resData.result.listOutput
        });
      }
    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.futureReminders.asObservable();
  }

  getPastReminders(page:number){
    let limit = 20;
    let offset = 0;

    if(page != 1){
      offset = page * limit - limit;
    }

    var date_1 = new Date();
    date_1.setDate(date_1.getDate() - 3000);
    date_1.setHours(0,0,0,0);
    var date_2 = new Date();
    date_2.setHours(0,0,0,0);
    var sort = 'DESC';

    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        condition: {
          type: "AND",
          invert: false,
          items: [
            {
              type: "COND",
              op: "GT",
              field: "dateTime",
              param: date_1
            },
            {
              type: "COND",
              op: "LT",
              field: "dateTime",
              param: date_2
            },
            {
              type: "COND",
              op: "EQ",
              field: "forUserId",
              param: this.current_user.id
            }
          ]
        },
        what: {
          uid: 1,
          title: 1,
          dateTime: 1,
          description: 1
        },
        offset: offset,
        limit: limit,
        sort: {
          dateTime: sort
        },
        getTotalCount: 1
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true',
        'ows-cachettl': '1'
      },
    };


    if(page == 1){
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(requestMethod, requestBody);
      if(cached_data){
        if(cached_data.result.listOutput.length){
          this.pastReminders.next({
            page: page,
            items: cached_data.result.listOutput
          });
        }
      }else{
        this.pastReminders.next(null);
      }
    }else{
      this.pastReminders.next(null);
    }


    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData && resData.result){
        this.pastReminders.next({
          page: page,
          items: resData.result.listOutput
        });
      }
    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.pastReminders.asObservable();
  }

  getSearchResults(searchInput:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.getReminderWithPermissionList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          fromUserId: 1,
          fromUser: 1,
          forUser: 1,
          forUserId: 1,
          title: 1,
          dateTime: 1,
          description: 1,
          postponed: 1,
          isRead: 1,
          smsOnly: 1,
          mobileNotification: 1,
          lastModified: 1,
          private: 1
        },
        condition: {
          type: "AND",
          invert: false,
          items: [
            {
              type: "OR",
              invert: false,
              items: [
                {
                  type: "COND",
                  field: "description",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                },
                {
                  type: "COND",
                  field: "title",
                  op: "LIKE",
                  param: "%"+searchInput+"%"
                }
              ]
            },
            {
              type: "OR",
              invert: false,
              items: [
                {
                  type: "OR",
                  invert: false,
                  items: [
                    {
                      type: "COND",
                      op: "EQ",
                      field: "forUserId",
                      param: this.current_user.id
                    }
                  ]
                }
              ]
            }
          ]
        },
        offset: 0,
        limit: 250,
        getTotalCount: 1
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    this.http
    .post<any>(environment.owsUrl, requestBody, requestHeaders)
    .subscribe((resData) => {
      if (resData) {
        if (resData.result.listOutput) {
          this.reminderSearchResultsList.next({
            page: 1,
            items: resData.result.listOutput,
          });
        }
      }
    });

    // Return the relation list as an observable so other components can listen to any updates
    return this.reminderSearchResultsList.asObservable();
  }

  getReminders(limit:number){

    var dateToday = new Date();
    dateToday.setHours(0,0,0,0);
    var dateFuture = new Date();
    dateFuture.setDate(dateFuture.getDate() + 30);
    dateFuture.setHours(23,59,59,0);

    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        condition: {
          type: "AND",
          invert: false,
          items: [
            {
              type: "COND",
              op: "GT",
              field: "dateTime",
              param: dateToday
            },
            {
              type: "COND",
              op: "LT",
              field: "dateTime",
              param: dateFuture
            },
            {
              type: "COND",
              op: "EQ",
              field: "forUserId",
              param: this.current_user.id
            }
          ]
        },
        what: {
          uid: 1,
          title: 1,
          dateTime: 1,
          description: 1
        },
        offset: 0,
        limit: limit,
        sort: {
          dateTime: "ASC"
        },
        getTotalCount: 1
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data && cached_data.result){
      this.dashboardReminders.next(cached_data.result.listOutput);
    }else{
      this.dashboardReminders.next(null);
    }

    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData && resData.result){
        this.dashboardReminders.next(resData.result.listOutput)
      }

    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.dashboardReminders.asObservable();
  }


  getReminderDetail(uid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.getDetail';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        title  : 1,
        dateTime: 1,
        description: 1,
        forUserId: 1,
        forUser: 1,
        fromUserId: 1,
        fromUser: 1
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };
    // Return the mailWidget as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  getUserOptionList(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.getOptionLists';
    // Define the body of the request (params)
    const requestBody = [
      [
        "forUserId"
      ]
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data && cached_data.result){
      this.userOptionList.next(cached_data.result.forUserId);
    }else{
      this.userOptionList.next(null);
    }

    // Retrieve live data
    this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
      if(resData && resData.result){
        this.userOptionList.next(resData.result.forUserId)
      }

    });

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.userOptionList.asObservable();

  }

  removeReminder(uid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.delete';
    // Define the body of the request (params)
    const requestBody = [
      uid
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };
    // Return the mailWidget as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  saveReminder(uid:number, reminder:reminderDetail){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.update';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        dateTime: reminder.dateTime,
        title: reminder.title,
        description: reminder.description,
        forUserId: reminder.forUserId
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };
    // Return the mailWidget as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  createReminder(reminder:reminderDetail){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ReminderService.add';
    // Define the body of the request (params)
    const requestBody = [
      {
        dateTime: reminder.dateTime,
        title: reminder.title,
        description: reminder.description,
        forUserId: reminder.forUserId,
        fromUserId: reminder.fromUserId,
        isRead : false
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.tracker.trackGoal(6);

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


}
