<app-header *transloco="let t; read: 'settings'" [showVersion]="true" [showLoading]="false" [title]="t('pageTitle') | capitalize" [showSearchBar]="false" [additionalSection]="swiperNav"></app-header>

<ng-template #swiperNav>
  <div class="tabs-nav settings-page-tab-nav">
    <div class="swiper-pagination"></div>
  </div>
</ng-template>

<swiper #swiper [config]="config" *transloco="let t; read: 'settings'">
  <ng-template swiperSlide>
    <div class="tab">
      <app-setting-tab [allowedSettings]="['01theme', '05darkMode', '06language']"></app-setting-tab>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="tab">
      <app-setting-tab [allowedSettings]="['02dashboardLayout','03candidateFilterOnDashboard']"></app-setting-tab>
    </div>
  </ng-template>
  <ng-template swiperSlide *ngIf="this.current_user.modules.includes('Emails')">
    <div class="tab">
      <app-setting-tab [allowedSettings]="['04emailAutoResponder']"></app-setting-tab>
    </div>
  </ng-template>
  <ng-template swiperSlide *ngIf="this.current_user.modules.includes('Calendar')">
    <div class="tab">
      <app-setting-tab [allowedSettings]="['07calendarShowWeekends']"></app-setting-tab>
    </div>
  </ng-template>
</swiper>
