import { RelationService } from './../../core/services/relation.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-relation-autosuggest',
  templateUrl: './relation-autosuggest.component.html',
  styleUrls: ['./relation-autosuggest.component.sass']
})
export class RelationAutosuggestComponent{

  @Output() selectedRelation: EventEmitter<string> = new EventEmitter();

  fieldValue:string;
  autoSuggestValues:any = [];
  showInput:boolean = true;
  result:any;

  constructor(private relationService: RelationService) { }

  onInput(){
    if(this.fieldValue.length  > 2){
      this.relationService.suggestRelation(this.fieldValue).subscribe(response => {
        if(response){
          this.autoSuggestValues = response.result;
        }
      });
    }
  }

  onSelectItem(suggestItem:any){
    this.selectedRelation.emit(suggestItem.uid);
    this.result = suggestItem;
    this.showInput = false;
  }


  onReset(){
    this.result = null;
    this.showInput = true;
    this.autoSuggestValues = [];
    this.fieldValue = '';
    this.selectedRelation.emit('');
  }

}
