import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
  ElementRef,
} from '@angular/core';
import { fromEvent, pipe, Observable } from 'rxjs';
import { delay, map, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[pressAndHold]',
})
export class PressAndHoldDirective {
  @Output() pressAndHold: EventEmitter<string> = new EventEmitter();

  touchStart$: Observable<any>;
  touchEnd$: Observable<any>;
  touchMove$: Observable<any>;

  touchMoves = {
    x: 0,
    y: 0
  };

  constructor(private elementRef: ElementRef) {

    this.touchStart$ = fromEvent(this.elementRef.nativeElement, 'touchstart').pipe(tap());

    this.touchMove$ = fromEvent(this.elementRef.nativeElement, 'touchmove');
    this.touchMove$.subscribe(value => {
      this.touchMoves.x = value.touches[0].clientX;
      this.touchMoves.y = value.touches[0].clientY;
    });

    this.touchEnd$ = fromEvent(this.elementRef.nativeElement, 'touchend').pipe(tap());

    this.watchTouches();
  }

  watchTouches(){
    this.touchStart$.pipe(
      delay(500),
      takeUntil(this.touchEnd$)
    ).subscribe(res => {


      console.log(this.touchMoves);

      if(this.touchMoves.x < 50 || this.touchMoves.y < 50){
        this.pressAndHold.emit('Press and holded')

      }
      this.touchMoves = {
        x: 0,
        y: 0
      };
      this.watchTouches()
    });
  }

}
