import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Subscription } from 'rxjs';
import { dossierTab } from './../../core/interfaces/dossier.interface';
import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DossierService } from 'src/app/core/services/dossier.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';

@Component({
  selector: 'app-dossier-tab',
  templateUrl: './dossier-tab.component.html',
  styleUrls: ['./dossier-tab.component.sass']
})

export class DossierTabComponent implements OnInit, OnDestroy {

  @Input() dossierEntity:number;
  @Input() entityUid:number;

  @ViewChild('uploader') uploader: ElementRef;

  dossierTabs:dossierTab[] = [];
  activeTab:number = 0;
  $dossierSubscription:Subscription;
  $documentTypeDrawerClickedItemSubscription:Subscription;
  downloadLinkBase =  environment.fileServiceDownload;
  current_user:User;
  actionIcon:string = '<i class="fa-solid fa-sharp fa-plus"></i>';
  noteIcon:string = '<i class="fa-solid fa-note-sticky"></i>';

  $subscription1:Subscription;
  $subscription2:Subscription;

  constructor(private dossierService: DossierService, private authService: AuthService, private router:Router){

    this.$subscription1 = this.authService.user.subscribe(resp => {
      this.current_user = resp;
    })

    this.$subscription2 = dossierService.dossierTabs.subscribe(resp => {
      this.dossierTabs = resp;
    });
  }

  ngOnInit(){
    this.getDossierItems();
  }

  getDossierItems(){
    const activatedTab = this.dossierTabs.findIndex(object => {
      return object.active;
    });
    this.activeTab = activatedTab;
    this.dossierService.getDossierPage(activatedTab, this.dossierEntity, this.entityUid);
  }

  onClickTab(itemClicked:number){
    if(this.activeTab != itemClicked){
      this.dossierTabs.forEach(function (tab) {
          tab.active = false;
      });
      this.dossierTabs[itemClicked].active = true;
      this.getDossierItems();
    }
  }

  onNextTab(itemClicked:number){
    this.dossierTabs.forEach(function (tab) {
        tab.active = false;
    });
    this.dossierTabs[itemClicked].active = true;
    this.getDossierItems();
  }

  onAddDocument(){
    this.router.navigate(['/dossier/upload/' + this.dossierEntity + '/' + this.entityUid])
  }

  onAddNote(){
    this.router.navigate(['/dossier/create-note/' + this.dossierEntity + '/' + this.entityUid])
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    this.dossierService.createEmptyTabs();
  }
}
