import { HashingService } from './hashing.service';
import { User } from 'src/app/core/auth/user.model';
import { AuthService } from './../auth/auth.service';
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from 'rxjs';
import { request } from 'http';

@Injectable()
export class CachingService implements OnDestroy{

  $subscription1:Subscription;

  // 4 days
  CACHE_TTL:number = 96;

  currentUser:User;

  // Storage watcher interval
  private watcherInterval:ReturnType<typeof setInterval>;

  // Expired cache subject, which other services can subscribe to, so they know if something is removed
  public expiredCache = new BehaviorSubject<string>(null);

  constructor(private authService: AuthService, private hashingService: HashingService){
    this.$subscription1 = this.authService.user.subscribe(userData => {
      this.currentUser = userData;
    })

    this.watchStorage();
  }


  removeCacheByString(keywords:string):void{
    for (var i = 0; i < localStorage.length; i++){
      let cache_key = localStorage.key(i);
      if(cache_key.includes(keywords)){
        localStorage.removeItem(cache_key);
      }
    }
  }

  public createCacheKey(requestMethod:string, requestBody:object):string{
    if(this.currentUser && this.currentUser.id){
      var userId = this.currentUser.id.toString();
    }else{
      var userId = '0';
    }
    let request_body_hashed = this.hashObject(requestBody);
    return 'v1_' + userId + '_' + requestMethod + '_' + request_body_hashed;
  }

  getCacheAge(requestMethod:string, requestBody:any):boolean|number{
    let cacheKey = this.createCacheKey(requestMethod, requestBody);
    let cacheString = localStorage.getItem(cacheKey);
    if(cacheString){
      let cacheObj = JSON.parse(cacheString);
      return this.getMinDiff(new Date(),new Date(cacheObj.timestamp));
    }else{
      return false;
    }
  }

  getCacheByKey(cacheKey:string):boolean|any{
    let cacheString = localStorage.getItem(cacheKey);
    if(cacheString){
      let cacheObj = JSON.parse(cacheString);
      if(new Date(cacheObj.expire) < new Date()){
        localStorage.removeItem(cacheKey);
        return false
      }
      return cacheObj.data;
    }else{
      return false;
    }
  }

  getCache(requestMethod:string, requestBody:any):boolean|any{
    let cacheKey = this.createCacheKey(requestMethod, requestBody);
    return this.getCacheByKey(cacheKey);
  }

  createCache(cacheKey:string, responseBody:any, ttlHours:number = this.CACHE_TTL):boolean{
    let expire_date = this.getDate(ttlHours);
    let now = new Date();
    let object_to_cache = {
      expire : expire_date,
      timestamp: now,
      data: responseBody
    };
    let objectstring = JSON.stringify(object_to_cache);
    localStorage.setItem(cacheKey, objectstring);
    return true;
  }

  getDate(numOfHours:number, date = new Date()):Date{
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date;
  }

  private watchStorage():void{
    // We need to make sure local storage isnt containing of expired cache
    // So we check it right away
    this.storageChecker();
    // And every 10 seconds
    this.watcherInterval = setInterval(() => {
      this.storageChecker();
    }, 10000);
  }

  private storageChecker():void{
    // Loop trough each item in localStorage
    for(var i = 0, len = localStorage.length; i < len; ++i){
      let stringItem = localStorage.getItem(localStorage.key(i));
      if(stringItem && (stringItem.includes('{') || stringItem.includes('['))){
        let item = JSON.parse(stringItem);
        if (
            typeof item === 'object' &&
            !Array.isArray(item) &&
            item !== null
        ) {
          if(item && item.expire){
            let now = new Date();
            let expireDate = new Date(item.expire);
            if(now > expireDate){
              this.expiredCache.next(localStorage.key(i));
              localStorage.removeItem(localStorage.key(i));
            }
          }
        }
      }
    }
  }

  resetCaching(){
    localStorage.clear();
    // Now hard refresh the app
    window.location.href = window.location.href;
  }

  private getMinDiff(startDate:any, endDate:any):number {
    const msInMinute = 60 * 1000;
    return Math.round(
      Math.abs(endDate - startDate) / msInMinute
    );
  }

  private hashString(str:string){
    return this.hashObject({
      key: str
    });
  }

  private hashObject(object:object){
    return this.hashingService.createHash(object);
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
  }
}
