import { AuthService } from './../../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { calendarUser } from 'src/app/core/interfaces/calendar-interface';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { User } from 'src/app/core/auth/user.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-top-buttons',
  templateUrl: './top-buttons.component.html',
  styleUrls: ['./top-buttons.component.sass']
})
export class TopButtonsComponent implements OnInit,OnDestroy,AfterViewInit {

  calendarUsers:calendarUser[] = [];
  $userSubscription:Subscription;
  current_user:User;

  usersControl = new FormControl();

  @Output() selectedUsers: EventEmitter<number[]> = new EventEmitter();

  constructor(private calendarService: CalendarService, private authService: AuthService) {
    this.authService.user.subscribe(res => {
      this.current_user = res;
      this.selectedUsers.emit([this.current_user.id]);
    })
  }

  ngOnInit(): void {
    this.onLoadUsers();
    this.usersControl.patchValue([this.current_user.id]);
  }

  ngAfterViewInit(): void {
  }

  onSelectUpdate(){
    this.selectedUsers.emit(this.usersControl.value);
  }

  onLoadUsers(){
    this.$userSubscription = this.calendarService.getUsers().subscribe((resUsers) => {
      if(resUsers){
        this.calendarUsers = resUsers.result.users;
      }
    });
  }

  ngOnDestroy(): void {
    if(this.$userSubscription){
      this.$userSubscription.unsubscribe();
    }
  }
}
