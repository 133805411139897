import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CupertinoPane } from 'cupertino-pane';

@Component({
  selector: 'app-email-composer-header',
  templateUrl: './email-composer-header.component.html',
  styleUrls: ['./email-composer-header.component.sass']
})
export class EmailComposerHeaderComponent implements OnInit {

  @Output() mailSend: EventEmitter<void> = new EventEmitter();
  @Output() mailSendPostponed: EventEmitter<Date> = new EventEmitter();
  @Output() addAttachment: EventEmitter<void> = new EventEmitter();
  @Output() mailGoBack: EventEmitter<void> = new EventEmitter();;

  @Input() loadingSend:boolean = false;
  @Input() mailTitle:string = '';

  @ViewChild('postponedateInput') postponedateInput: ElementRef;

  constructor() { }

  closestHour:Date;
  minPostPone:Date;

  postPoneDrawer:CupertinoPane = null;

  ngOnInit(): void {
    let now = new Date();
    this.minPostPone = now;
    now.setHours(now.getHours() + 1);
    now.setMinutes(0, 0, 0);
    this.closestHour = now;




    this.postPoneDrawer = new CupertinoPane('.postponedrawer', {
      backdrop: true,
      bottomClose: true,
      fastSwipeClose: false,
      events: {
        onDidDismiss: (ev) => {
        },
        onBackdropTap: () => this.postPoneDrawer.destroy({ animate: true }),
      },
    });
  }

  onPostponeSend(){
    this.postPoneDrawer.present({
      animate: true,
    });

  }

  onSendMail(){
    this.mailSend.emit();
  }

  onGoBack(){
    this.mailGoBack.emit();
  }

  onAddAttachment(){
    this.addAttachment.emit();
  }

  onSendMailPostPoned(){

    let postponedate = new Date(this.postponedateInput.nativeElement.value);

    this.mailSendPostponed.emit(postponedate);
  }

}
