import { SettingsService } from 'src/app/core/services/settings.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { mailDetailResponseInner } from 'src/app/core/interfaces/mail-interface';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-email-information',
  templateUrl: './email-information.component.html',
  styleUrls: ['./email-information.component.sass']
})
export class EmailInformationComponent implements OnInit{

  darkMode:boolean = false;
  lightState:boolean = false;

  constructor(private authService: AuthService, private settingService:SettingsService,private readonly tracker: MatomoTracker) {
    this.settingService.themeObservable.subscribe(res => {
      if(res){
        this.onUpdateDarkMode();
      }
    });
    this.onUpdateDarkMode();
  }

  @Input() mailDetail:mailDetailResponseInner;
  @Output() flagUpdated: EventEmitter<number> = new EventEmitter();
  @Output() showMailLight: EventEmitter<boolean> = new EventEmitter();

  mailFlag:number = 0;
  updateMailFlagShow:boolean = false;
  showMoreToItems:boolean = false;
  showMoreCcItems:boolean = false;

  downloadLinkBase:string;
  user:User;

  ngOnInit(): void {
    this.mailFlag = this.mailDetail.tag;
    this.downloadLinkBase =  environment.fileServiceDownload;
    this.authService.user.subscribe(resp => {
      this.user = resp;
    })
  }

  onUpdateDarkMode(){
    let theSetting = this.settingService.getSetting('05darkMode');
    let theSettingVal = theSetting.value;
    if(theSettingVal == 0){
      this.darkMode = true;
    }else if(theSettingVal == 1){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.darkMode = true;
      }else{
        this.darkMode = false;
      }
    }else{
      this.darkMode = false;
    }
  }

  onFlagUpdate(newflag:number){
    this.flagUpdated.emit(newflag);
  }

  onToggleMailLight(){
    if(this.lightState){
      this.lightState = false;
    }else{
      this.lightState = true;
    }

    this.tracker.trackGoal(13);

    this.showMailLight.emit(this.lightState);
  }

}
