import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-calendar-invitation-contact-list',
  templateUrl: './calendar-invitation-contact-list.component.html',
  styleUrls: ['./calendar-invitation-contact-list.component.sass']
})
export class CalendarInvitationContactListComponent{

  @Input() contacts:any;
  @Output() clickedContact: EventEmitter<any> = new EventEmitter();

  constructor() { }

  onContactClicked(contact:any){
    this.clickedContact.emit(contact);
  }

}
