import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable()
export class WhatsappService {


  $subscription:Subscription;
  private whatsappWidget = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private cachingService: CachingService
  ) {}

  getWidget() {
    // Define the OWS method
    const requestMethod = 'Otys.Services.GenericPartnerWidget.getInfo';
    // Define the body of the request (params)
    const requestBody = [
      0,
      "70",
      0,
      "dcndwioco7"
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.whatsappWidget.next(cached_data.result.html);
    }

    // Retrieve live data
    this.$subscription = this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result.html) {
            this.whatsappWidget.next(resData.result.html);
          }
        }
      });

    // Return the relation list as an observable so other components can listen to any updates
    return this.whatsappWidget.asObservable();
  }

}
