<header>
  <div class="detail-page-header" *ngIf="relationContactLoaded">
    <div class="topbar">
      <app-back-button></app-back-button>
      <div class="jobtitle" *ngIf="relationContact.jobTitle">
        {{relationContact.jobTitle}}
      </div>
      <app-favorite-star [entity]="4" [uid]="relationContactUid"></app-favorite-star>
    </div>
    <div class="main-info">
      <div class="detail-image" [ngStyle]="{'background-image': (relationContact.Person.photoFileName) ? 'url('+relationContactPhotoUrl+')' : ''}">
        <ng-template  [ngIf]="!relationContact.Person.photoFileName">
          <span>{{relationContact.fullname|getInitials}}</span>
        </ng-template>
      </div>
      <div class="text-info">
        <h1>{{relationContact.fullname}}</h1>
        <div class="relation" [routerLink]="['/relations/' + relationContact.relationId]">
          {{relationContact.relation}}
        </div>
      </div>
    </div>
  </div>
  <div class="tabs-nav">
    <div class="swiper-pagination"></div>
  </div>
</header>

<div class="tabswrap" *ngIf="relationContact">
  <swiper #swiper [config]="config" *transloco="let t; read: 'relationContacts'">
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
            <div class="icon-and-text-vals" *ngIf="relationContact.phone || relationContact.Person.emailPrimary">
              <app-phone-number-with-icons [phone]="relationContact.phone"></app-phone-number-with-icons>
              <app-mail-with-icon [mail]="relationContact.Person.emailPrimary"></app-mail-with-icon>
            </div>

            <app-detailpage-item-value [label]="t('relationName')" [value]="relationContact.relation"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="relationContact.jobTitle" [label]="t('jobTitle')" [value]="relationContact.jobTitle"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="relationContact.user" [label]="t('connectedConsultant')" [value]="relationContact.user"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="relationContact.entryDateTime" [label]="t('entryDate')" [value]="relationContact.entryDateTime|customDate"></app-detailpage-item-value>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <app-dossier-tab [dossierEntity]="4" [entityUid]="relationContactUid" *ngIf="showDossier"></app-dossier-tab>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngIf="this.current_user.modules.includes('Vacancies')">
        <div class="tab">
          <div class="tab-inner">
            <div class="relation-vacancies-list" *ngIf="relationContactVacancies.length">
              <div class="list-item list-item-relation" *ngFor="let relationContactVacancy of relationContactVacancies" [routerLink]="['/vacancies/' + relationContactVacancy.uid]">
                <div class="list-image">
                  <i class="fa-solid fa-briefcase"></i>
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-left">
                    <div class="list-item-subject">{{relationContactVacancy.title}}</div>
                    <div class="list-item-location">{{relationContactVacancy.location}}</div>
                  </div>
                  <div class="list-item-content-right">
                    <div class="top">
                      <div class="list-item-status" *ngIf="relationContactVacancy.status">{{relationContactVacancy.status}}</div>
                    </div>
                    <div class="bottom">
                      <div class="job-applycount"><i class="fa-solid fa-user"></i>{{relationContactVacancy.applyCount}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-no-results *ngIf="!relationContactVacancies.length"></app-no-results>
          </div>
        </div>
      </ng-template>
  </swiper>
</div>
