<header>
  <div class="detail-page-header" *ngIf="vacancyLoaded">
    <div class="topbar">
      <app-back-button></app-back-button>
      <div class="status" *ngIf="vacancy.status">
        {{vacancy.status}}
      </div>
      <app-favorite-star [entity]="1" [uid]="vacancyUid"></app-favorite-star>
    </div>
    <div class="main-info">
      <div class="detail-image" [ngStyle]="{'background-image': (vacancy.photoFileName) ? 'url('+vacancyPhotoUrl+')' : ''}">
        <ng-template  [ngIf]="!vacancy.photoFileName">
          <i class="fa-solid fa-briefcase"></i>
        </ng-template>
      </div>
      <div class="text-info">
        <h1>{{vacancy.title}}</h1>
        <div class="relation" [routerLink]="['/relations/' + vacancy.relationId]">
          {{vacancy.relation}}
        </div>
      </div>
    </div>
  </div>
  <div class="tabs-nav">
    <div class="swiper-pagination"></div>
  </div>
</header>

<div class="tabswrap" *ngIf="vacancy">
  <swiper #swiper [config]="config" *transloco="let t; read: 'vacancies'">
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">

            <app-detailpage-item-value [label]="t('relation')" [value]="vacancy.relation" [routerLink]="['/relations/' + vacancy.relationId]"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('vacancyOwner')" [value]="vacancy.user"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="vacancy.entryDateTime" [label]="t('entryDate')" [value]="vacancy.entryDateTime|customDate"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('location')" [value]="vacancy.location"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('status')" [value]="vacancy.status"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('published')" [value]="t('yes')" *ngIf="vacancy.published"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('published')" [value]="t('no')" *ngIf="!vacancy.published"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('publicationStatus')" [value]="publicationOptions.publicationStatusId[vacancy.publicationStatusId]" *ngIf="vacancy.publicationStatusId"></app-detailpage-item-value>


            <div class="extra-detailpage-item" *ngIf="
              vacancy.matchCriteria_1.length !== 0 ||
              vacancy.matchCriteria_2.length !== 0 ||
              vacancy.matchCriteria_3.length !== 0 ||
              vacancy.matchCriteria_4.length !== 0 ||
              vacancy.matchCriteria_5.length !== 0 ||
              vacancy.matchCriteria_6.length !== 0 ||
              vacancy.matchCriteria_7.length !== 0 ||
              vacancy.matchCriteria_8.length !== 0 ||
              vacancy.matchCriteria_9.length !== 0 ||
              vacancy.matchCriteria_10.length !== 0 ||
              vacancy.matchCriteria_11.length !== 0 ||
              vacancy.matchCriteria_12.length !== 0 ||
              vacancy.matchCriteria_13.length !== 0 ||
              vacancy.matchCriteria_14.length !== 0 ||
              vacancy.matchCriteria_15.length !== 0 ||
              vacancy.matchCriteria_16.length !== 0 ||
              vacancy.matchCriteria_17.length !== 0 ||
              vacancy.matchCriteria_18.length !== 0
              ">
                <ng-container *ngFor="let criteriaName of vacancy.matchCriteriaNames | keyvalue">
                  <app-candidate-criteria-item [criteriaValues]="vacancy[criteriaName.key]"  [criteriaName]="criteriaName.value"></app-candidate-criteria-item>
                </ng-container>
            </div>

          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <app-dossier-tab [dossierEntity]="1" [entityUid]="vacancyUid" *ngIf="showDossier"></app-dossier-tab>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
            <div class="vacancy-procedures-list">
              <div class="list-item list-item-procedure" *ngFor="let procedure of procedures" [routerLink]="['/candidates/' + procedure.candidateUid]">
                <div class="list-image">
                  <span *ngIf="procedure.candidate">
                    {{ procedure.candidate | getInitials}}
                  </span>
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-left">
                    <div class="list-item-subject">{{procedure.candidate}}</div>
                    <div class="list-item-status" *ngIf="procedure.ProcedureStatus3.status">{{procedure.ProcedureStatus3.status}}</div>
                    <div class="list-item-status" *ngIf="!procedure.ProcedureStatus3.status && procedure.ProcedureStatus2.status">{{procedure.ProcedureStatus2.status}}</div>
                    <div class="list-item-status" *ngIf="!procedure.ProcedureStatus3.status && !procedure.ProcedureStatus2.status && procedure.ProcedureStatus1.status">{{procedure.ProcedureStatus1.status}}</div>
                  </div>
                  <div class="list-item-content-right">
                    <div class="top">
                      <div class="list-item-date" *ngIf="procedure.entryDateTime">{{procedure.entryDateTime|customDate}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-no-results *ngIf="!procedures.length"></app-no-results>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
              <div class="job-text" *ngFor="let textField of vacancyTextFields">
                <div class="job-text-inner" *ngIf="vacancy[textField].text">
                  <h3 [innerHTML]="vacancy[textField].title"></h3>
                  <div class="text" [innerHTML]="vacancy[textField].text"></div>
                </div>
              </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner" *ngIf="vacancyLoaded">
            <app-vacancy-publication-form [vacancy]="vacancy" [publicationOptions]="publicationOptions"></app-vacancy-publication-form>
          </div>
        </div>
      </ng-template>
  </swiper>
</div>

