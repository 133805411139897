export class User {
  constructor(
    public canUseApp:boolean,
    public username: string,
    public id: number,
    public clientId: number,
    public client: string,
    public email: string,
    public keyUser: boolean,
    public defaultContentLanguage: string,
    public defaultCountry: string,
    public contentLanguages:string[],
    public firstName: string,
    public infix: string,
    public lastName: string,
    public photoFileName: string,
    public modules: string[],
    public userSettings: any,
    public languageSet: number,
    private _sessionid: string
  ) {}

  get sessionId(){
    return this._sessionid;
  }

}
