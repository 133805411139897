import { VerifyTokenComponent } from './core/auth/verify-token/verify-token.component';
import { TaskDetailComponent } from './modules/tasks/task-detail/task-detail.component';
import { TasksComponent } from './modules/tasks/tasks.component';
import { NoteComponent } from './modules/dossier/note/note.component';
import { DossierCreatenoteComponent } from './modules/dossier/dossier-createnote/dossier-createnote.component';
import { WhatsappComponent } from './modules/whatsapp/whatsapp.component';
import { EventComposerComponent } from './modules/calendar/event-composer/event-composer.component';
import { ViewFileComponent } from './modules/dossier/view-file/view-file.component';
import { CandidateDetailComponent } from './modules/candidates/candidate-detail/candidate-detail.component';
import { InteractionsComponent } from './modules/interactions/interactions.component';
import { VacancyDetailComponent } from './modules/vacancies/vacancy-detail/vacancy-detail.component';
import { DossierDocumentuploadComponent } from './modules/dossier/dossier-documentupload/dossier-documentupload.component';
import { RelationDetailComponent } from './modules/relations/relation-detail/relation-detail.component';
import { ReminderComposerComponent } from './modules/reminders/reminder-composer/reminder-composer.component';
import { ReminderDetailComponent } from './modules/reminders/reminder-detail/reminder-detail.component';
import { EmailComposerComponent } from './modules/emails/email-composer/email-composer.component';
import { EmailDetailComponent } from './modules/emails/email-detail/email-detail.component';
import { RemindersComponent } from './modules/reminders/reminders.component';
import { RelationsContactsComponent } from './modules/relations-contacts/relations-contacts.component';
import { RelationsComponent } from './modules/relations/relations.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { VacanciesComponent } from './modules/vacancies/vacancies.component';
import { CandidatesComponent } from './modules/candidates/candidates.component';
import { EmailsComponent } from './modules/emails/emails.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthComponent } from './core/auth/auth.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './modules/calendar/calendar.component';
import { RelationsContactDetailComponent } from './modules/relations-contacts/relations-contact-detail/relations-contact-detail.component';
import { FillInFormComponent } from './modules/interactions/fill-in-form/fill-in-form.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: DashboardComponent
  },
  {
    path: 'emails',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: EmailsComponent
  },
  {
    path: 'emails/compose',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: EmailComposerComponent
  },
  {
    path: 'emails/reply/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: EmailComposerComponent
  },
  {
    path: 'emails/replyall/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: EmailComposerComponent
  },
  {
    path: 'emails/forward/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: EmailComposerComponent
  },
  {
    path: 'emails/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: EmailDetailComponent
  },
  {
    path: 'emails/folder/:folder',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: EmailsComponent
  },
  {
    path: 'calendar',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: CalendarComponent
  },
  {
    path: 'calendar/compose',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: EventComposerComponent
  },
  {
    path: 'calendar/compose/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: EventComposerComponent
  },
  {
    path: 'candidates',
    canActivate: [AuthGuard],
    data: {"show_navigation": true},
    component: CandidatesComponent
  },
  {
    path: 'candidates/:uid',
    canActivate: [AuthGuard],
    data: {"show_navigation": true},
    component: CandidateDetailComponent
  },
  {
    path: 'tasks',
    canActivate: [AuthGuard],
    data: {"show_navigation": true},
    component: TasksComponent
  },
  {
    path: 'tasks/:uid',
    canActivate: [AuthGuard],
    data: {"show_navigation": true},
    component: TaskDetailComponent
  },
  {
    path: 'vacancies',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: VacanciesComponent
  },
  {
    path: 'vacancies/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: VacancyDetailComponent
  },
  {
    path: 'relations',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: RelationsComponent
  },
  {
    path: 'relations/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: RelationDetailComponent
  },
  {
    path: 'relation-contacts',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: RelationsContactsComponent
  },
  {
    path: 'relation-contacts/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: RelationsContactDetailComponent
  },
  {
    path: 'interactions',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: InteractionsComponent
  },
  {
    path: 'interactions/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: FillInFormComponent
  },
  {
    path: 'reminders',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: RemindersComponent
  },
  {
    path: 'reminders/compose',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: ReminderComposerComponent
  },
  {
    path: 'reminders/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: ReminderDetailComponent
  },
  {
    path: 'dossier/view/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: ViewFileComponent
  },
  {
    path: 'dossier/upload/:entity/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: DossierDocumentuploadComponent
  },
  {
    path: 'dossier/create-note/:entity/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: DossierCreatenoteComponent
  },
  {
    path: 'dossier/note/:uid',
    canActivate: [AuthGuard],
    data: {show_navigation: false},
    component: NoteComponent
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: SettingsComponent
  },
  {
    path: 'whatsapp',
    canActivate: [AuthGuard],
    data: {show_navigation: true},
    component: WhatsappComponent
  },
  {
    path: 'verifyToken',
    data: {show_navigation: false},
    component: VerifyTokenComponent
  },
  {
    path: 'login',
    data: {show_navigation: false},
    component: AuthComponent
  },
  {
     path: '**',
     pathMatch: 'full',
     redirectTo: 'dashboard'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
