<div [ngClass]="drawerClass" (click)="onCloseDrawer()">
  <div class="drawer-inner" [ngClass]="{'show': drawerOpen}" *transloco="let t;">
    <div class="drawer-title">{{drawerTitle}}</div>
    <div class="drawer-list" *ngIf="list.length">
      <ng-container *ngFor="let listItem of list">
        <div class="drawer-list-item" (click)="onDrawerItemClicked(listItem.uid)" *ngIf="listItem.disabled !== true">
          <div class="drawer-list-item-icon" [innerHtml]="listItem.icon" [style]="{color: listItem.iconColour ? listItem.iconColour : ''}">
          </div>
          <div class="drawer-list-item-title" *ngIf="!listItem.useTransloco"><span *ngIf="listItem.subTitle" class="subtitle">{{listItem.subTitle}}</span>{{listItem.title}}</div>
          <div class="drawer-list-item-title" *ngIf="listItem.useTransloco"><span *ngIf="listItem.subTitle" class="subtitle">{{t(listItem.subTitle)}}</span>{{t(listItem.title)}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
