<div class="relation-select-input" *ngIf="showInput">
  <select (change)="onSelectItem()" #relationContactSelect>
    <option value="" *transloco="let t; read: 'shared'">{{t('pickAContact')}}</option>
    <option *ngFor="let suggestValue of autoSuggestValues" [value]="suggestValue.uid">{{suggestValue.Person.firstName}} {{suggestValue.Person.infix}} {{suggestValue.Person.lastName}}</option>
  </select>
</div>

<div class="relation-select-result" *ngIf="result" (click)="onReset()">
    <div class="result-value">
      {{result.name}}
    </div>
    <div class="result-remove">
      <i class="fa-sharp fa-solid fa-circle-xmark"></i>
    </div>
</div>
