<div class="mail-with-icon">
  <div class="iconwrap" *ngIf="mail">
    <a class="icon" [routerLink]="['/emails/compose']" [queryParams]="{email: mail}">
      <i class="fa-solid fa-envelope"></i>
    </a>
  </div>
  <div class="mail-text" [routerLink]="['/emails/compose']" [queryParams]="{email: mail}">
    {{mail}}
  </div>
</div>
