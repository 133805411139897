<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of candidatesListing;">
    <ng-container *ngFor="let candidate of page.items">
      <div class="list-item list-item-relation" [routerLink]="['/candidates/' + candidate.uid]" *ngIf="candidate.fullname">
        <div class="list-image" [ngStyle]="{'background-image': (candidate.Person.photoFileName) ? 'url('+candidate.photoUrl+')' : ''}">
          <ng-template [ngIf]="!candidate.Person.photoFileName">
            <span *ngIf="candidate.fullname">
              {{ candidate.fullname | getInitials}}
            </span>
          </ng-template>
        </div>
        <div class="list-item-content">
          <div class="list-item-content-left">
            <div class="list-item-subject semibold">{{candidate.fullname}}</div>
            <div class="list-item-subtitle" *ngIf="candidate.status">{{candidate.status}}</div>
          </div>
          <div class="list-item-content-right">
            <div class="top">
              <div class="list-item-status" *ngIf="candidate.entryDateTime">{{candidate.entryDateTime|customDate}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
