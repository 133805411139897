import { Subscription } from 'rxjs';
import { WhatsappService } from './../../core/services/whatsapp.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.sass']
})

export class WhatsappComponent implements OnInit, OnDestroy{

  $subscription1:Subscription;

  constructor(private whatsappService: WhatsappService) { }

  iframe:string;
  frameLoaded:boolean = false;
  frameLoadedFully:boolean = false;

  ngOnInit(): void {
    this.$subscription1 = this.whatsappService.getWidget().subscribe(res => {
      if(res && res.html){
        if(this.frameLoaded === false){
          this.iframe = res.html;
          this.frameLoaded = true;
          let scope = this;
          setTimeout(() => {
            scope.frameLoadedFully = true;
          }, 3000)
        }
      }
    });
  }


  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
  }
}
