import { StoredQueryTransformerService } from './storedquery-transformer.service';
import { storedQueryData } from './../interfaces/stored-query.interface';
import { publicationOptions, vacancyDetail } from './../interfaces/vacancies.interface';
import { vacanciesPage } from 'src/app/core/interfaces/vacancies.interface';
import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Injectable()
export class VacancyService {

  private vacanciesList = new BehaviorSubject<vacanciesPage>(null);
  private vacancySearchResultList = new BehaviorSubject<vacanciesPage>(null);
  private vacancyDetail = new BehaviorSubject<vacancyDetail>(null);
  private vacancyPublicationSettings = new BehaviorSubject<publicationOptions>(null);

  constructor(
    private http: HttpClient,
    private cachingService: CachingService,
    private storedQueryTransformerService: StoredQueryTransformerService,
    private readonly tracker: MatomoTracker
  ) {}

  getVacancyList(page: number = 1, storedQueryData:any = null) {
    let limit = 20;
    let offset = 0;

    if (page != 1) {
      offset = page * limit - limit;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.VacancyService.getListEx';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        search: {
          ACTONOMY: {
            DATA: {
              dummy: {
                value: 1
              }
            },
            OPTIONS: {
              getTotalCount: 1,
              limit: limit,
              offset: offset,
              sort: {
                SCORE: "DESC",
                entryDateFull: "DESC"
              },
              facetsToUse: []
            },
            VERSION: 2,
            SUB_VERSION: 0
          }
        },
        limit: 25,
        excludeLimitCheck: true,
        getTotalCount: true,
        what: {
          uid: 1,
          internalId: 1,
          published: 1,
          entryDateTime: 1,
          status: 1,
          photoFileName:1,
          statusId: 1,
          title: 1,
          relation: 1,
          relationId: 1,
          location: 1,
          user: 1,
          applyCount: 1,
          applyCountToday: 1
        }
      }
    ];

    if(storedQueryData){
      let transformed = this.storedQueryTransformerService.transform(storedQueryData);
      if(transformed.conditional){
        requestBody[0].condition = transformed.conditional;
      }
      requestBody[0].search.ACTONOMY.DATA = transformed.actonomy;
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    if (page == 1) {
      // Retrieve cached data (if theres any)
      let cached_data = this.cachingService.getCache(
        requestMethod,
        requestBody
      );
      if (cached_data) {
        if (cached_data.result.listOutput.length) {
          this.vacanciesList.next({
            page: page,
            items: cached_data.result.listOutput,
          });
        }
      } else {
        this.vacanciesList.next(null);
      }
    } else {
      this.vacanciesList.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result.listOutput) {
            this.vacanciesList.next({
              page: page,
              items: resData.result.listOutput,
            });
          }
        }
      });

    // Return the vacancy list as an observable so other components can listen to any updates
    return this.vacanciesList.asObservable();
  }

  getSearchResults(searchInput: string) {

    this.vacancySearchResultList.next(null);

    // Define the OWS method
    const requestMethod = 'Otys.Services.VacancyService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        search: {
          ACTONOMY: {
            DATA: {
              keywords: {
                options: {
                  enableExpansion: false,
                  required: true,
                  searchList: [
                    "title",
                    "chapo",
                    "refs"
                  ],
                  searchMode: "one",
                  appendAsterisk: true,
                },
                value: searchInput
              }
            },
            OPTIONS: {
              getTotalCount: 1,
              limit: 75,
              offset: 0,
              sort: {
                SCORE: "DESC",
                entryDateFull: "DESC"
              },
              facetsToUse: []
            },
            VERSION: 2,
            SUB_VERSION: 0
          }
        },
        limit: 25,
        excludeLimitCheck: true,
        getTotalCount: true,
        what: {
          uid: 1,
          internalId: 1,
          published: 1,
          entryDateTime: 1,
          status: 1,
          statusId: 1,
          photoFileName:1,
          title: 1,
          relation: 1,
          relationId: 1,
          location: 1,
          user: 1,
          applyCount: 1,
          applyCountToday: 1
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    this.http
    .post<any>(environment.owsUrl, requestBody, requestHeaders)
    .subscribe((resData) => {
      if (resData) {
        if (resData.result.listOutput) {
          this.vacancySearchResultList.next({
            page: 1,
            items: resData.result.listOutput,
          });
        }
      }
    });

    // Return the vacancy list as an observable so other components can listen to any updates
    return this.vacancySearchResultList.asObservable();
  }

  getVacancyDetail(uid: string) {
    // Define the OWS method
    const requestMethod = 'Otys.Services.VacancyService.getDetail';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {
        uid: 1,
        title: 1,
        relation: 1,
        relationId:1,
        status: 1,
        user:1,
        statusId: 1,
        applyCount: 1,
        location: 1,
        published: 1,
        publishedWebsites: 1,
        publicationStatus: 1,
        publicationStatusId: 1,
        publishedInShortlist: 1,
        publishedInShortlistWebsites: 1,
        jobBanner: 1,
        jobBannerWebsites: 1,
        publishLanguages:1,
        publicationStartDate:1,
        publicationEndDate:1,
        entryDateTime: 1,
        photoFileName: 1,
        textField_description: 1,
        textField_companyProfile: 1,
        textField_requirements: 1,
        textField_salary: 1,
        textField_companyCulture: 1,
        textField_summary: 1,
        textField_extra1: 1,
        textField_extra2: 1,
        textField_extra3: 1,
        textField_extra4: 1,
        textField_extra5: 1,
        textField_extra6: 1,
        textField_extra7: 1,
        textField_extra8: 1,
        textField_extra9: 1,
        textField_extra10: 1,
        matchCriteriaNames: 1,
        matchCriteria_1: 1,
        matchCriteria_2: 1,
        matchCriteria_3: 1,
        matchCriteria_4: 1,
        matchCriteria_5: 1,
        matchCriteria_6: 1,
        matchCriteria_7: 1,
        matchCriteria_8: 1,
        matchCriteria_9: 1,
        matchCriteria_10: 1,
        matchCriteria_11: 1,
        matchCriteria_12: 1,
        matchCriteria_13: 1,
        matchCriteria_14: 1,
        matchCriteria_15: 1,
        matchCriteria_16: 1,
        matchCriteria_17: 1,
        matchCriteria_18: 1
      },
      null,
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true',
        'ows-cachettl': '1'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.vacancyDetail.next(cached_data.result);
    } else {
      this.vacancyDetail.next(null);
    }

    // Retrieve live data
    this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData) {
          if (resData.result) {
            this.vacancyDetail.next(resData.result);
          }
        }
      });

    // Return the vacancy list as an observable so other components can listen to any updates
    return this.vacancyDetail.asObservable();
  }


  getProcedures(uid:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ProcedureService.getListEx';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          entryDateTime: 1,
          candidateUid: 1,
          candidate: 1,
          ProcedureStatus1: {
            uid: 1,
            status: 1
          },
          ProcedureStatus2: {
            uid: 1,
            status: 1
          },
          ProcedureStatus3: {
            uid: 1,
            status: 1
          }
        },
        limit: 500,
        offset: 0,
        getTotalCount: 1,
        sort: {
          entryDateTime: "DESC"
        },
        excludeLimitCheck: true,
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "vacancyUid",
              op: "EQ",
              param: uid
            }
          ]
        }
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };


    // Retrieve live data
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
  }

  updateVacancyField(uid:string, field:string, value:any){

    if(value == 'null'){
      var pastVal = null;
    }else{
      var pastVal = value;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.VacancyService.update';
    // Define the body of the request (params)
    const requestBody = [
      uid,
      {

      }
    ];

    requestBody[1][field] = pastVal;

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false',
      },
    };

    this.tracker.trackGoal(10);

    // Retrieve live data
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders)
  }


  getPublicationOptions(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.VacancyService.getOptionLists';
    // Define the body of the request (params)
    const requestBody = [
      [
          "publishedWebsites",
          "publicationStatusId"
      ]
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data) {
      this.vacancyPublicationSettings.next(cached_data.result);
    } else {
      // Retrieve live data
      this.http
        .post<any>(environment.owsUrl, requestBody, requestHeaders)
        .subscribe((resData) => {
          if (resData) {
            if (resData.result) {
              this.vacancyPublicationSettings.next(resData.result);
            }
          }
        });
    }
    return this.vacancyPublicationSettings.asObservable();
  }



}
