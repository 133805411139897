import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { AuthService } from './../../../core/auth/auth.service';
import { AlertService } from './../../../core/services/alert.service';
import { Subscription } from 'rxjs';
import { ReminderService } from './../../../core/services/reminder.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {Location} from '@angular/common';
import { reminderDetail } from 'src/app/core/interfaces/reminder.interface';
import { User } from 'src/app/core/auth/user.model';

@Component({
  selector: 'app-reminder-composer',
  templateUrl: './reminder-composer.component.html',
  styleUrls: ['./reminder-composer.component.sass']
})
export class ReminderComposerComponent implements OnInit,OnDestroy {

  $subscription1:Subscription;
  $subscription2:Subscription;

  reminder:reminderDetail = {
    title: '',
    dateTime: '',
    description: '',
    forUserId: 0,
    forUser: '',
  };
  reminderUsers:any = [];
  reminderLoaded:boolean = false;

  reminderSaveLoading:boolean = false;

  current_user:User;

  constructor(private reminderService: ReminderService, private router: Router, private alertService: AlertService, private authService: AuthService, private translationService: TranslocoService) {
    this.authService.user.subscribe(response => {
      this.current_user = response;
    })
  }


  ngOnInit(): void {
    this.reminder.forUserId = this.current_user.id;
    this.reminder.forUser = this.current_user.firstName + ' ' + this.current_user.lastName;

    this.reminder.fromUserId = this.current_user.id;
    this.reminder.fromUser = this.current_user.firstName + ' ' + this.current_user.lastName;

    let dateNow = new Date();
    dateNow.setMinutes(0,0,0);
    dateNow.setHours(dateNow.getHours() + 1);

    this.reminder.dateTime = dateNow.toISOString();

    this.loadUserOptions();
  }

  loadUserOptions(){
    this.$subscription1 = this.reminderService.getUserOptionList().subscribe(response => {
      this.reminderUsers = response;
    });
  }

  onCreateReminder(){
    this.reminderSaveLoading = true;
    this.$subscription2 = this.reminderService.createReminder(this.reminder).subscribe(response => {
      if(response.result){
        this.reminderSaveLoading = false;
        this.router.navigate(['/reminders/' + response.result], {replaceUrl: true});
        this.alertService.success(this.translationService.translate('reminders.createdSuccesfully'));
      }
    });

  }

  onTitleChange(title:string){
    this.reminder.title = title;
  }
  onDescriptionChange(description:string){
    this.reminder.description = description;
  }
  onDateChange(dateTime:string){
    this.reminder.dateTime = dateTime;
  }
  onForChange(forUid:number){
    this.reminder.forUserId = forUid;
  }


  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
  }
}
