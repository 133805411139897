import { TranslocoService } from '@ngneat/transloco';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.sass']
})
export class NoResultsComponent{

  constructor(private translationService: TranslocoService){}

  @Input() text:string = this.translationService.translate('shared.noResults');
}
