import { Router } from '@angular/router';
import { drawerListItem } from './../../../core/interfaces/drawer-list.interface';
import { Subscription } from 'rxjs';
import { InteractionService } from './../../../core/services/interaction.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-create-relation',
  templateUrl: './create-relation.component.html',
  styleUrls: ['./create-relation.component.sass']
})
export class CreateRelationComponent implements OnInit, OnDestroy  {

  $relationFormsSubscription:Subscription;
  relationInteractionForms:drawerListItem[] = [];
  showFormsDrawer:boolean = false;

  constructor(private interactionService: InteractionService, private router: Router) {}

  ngOnInit(): void {
    // Enable when ready
    //this.getRelationInteractionForms();
  }

  getRelationInteractionForms(){
    this.$relationFormsSubscription = this.interactionService.getRelationInsertionForms().subscribe(res => {
      if(res){
        let interactionFormList = [];
        res.forEach(function(formItem){
          interactionFormList.push({
            title: formItem.name,
            icon: '<i class="fa-solid fa-pen-field"></i>',
            uid: formItem.uid
          });
        });
        this.relationInteractionForms = interactionFormList;
      }
    });
  }


  onShowForms(){
    this.showFormsDrawer = true;
  }

  onUpdateDrawerState(newstate:boolean){
    this.showFormsDrawer = newstate;
  }

  onDrawerItemClicked(formId:number){
    this.router.navigate(['/interactions/' + formId]);
  }

  ngOnDestroy(): void {
    if(this.$relationFormsSubscription){
      this.$relationFormsSubscription.unsubscribe();
    }
  }

}
