import { SettingsService } from 'src/app/core/services/settings.service';
import { CachingService } from './../../core/services/caching.service';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from './../../core/auth/auth.service';
import { day, event } from './../../core/interfaces/calendar-interface';
import { CalendarService } from './../../core/services/calendar.service';
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ApplicationRef,
} from '@angular/core';

import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';

import { Subject, Subscription } from 'rxjs';
import nlLocale from '@fullcalendar/core/locales/nl';
import deLocale from '@fullcalendar/core/locales/de';
import frLocale from '@fullcalendar/core/locales/fr';
import csLocale from '@fullcalendar/core/locales/cs';
import ukLocale from '@fullcalendar/core/locales/uk';
import { User } from 'src/app/core/auth/user.model';
import { FullCalendarComponent } from '@fullcalendar/angular';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass'],
})

export class CalendarComponent implements OnInit, OnDestroy {

  current_user: User;

  floatingActionIcon = '<i class="fa-solid fa-plus"></i>';

  events: event[] = [];

  searchResults: Subject<any> = new Subject<any>();

  today = new Date();
  eventsLoaded = false;

  fcCurrentDateStart = new Date();
  fcCurrentDateEnd = new Date();

  selectedUsers: number[] = [];

  dayPopupActive = false;
  dayPopDay: number = 0;
  dayPopupDays: day[] = [];

  monthLocale:string = 'nl';

  showWeekendsSetting:number[] = [0];
  maxEventsPerDaySetting:number = 2;


  @ViewChild('calendar') calendarComponent:FullCalendarComponent;

  calendarOptions: CalendarOptions;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  $subscription1: Subscription;
  $subscription2: Subscription;
  $subscription3: Subscription;
  $subscription4: Subscription;

  $routerSubscription:Subscription;

  constructor(
    private calendarService: CalendarService,
    private authService: AuthService,
    private router: Router,
    private applicationRef: ApplicationRef,
    private cachingService: CachingService,
    private settingsService: SettingsService
  ) {

    this.$subscription3 = this.authService.user.subscribe((res) => {
      this.current_user = res;
      this.monthLocale = this.current_user.defaultContentLanguage;
    });

    if(this.settingsService.getSetting('07calendarShowWeekends').value == true){
        this.showWeekendsSetting = [];
        this.maxEventsPerDaySetting = 2;
    }else{
      this.showWeekendsSetting = [0,6];
      this.maxEventsPerDaySetting = 3;
    }

    this.$subscription4 = this.$routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.saveCurrentSituationInCache()
      }
    });
  }


  setCalendarOptions(){
    this.calendarOptions = {
      initialDate: this.fcCurrentDateStart,
      locales: [nlLocale, deLocale, frLocale, csLocale, ukLocale],
      locale: this.current_user.defaultContentLanguage,
      plugins: [dayGridPlugin],
      headerToolbar: false,
      initialView: 'dayGridMonth',
      nowIndicator: false,
      rerenderDelay: 1,
      dayMaxEvents: this.maxEventsPerDaySetting,
      showNonCurrentDates: false,
      views: {
        dayGridMonth: {
          weekNumbers: true,
          hiddenDays: this.showWeekendsSetting,
          fixedWeekCount: false,
        },
      },
      dayCellDidMount: (day) => {
        day.el.onclick = () => {
          if (this.eventsLoaded) {
            this.onDayClicked(day.date);
            this.applicationRef.tick();
          }
        };
      },
      eventDidMount: (event) => {
        // Get the OTYS type colour for the event from the extendedProps
        var eventTypeColor: string = event.event._def.extendedProps['typeColor'];
        // When this isn't set, we can set the default
        if (eventTypeColor == '#' || eventTypeColor == null) {
          var eventTypeColor = '#3788d8';
        }
        // Make it an RGB so we can do proper opaciy for this colour
        var eventRGBColor = this.hexToRgb(eventTypeColor);
        event.el.style.backgroundColor = 'rgba(' + eventRGBColor + ',0.6)';
        event.el.style.borderLeftColor = 'rgba(' + eventRGBColor + ',1)';
        if (event.event._def.allDay) {
        } else {
          // Remove the dot
          event.el.removeChild(event.el.firstChild);
        }
      },
      datesSet: (date) => {
        this.fcCurrentDateStart = date.start;
        this.fcCurrentDateEnd = date.end;
        const theDate = this.fcCurrentDateEnd;
        this.fcCurrentDateEnd = new Date(
          theDate.setMinutes(theDate.getMinutes() - 1)
        );

        if (this.calendarComponent) {
          this.loadEvents();
        }
      },
      height: '100%',
      slotMinTime: '08:00',
      slotMaxTime: '18:00',
      expandRows: true,
      events: this.events,
      eventTextColor: '#000000',
    };
  }

  ngOnInit(): void {
    this.checkCacheForHistory();
    this.setCalendarOptions();

    if(this.selectedUsers.length == 0){
      this.selectedUsers.push(this.current_user.id);
    }
  }

  ngAfterViewInit() {
    this.loadEvents();
  }

  onChangeDate(event: any) {
    let setdate = event.target.value;
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(setdate);
  }

  onDayClicked(date: Date) {
    let dateNow = date;
    this.createDayPopUpDays();
    setTimeout(() => {
      this.dayPopDay = dateNow.getDate();
    }, 50);
    if (!this.dayPopupActive) {
      this.dayPopupActive = true;
    }
  }

  createDayPopUpDays(){
    let monthStart = this.fcCurrentDateStart;
    let monthEnd = this.fcCurrentDateEnd;
    let dateArray = this.getDaysArray(monthStart, monthEnd);
    this.dayPopupDays = [];
    for(let i=0; i < dateArray.length; i++){
      let date123 = dateArray[i];
      let eventsOfThisDay = this.getEventsPerDay(date123);
      let theDay: day = {
        date: date123,
        events: eventsOfThisDay,
      };
      this.dayPopupDays.push(theDay);
    }
  }

  getDaysArray(start: Date, end: Date) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  getEventsPerDay(date: Date) {
    let calendarApi = this.calendarComponent.getApi();
    let allEvents = calendarApi.getEvents();
    let filteredEvents: any[] = [];
    allEvents.forEach((event: any) => {
      let eventStartDate = event.start.setHours(0, 0, 0, 0);
      if(event.end){
        var eventEndDate = event.end.setHours(0, 0, 0, 0);
      }else{
        var eventEndDate = event.start.setHours(0, 0, 0, 0);
      }
      let scopeDate = date.setHours(0, 0, 0, 0);
      if(scopeDate >= eventStartDate && scopeDate <= eventEndDate){
        filteredEvents.push(event);
      }
    });
    return filteredEvents.sort((a, b) => a.start - b.start);
  }

  hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);
      return r + ',' + g + ',' + b;
    }
    return null;
  }

  loadEvents() {
    this.$subscription1 = this.calendarService
      .getCalendarItems(
        this.fcCurrentDateStart,
        this.fcCurrentDateEnd,
        this.selectedUsers
      )
      .subscribe((resData) => {
        if (resData && resData.result) {
          this.events = [];
          this.eventsLoaded = true;
          resData.result.forEach(
            (event: {
              uid: string;
              title: string;
              startDateTime: string;
              endDateTime: string;
              wholeDay: boolean;
              typeColor: string;
              userId: number;
              initials: string;
              invitationsCount: number;
              travelTimeTo: any;
              travelTimeFrom: any;
            }) => {


              // Remove excisting items from the events array, so we have fresh data
              const indexOfObject = this.events.findIndex((object) => {
                return object.uid === event.uid;
              });
              if (indexOfObject !== -1) {
                this.events.splice(indexOfObject, 1);
              }

              // If an event doesnt have an color set, it get's an default of blue
              if (event.typeColor == null) {
                var eventColor = '#3788d8';
              } else {
                var eventColor = '#' + event.typeColor;
              }

              var travelTimeToInMinutes = this.getMinutesFromTime(event.travelTimeTo);
              var travelTimeFromInMinutes = this.getMinutesFromTime(event.travelTimeFrom);

              // Now push this new event (or updated event) to the events array
              this.events.push({
                uid: event.uid,
                title: event.title,
                start: event.startDateTime,
                end: event.endDateTime,
                allDay: event.wholeDay,
                typeColor: eventColor,
                userId: event.userId,
                invitationsCount: event.invitationsCount,
                initials: event.initials,
                travelTimeTo: travelTimeToInMinutes,
                travelTimeFrom: travelTimeFromInMinutes,
              });
            }
          );

          // When there are any events, remove the current FullCalendar events and add these new ones in
          if (this.events.length) {
            if (this.calendarComponent) {
              if (this.calendarComponent && this.calendarComponent.getApi() !== null) {
                this.calendarComponent.getApi().removeAllEvents();
                this.calendarComponent.getApi().removeAllEventSources();
                this.calendarComponent.getApi()
                  .addEventSource(this.events);
                  this.createDayPopUpDays();
              }
            }
          }
        }
      });
  }

  getMinutesFromTime(input:string){
    if(input){
      var a = input.split(':');
      return (+a[0]) * 60 + (+a[1]);
    }else{
      return 0;
    }
  }

  onPrevMonth() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.prev();
  }

  onNextMonth() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
  }

  onMonthNavigation(mode:string){
    if(mode == 'next'){
      this.onNextMonth();
      this.dayPopDay = 1;
    }else if(mode == 'prev'){
      let tempDate = new Date(this.fcCurrentDateStart);
      this.onPrevMonth();
      tempDate.setMonth(tempDate.getMonth());
      this.dayPopDay = this.getLastDayOfMonth(tempDate.getFullYear(), tempDate.getMonth());
    }
  }

  getLastDayOfMonth(year:number, month:number){
    return new Date(year, month, 0).getDate();
  }

  onCloseDay() {
    this.dayPopupActive = false;
  }

  onUpdatedSelectedUsers(selectedUsers: number[]) {
    this.selectedUsers = selectedUsers;
    this.loadEvents();
  }

  onSingleActionClicked(e: any) {
    this.router.navigate(['/calendar/compose']);
  }

  swipe(e: TouchEvent, when: string): void {
    if(this.dayPopupActive == false){
      const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
      const time = new Date().getTime();

      if (when === 'start') {
        this.swipeCoord = coord;
        this.swipeTime = time;
      } else if (when === 'end') {
        const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
        const duration = time - this.swipeTime;

        if (duration < 1000 //
          && Math.abs(direction[0]) > 30 // Long enough
          && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
            const swipe = direction[0] < 0 ? 'next' : 'previous';
            // Do whatever you want with swipe
            if(swipe == 'next'){
              this.onNextMonth();
            }else{
              this.onPrevMonth();
            }
        }
      }
    }
  }

  onSearchInput(searchInput: string) {
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    // There should be at least 3 characters
    if (searchInput.length > 2) {
      this.$subscription2 = this.calendarService
        .getSearchResults(searchInput, this.selectedUsers)
        .subscribe((resData) => {
          if (resData && resData.result) {
            this.searchResults.next({
              page: 1,
              items: resData.result,
            });
          } else {
            this.searchResults.next([]);
          }
        });
    } else {
      this.searchResults.next([]);
    }
  }

  checkCacheForHistory(){
    let historyObjectFromCache = this.cachingService.getCache('calendar_history', {val:this.current_user.id});
    if(historyObjectFromCache){
      this.fcCurrentDateStart = new Date(historyObjectFromCache.fcCurrentDateStart);
      this.fcCurrentDateEnd = new Date(historyObjectFromCache.fcCurrentDateEnd);
      this.selectedUsers = historyObjectFromCache.selectedUsers;
      this.dayPopupActive = historyObjectFromCache.dayPopupActive;
      let daySliderIndexFromCache = this.cachingService.getCache('calendar_slider', {val: 'index'});
      if(daySliderIndexFromCache){
        this.dayPopDay = daySliderIndexFromCache;

        console.log(daySliderIndexFromCache);

      }
    }
  }

  saveCurrentSituationInCache(){
    // When navigating away lets remember where we are and what we are going to do.
    let historyObject = {
      fcCurrentDateStart: this.fcCurrentDateStart,
      fcCurrentDateEnd: this.fcCurrentDateEnd,
      selectedUsers: this.selectedUsers,
      dayPopupActive: this.dayPopupActive
    };
    let cacheKey = this.cachingService.createCacheKey('calendar_history', {val:this.current_user.id});
    this.cachingService.createCache(cacheKey, historyObject, 30);
  }

  ngOnDestroy() {
    if (this.$subscription1) {
      this.$subscription1.unsubscribe();
    }
    if (this.$subscription2) {
      this.$subscription2.unsubscribe();
    }
    if (this.$subscription3) {
      this.$subscription3.unsubscribe();
    }
    if (this.$subscription4) {
      this.$subscription4.unsubscribe();
    }

    if(this.$routerSubscription){
      this.$routerSubscription.unsubscribe();
    }
  }
}
