import { FavoriteService } from './../../core/services/favorite.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-favorite-star',
  templateUrl: './favorite-star.component.html',
  styleUrls: ['./favorite-star.component.sass']
})
export class FavoriteStarComponent implements OnInit, OnDestroy {

  @Input('uid') uid:any;
  @Input('entity') entity:number;

  favorite:boolean = false;
  favoriteUid:number = 0;
  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;

  changeLoading:boolean = false;

  constructor(private favoriteService: FavoriteService) { }


  ngOnInit(): void {
    if(this.uid && this.entity){
      this.$subscription1 = this.favoriteService.getFavoriteInformation(this.entity, this.uid).subscribe(response => {
        if(response && response.result && response.result.length){
          this.favoriteUid = response.result[0].uid;
          this.favorite = true;
        }
      });
    }
  }

  onClick(){
    if(this.changeLoading == false && this.uid){
      this.changeLoading = true;
      if(this.favorite == true){
        this.$subscription2 = this.favoriteService.deleteFavorite(this.favoriteUid).subscribe(response => {
          if(response && response.result){
            this.changeLoading = false;
            this.favorite = false;
          }
        });
      }
      if(this.favorite == false){
        this.$subscription3 = this.favoriteService.addFavorite(this.entity, this.uid).subscribe(response => {
          if(response && response.result){
            this.favoriteUid = response.result;
            this.changeLoading = false;
            this.favorite = true;
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
  }
}
