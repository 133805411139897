import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-phone-number-with-icons',
  templateUrl: './phone-number-with-icons.component.html',
  styleUrls: ['./phone-number-with-icons.component.sass']
})
export class PhoneNumberWithIconsComponent{
  @Input() phone:string;
}
