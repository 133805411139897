import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.sass']
})

export class ToggleSwitchComponent{

  @Input() value:boolean = false;
  @Input() disabled:boolean = false;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter(this.value);

  constructor() { }

  onToggleSwitch(){
    if(this.disabled){
      // No need to do anything
    }else{
      this.value = !this.value;
      this.toggled.emit(this.value);
    }
  }

}
