import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.sass']
})
export class CandidateListComponent{

  @Input() candidatesListing:any = [];
  constructor() { }

}
