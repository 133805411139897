<header>
  <div class="detail-page-header" *transloco="let t; read: 'dossier'">
    <div class="topbar">
      <div class="left-side">
        <app-back-button></app-back-button>
        <h1>{{t('createNote')}}</h1>
      </div>
      <div class="right-side controls">
        <div class="control control-save" id="dossierCreateNoteSave" (click)="onSaveNote()">
          <i class="fa-solid fa-save" *ngIf="!noteLoading"></i>
          <img src="/assets/images/spinner.svg" width="14" height="14" *ngIf="documentLoading" class="loading_spinner">
        </div>
      </div>
    </div>
  </div>
</header>

<div class="page" *transloco="let t; read: 'dossier'">
  <form class="form">
    <div class="question">
      <label>{{t('subject')}}</label>
      <input type="text" name="noteSubject" id="noteSubject" [(ngModel)]="noteSubject">
    </div>
    <div class="question">
      <label>{{t('description')}}</label>
      <textarea name="noteDescription" [(ngModel)]="noteDescription"></textarea>
    </div>
    <div class="question">
      <label>{{t('type') | capitalize}}</label>
      <select (change)="onTypeSelected()" #typeSelect>
        <option value="0">{{t('pickAType')}}</option>
        <option *ngFor="let noteType of noteTypes | keyvalue" [value]="noteType.key">{{noteType.value}}</option>
      </select>
    </div>
    <div class="question">
      <div class="togglewrap">
        <div class="toggle-item">
          <label>{{t('alwaysOnTop')}}</label>
          <app-toggle-switch [value]="false" (toggled)="onToggleAlwaysOnTop($event)"></app-toggle-switch>
        </div>
        <div class="toggle-item">
          <label>{{t('private')}}</label>
          <app-toggle-switch [value]="false" (toggled)="onTogglePrivate($event)"></app-toggle-switch>
        </div>
      </div>
    </div>
  </form>
</div>
