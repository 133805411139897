<app-header
  *transloco="let t; read: 'vacancies'"
  listType="vacancy"
  [title]="t('pageTitle') | capitalize"
  [searchPlaceHolder]="t('searchTitle')"
  (onSearchInput)="onSearchInput($event)"
  [searchResults]="searchResults.asObservable()"
  [additionalSection]="storedQueriesDrawer"
></app-header>

<ng-template #storedQueriesDrawer>
  <app-storedqueries-drawer handle="HSVACANCIES" [open]="showStoredQueries" (openClose)="showStoredQueries = $event" (filterData)="onStoredQueryClicked($event)"></app-storedqueries-drawer>
</ng-template>

<div class="page">
  <app-vacancies-list [vacanciesListing]="vacanciesListing"></app-vacancies-list>
  <app-no-results *ngIf="vacanciesListing.length && vacanciesListing[0].items.length == 0 && vacanciesLoading == false"></app-no-results>
  <app-load-more-button
    [showLoadMoreButton]="showLoadMoreButton"
    (buttonClicked)="onNextPage()"
  ></app-load-more-button>

  <app-create-vacancy></app-create-vacancy>
</div>
