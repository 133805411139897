import { User } from 'src/app/core/auth/user.model';
import { Subscription } from 'rxjs';
import { EmailsService } from './../../core/services/emails.service';
import { AuthService } from './../../core/auth/auth.service';
import { Component, ElementRef, QueryList, ViewChild, ViewChildren, OnDestroy, AfterViewChecked, AfterContentInit } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { navigationItem } from 'src/app/core/interfaces/navigation.interface';

interface mailFolder{
  uid : string,
  folder : string,
  unreadEmailCount : number
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass']
})

export class NavigationComponent implements OnDestroy,AfterContentInit, AfterViewChecked {

  current_user:User;
  loggedin:boolean = false;
  unreadMailCount:number = 0;
  showNavigation:boolean = true;
  modules:string[] = [];

  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;
  $subscription5:Subscription;

  // References to the navigation items and the active line
  @ViewChildren("navigationitem") navigationitem: QueryList<ElementRef>;
  @ViewChild("activeLine") activeline: ElementRef;
  @ViewChild("moreItem") moreItem: ElementRef;


  // On default the navigation drawer is closed
  drawerOpen:boolean = false;

  //// --- Define all the possible App modules and their routes ---
  // Title is used for how the link's translation is called
  // Name is how this module will be named in OTYS
  // Route is the link to the page
  // Icon is font-awesome class name based
  // If allowed is true here, it will show on default for all app-users
  appModules:navigationItem[] = [
    {
      title: 'navigation.dashboard',
      name: 'Dashboard',
      route: '/dashboard',
      icon: 'fa-solid fa-house-blank',
      id: 'navItemDashboard',
      allowed: true
    },
    {
      title: 'navigation.emails',
      name: 'Emails',
      route: '/emails',
      icon: 'fa-solid fa-envelope',
      id: 'navItemEmails',
      allowed: false
    },
    {
      title: 'navigation.calendar',
      name: 'Calendar',
      route: '/calendar',
      icon: 'fa-solid fa-calendar',
      id: 'navItemCalendar',
      allowed: false
    },
    {
      title: 'navigation.candidates',
      name: 'Candidates',
      route: '/candidates',
      icon: 'fa-solid fa-user-tie',
      id: 'navItemCandidates',
      allowed: false
    },
    {
      title: 'navigation.vacancies',
      name: 'Vacancies',
      route: '/vacancies',
      icon: 'fa-solid fa-briefcase',
      id: 'navItemVacancies',
      allowed: false
    },
    {
      title: 'navigation.relations',
      name: 'Relations',
      route: '/relations',
      icon: 'fa-solid fa-address-book',
      id: 'navItemRelations',
      allowed: false
    },
    {
      title: 'navigation.relationContacts',
      name: 'Relations',
      route: '/relation-contacts',
      icon: 'fa-solid fa-user-tag',
      id: 'navItemRelationContacts',
      allowed: false
    },
    {
      title: 'navigation.reminders',
      name: 'Reminders',
      route: '/reminders',
      icon: 'fa-solid fa-bell',
      id: 'navItemReminders',
      allowed: false
    },
    {
      title: 'navigation.whatsApp',
      name: 'WhatsApp',
      route: '/whatsapp',
      icon: 'fa-brands fa-whatsapp',
      id: 'navItemWhatsapp',
      allowed: true
    },
    {
      title: 'navigation.settings',
      name: 'Settings',
      route: '/settings',
      icon: 'fa-solid fa-gear',
      id: 'navItemSettings',
      allowed: true
    }
  ];

  constructor(private authService: AuthService,private router: Router, private emailService: EmailsService) {
  }

  ngAfterContentInit(): void {
    this.$subscription1 = this.authService.user.subscribe(res => {
      this.current_user = res;
      if(res.sessionId != null && res.sessionId != undefined){
        this.loggedin = true;
        this.getMailFolders();
      }else{
        this.loggedin = false;
      }
     });
     this.getUserModules();
  }

  ngAfterViewChecked(): void {
    if(this.loggedin && this.showNavigation){
      this.onCheckActiveLine();
    }
    if(this.loggedin && this.showNavigation){
      // Close the navigation drawer when someone navigates away
      this.$subscription2 = this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          // Close the drawer
          this.onCloseNavigationDrawer();
          // Do some checks for the active line
          this.onCheckActiveLine();
        }
      })

      // Update the SHOW navigation variable based upon route
      this.$subscription3 = this.router.events.subscribe(events => {
        if(events instanceof ActivationEnd){
          this.showNavigation = events.snapshot.data['show_navigation'];
        }
      })
    }
  }

  getMailFolders(){
    // Get the latest count for mails
    if(this.current_user.canUseApp){
      this.$subscription4 = this.emailService.getMailFolders().subscribe((response:mailFolder[]) => {
        if(response && response.length){
          this.unreadMailCount = response[0].unreadEmailCount;
        }
      });
    }
  }

  // Get the active menu item an push it's position/width to the active line
  onCheckActiveLine(){
    this.navigationitem.forEach(loc => {
      if(loc.nativeElement.classList.contains('active')){
        if(loc.nativeElement.classList.contains('nav-item-within-drawer') == false){
          // Give the active line an offset left of the same as the nav item has, PLUS an additional 20% of the navitem width
          this.activeline.nativeElement.style.left = ((0.2 * loc.nativeElement.offsetWidth) + loc.nativeElement.offsetLeft) + 'px';
          // Give the active line the width of 60% of the active item
          this.activeline.nativeElement.style.width = (0.6 * loc.nativeElement.offsetWidth) + 'px';
        }else{
          // If the item is wihtin the appdrawer show the active line on top of the more icon
          if(this.activeline){
            this.activeline.nativeElement.style.left = ((0.2 * this.moreItem.nativeElement.offsetWidth) + this.moreItem.nativeElement.offsetLeft) + 'px';
            this.activeline.nativeElement.style.width = (0.6 * this.moreItem.nativeElement.offsetWidth) + 'px';
          }
        }
      }
    });
  }

  // Check if there's a logged in user and get their modules
  getUserModules(){
    this.$subscription5 = this.authService.user.subscribe(res => {
      var usermodules = res.modules;
      if(usermodules.length > 0){
        this.modules = usermodules;
        this.appModules.forEach((item, index) => {
          if(!item.allowed){
            if(this.modules.includes(item.name)){
              item.allowed = true;
            }else{
              this.appModules.splice(index, 1);
            }
          }
        });
      }
     })
  }

  onToggleNavigationDrawer(){
    this.drawerOpen = !this.drawerOpen;
  }

  onCloseNavigationDrawer(){
    this.drawerOpen = false;
  }

  onSignOut(){
    this.authService.logout();
  }


  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1;
    }
    if(this.$subscription2){
      this.$subscription2;
    }
    if(this.$subscription3){
      this.$subscription3;
    }
    if(this.$subscription4){
      this.$subscription4;
    }
    if(this.$subscription5){
      this.$subscription5;
    }
  }


}
