import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHTML'
})

export class StripHTML implements PipeTransform {

  transform(value: string) {
    return this.stripHtml(value);
  }

  stripHtml(html:string)
  {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
}


