<header>
  <div class="detail-page-header">
    <div class="topbar">
      <div class="left-side">
        <app-back-button></app-back-button>
        <h1>{{note.subject}}</h1>
      </div>
    </div>
  </div>
</header>

<div class="page" *transloco="let t;">
  <app-detailpage-item-value [label]="t('dossier.subject') | capitalize" [value]="note.subject"></app-detailpage-item-value>
  <app-detailpage-item-value [label]="t('dossier.description') | capitalize" [value]="note.text"></app-detailpage-item-value>
  <app-detailpage-item-value [label]="t('dossier.type') | capitalize" [value]="note.type"></app-detailpage-item-value>
  <app-detailpage-item-value [label]="t('shared.entryDate') | capitalize" [value]="note.entryDateTime | customDate"></app-detailpage-item-value>
  <app-detailpage-item-value *ngIf="note.private" [label]="t('dossier.private') | capitalize" [value]="t('shared.yes')"></app-detailpage-item-value>
  <app-detailpage-item-value *ngIf="!note.private" [label]="t('dossier.private') | capitalize" [value]="t('shared.no')"></app-detailpage-item-value>

  <app-detailpage-item-value *ngIf="note.alwaysOnTop" [label]="t('dossier.alwaysOnTop') | capitalize" [value]="t('shared.yes')"></app-detailpage-item-value>
  <app-detailpage-item-value *ngIf="!note.alwaysOnTop" [label]="t('dossier.alwaysOnTop') | capitalize" [value]="t('shared.no')"></app-detailpage-item-value>

  <app-detailpage-item-value [label]="t('shared.addedBy') | capitalize" [value]="note.user"></app-detailpage-item-value>



</div>
