import { CachingService } from './../../../../core/services/caching.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from './../../../../core/services/settings.service';
import { ReminderService } from './../../../../core/services/reminder.service';
import { Component, OnDestroy, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-reminderwidget',
  templateUrl: './reminderwidget.component.html',
  styleUrls: ['./reminderwidget.component.sass']
})
export class ReminderwidgetComponent implements OnDestroy, AfterContentInit {
  reminders = [];
  $reminderWidgetSub: Subscription;

  constructor(
    private reminderService: ReminderService,
    private settingService: SettingsService,
    private router: Router
  ) {
  }
  listLimit:number = 1;
  dashboardLayout:any;


  ngAfterContentInit(): void {
    this.dashboardLayout = this.settingService.getSetting('02dashboardLayout').value;
    let reminderWidget = this.dashboardLayout.find(item => item.component === 'app-reminderwidget');
    this.listLimit = reminderWidget.limit;

    this.$reminderWidgetSub = this.reminderService
      .getReminders(this.listLimit)
      .subscribe((response) => {
        if (response && response.length) {
          this.reminders = response;
        }
      });
  }

  onNoResultsClick() {
    this.router.navigate(['/reminders/compose']);
  }

  ngOnDestroy(): void {
    this.$reminderWidgetSub.unsubscribe();
  }
}
