import { Subscription } from 'rxjs';
import { vacancyDetail } from './../../../../core/interfaces/vacancies.interface';
import { TranslocoService } from '@ngneat/transloco';
import { AlertService } from './../../../../core/services/alert.service';
import { VacancyService } from './../../../../core/services/vacancy.service';
import { AuthService } from './../../../../core/auth/auth.service';
import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { User } from 'src/app/core/auth/user.model';
import { publicationOptions } from 'src/app/core/interfaces/vacancies.interface';


@Component({
  selector: 'app-vacancy-publication-form',
  templateUrl: './vacancy-publication-form.component.html',
  styleUrls: ['./vacancy-publication-form.component.sass']
})

export class VacancyPublicationFormComponent implements OnChanges, OnDestroy {

  @Input() vacancy:vacancyDetail;
  @Input() publicationOptions:publicationOptions;

  current_user:User;
  multiSite:boolean = false;

  $subscription1:Subscription;

  publishLanguages:any;
  publishedWebsites = [];
  jobBannerWebsites = [];
  publishedInShortlistWebsites = [];

  constructor(private authService: AuthService, private vacancyService: VacancyService, private alertService: AlertService, private translationService: TranslocoService) {
    this.$subscription1 = this.authService.user.subscribe(res => {
      this.current_user = res;
    });
  }

  onUpdateValue(field:string, value:any){
    this.vacancyService.updateVacancyField(this.vacancy.uid, field, value).subscribe(res => {
      if(res && res.result.length == 0){
        const message = this.translationService.translate('shared.updatedFieldSuccess');
        this.alertService.success(message);
      }else{
        const message = this.translationService.translate('shared.updatedFieldError');
        this.alertService.error('Something went wrong');
      }
    });
  }

  ngOnInit(): void {
    if(Object.keys(this.publicationOptions.publishedWebsites).length > 1){
      this.multiSite = true;
    }
    this.loadSelectValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.keys(this.publicationOptions.publishedWebsites).length > 1){
      this.multiSite = true;
    }
    this.loadSelectValues();
  }


  loadSelectValues(){
    this.publishLanguages = this.vacancy.publishLanguages;

    this.jobBannerWebsites = [];
    for (const jobBannerSite in this.vacancy.jobBannerWebsites) {
      this.jobBannerWebsites.push(jobBannerSite);
    }

    this.publishedInShortlistWebsites = [];
    for (const shortlistSite in this.vacancy.publishedInShortlistWebsites) {
      this.publishedInShortlistWebsites.push(shortlistSite);
    }

    this.publishedWebsites = [];
    for (const site in this.vacancy.publishedWebsites) {
      this.publishedWebsites.push(site);
    }
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
  }

}
