<header>
  <div class="detail-page-header">
    <div class="topbar">
      <div class="left-side">
        <app-back-button></app-back-button>
        <h1 *ngIf="form">{{form.name}}</h1>
      </div>
    </div>
    <div class="form-content" *ngIf="form">
      {{form.description}}
    </div>
  </div>
  <div class="tabs-nav">
    <div class="swiper-pagination"></div>
  </div>
</header>

<div class="loading" *ngIf="formSaveLoading">
  <img src="/assets/images/spinner.svg" width="70" height="70" class="loading_spinner">
</div>

<form class="form" #fimForm="ngForm" (ngSubmit)="onSubmit(fimForm)" *ngIf="form && !formSaveLoading">
  <swiper #swiper [config]="config">
    <ng-template swiperSlide *ngFor="let formTab of formTabs; let i = index;">
      <div class="form-tab">
        <div class="form-tab-contents">
          <ng-container *ngFor="let formField of formFields | orderBy: 'value.rank'">
            <div class="question" *ngIf="formField.value.tabId == formTab.value.uid || (formField.value.tabId == null && formTab.value.uid == -1)">

              <label for="{{formField.key}}">{{formField.value.name}}<span *ngIf="formField.value.Validation.mandatory == true">*</span></label>

              <ng-container *ngIf="formField.value.typeUi == 'Textfield'">
                <input type="text" name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" ngModel>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'Email'">
                <input type="email" name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" ngModel>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'PhoneNumber'">
                <input type="tel" name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" ngModel>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'Date'">
                <input type="date" name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" ngModel>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'Select' || formField.value.typeUi == 'CountrySelect'">
                <select name="{{formField.key}}" id="{{formField.key}}" ngModel>
                  <option *ngFor="let answer of formField.value.Answers | keyvalue;" [value]="answer.value.uid" [required]="formField.value.Validation.mandatory" >
                    {{answer.value.answer}}
                  </option>
                </select>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'Gender'">
                <select name="{{formField.key}}" id="{{formField.key}}" ngModel>
                  <option *ngFor="let answer of formField.value.Answers | keyvalue;" [value]="answer.value.uid" [required]="formField.value.Validation.mandatory" >
                    {{answer.value.name.answer}}
                  </option>
                </select>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'File'">
                <input type="hidden" name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" #fileUidInput ngModel>
                <input type="file" (change)="fileUpload(formField.key,$event.target.files)">
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'Criteria' || formField.value.typeUi == 'Multiselect'">
                <select multiple name="{{formField.key}}" id="{{formField.key}}" ngModel>
                  <option *ngFor="let answer of formField.value.Answers | keyvalue;" [value]="answer.value.uid" [required]="formField.value.Validation.mandatory" >
                    {{answer.value.answer}}
                  </option>
                </select>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'CrmCriteria'">
                <select multiple name="{{formField.key}}" id="{{formField.key}}" ngModel>
                  <option *ngFor="let answer of formField.value.Answers;" [value]="answer.value.uid" [required]="formField.value.Validation.mandatory" >
                    {{answer.displayValue}}
                  </option>
                </select>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'RelationSelect'">
                <input type="text" name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" #relationSelectInput ngModel style="display:none;">
                <app-relation-autosuggest (selectedRelation)="onRelationUid(formField.key,$event)"></app-relation-autosuggest>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'RelationContactSelect'">
                <input type="text" name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" #relationContactSelectInput ngModel style="display:none;">
                <app-relation-contact-autosuggest (selectedRelationContact)="onRelationContact(formField.key, $event)" [relationUid]="relationUid"></app-relation-contact-autosuggest>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'YesNo'">
                <div class="checkwrap">
                  <input type="checkbox" name="{{formField.key}}" value="true" [required]="formField.value.Validation.mandatory" ngModel>
                </div>
              </ng-container>

              <ng-container *ngIf="formField.value.typeUi == 'Textarea'">
                <textarea name="{{formField.key}}" id="{{formField.key}}" [required]="formField.value.Validation.mandatory" ngModel></textarea>
              </ng-container>

            </div>
          </ng-container>
        </div>
        <div class="buttons" *transloco="let t; read: 'shared'">
          <button type="button" class="secondary" *ngIf="i != 0" (click)="onNavToTab(i - 1)">{{t('prevPage')}}</button>
          <button type="button" class="secondary" *ngIf="i != (formTabs.length - 1)" (click)="onNavToTab(i + 1)">{{t('nextPage')}}</button>
          <button type="submit" *ngIf="i == (formTabs.length - 1)">{{t('submit')}}</button>
        </div>
      </div>
    </ng-template>
  </swiper>
</form>
