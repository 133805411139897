import { drawerListItem } from './../../../core/interfaces/drawer-list.interface';
import { TranslocoService } from '@ngneat/transloco';
import { FileUploadService } from './../../../core/services/file-upload.service';
import { AlertService } from './../../../core/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailsService } from './../../../core/services/emails.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Location } from '@angular/common';
import { attachment } from 'src/app/core/interfaces/mail-interface';
import { CupertinoPane } from 'cupertino-pane';

@Component({
  selector: 'app-email-composer',
  templateUrl: './email-composer.component.html',
  styleUrls: ['./email-composer.component.sass'],
})
export class EmailComposerComponent implements OnInit,OnDestroy, AfterViewInit {

  toValues: string[] = [];
  ccValues: string[] = [];
  messageValue: string = '';
  subject: string = '';
  freshMode: boolean = true;
  emptyMailHTML: string = '';
  fullMessage: string = '';
  attachments: attachment[] = [];
  postpone: any = false;
  mailUid: number;
  actionMode: string = null;
  loadingSend: boolean = false;
  conceptUid:number;
  conceptInterval:ReturnType<typeof setInterval>;
  saveConceptDrawer:CupertinoPane = null;

  hasSendingProfiles:boolean = false;
  sendingProfiles:any = [];
  sendingProfilesOptions:drawerListItem[] = [];
  selectedSendingProfileId:number = -1;
  selectedSendingProfileName:string;
  showSendingProfileDrawer:boolean = false;

  @ViewChild('mailbody') mailBody: ElementRef;
  @ViewChild('fileinput') fileinput: ElementRef;

  constructor(
    private emailsService: EmailsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private _location: Location,
    private fileUploadService: FileUploadService,
    private router: Router,
    private translationService: TranslocoService
  ) {}


  ngOnInit(): void {
    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.mailUid != parseInt(params['uid'])) {
          // Set this as the current uid
          this.mailUid = parseInt(params['uid']);
          this.freshMode = false;
        }
      }
    });

    this.route.queryParams.subscribe(queryParams => {
      if(queryParams['email']){
        this.toValues.push(queryParams['email'])
      }
    });

    this.saveConceptDrawer = new CupertinoPane('.saveConceptConfirmation', {
      backdrop: true,
      backdropOpacity: 0.7,
      bottomClose: true,
      fastSwipeClose: false,
      events: {
        onDidDismiss: (ev) => {

        },
        onBackdropTap: () => this.saveConceptDrawer.destroy({ animate: true }),
      },
    });

    if (this.router.url.includes('replyall')) {
      this.actionMode = 'replyAll';
    } else if (this.router.url.includes('forward')) {
      this.actionMode = 'forward';
    } else {
      this.actionMode = 'reply';
    }

    this.startConceptSaving();
  }


  getSendingProfiles(){
    this.emailsService.getSendingProfiles().subscribe(profiles => {
      if(profiles && profiles.length){
        this.sendingProfiles = profiles;
        this.hasSendingProfiles = true;
        let defaultProfile = this.sendingProfiles.filter(x => x.isStandardProfile == true);
        if(defaultProfile.length){
          let defaultProfileUid = defaultProfile[0].uid;
          let defaultProfileName = defaultProfile[0].name;
          this.selectedSendingProfileId = defaultProfileUid;
          this.selectedSendingProfileName = defaultProfileName;
        }else{
          this.selectedSendingProfileName = this.translationService.translate('emails.noSendingProfileSelected');
        }
        this.createDrawerList();
      }else{
        this.hasSendingProfiles = false;
      }
      this.loadHtmlBody();
    });
  }

  ngAfterViewInit(): void {
    this.getSendingProfiles();
  }

  createDrawerList(){
    this.sendingProfilesOptions = [{
      title: this.translationService.translate('emails.noSendingProfileSelected'),
      icon: '<i class="fa-solid fa-file-signature"></i>',
      uid: '-1'
    }];
    this.sendingProfiles.forEach(element => {
      this.sendingProfilesOptions.push({
        title: element.name,
        icon: '<i class="fa-solid fa-file-signature"></i>',
        uid: element.uid
      })
    });
  }

  onNewSendingProfileSelected(newProfileUid:number){
    if(newProfileUid != this.selectedSendingProfileId){
      this.selectedSendingProfileId = newProfileUid;
      if(newProfileUid != -1){
        this.selectedSendingProfileName = this.sendingProfiles.filter(x => x.uid == newProfileUid)[0].name;
      }else{
        this.selectedSendingProfileName = this.translationService.translate('emails.noSendingProfileSelected');
      }

      this.loadHtmlBody();
    }
  }

  loadHtmlBody(){
    if (this.mailUid) {
      this.onGetMessageTemplate();
    } else {
      this.onGetEmptyMessageTemplate();
    }
  }

  startConceptSaving(){
    this.conceptInterval = setInterval(() => {
      this.onSaveConcept();
    }, 5000);
  }

  onNavigateBack(){
    if(this.conceptUid){

      this.saveConceptDrawer.present({
        animate: true,
      });

    }else{
      this._location.back();
    }
  }

  onClickSaveConcept(){
    this.onSaveConcept();
    clearInterval(this.conceptInterval);
    this._location.back();
  }

  onClickRemoveConcept(){
    clearInterval(this.conceptInterval);
    this.emailsService.deleteEmail(this.conceptUid).subscribe(res => {
      this._location.back();
    });
  }

  onSaveConcept(){
    if(this.messageValue != '' || this.subject != '' || this.attachments.length > 0){

      this.wrapMessage();

      const attachmentArray = [];
      if(this.attachments){
        for (let attachment of this.attachments) {
          if (attachment.ouid != '') {
            attachmentArray.push({
              storageFileId: attachment.ouid,
            });
          }
        }
      }


      this.emailsService.saveAsConcept(
          this.conceptUid,
          this.toValues,
          this.ccValues,
          this.subject,
          this.fullMessage,
          attachmentArray
        )
        .subscribe((response) => {
          if (response) {
            this.conceptUid = parseInt(response.result);
          }
        });


    }
  }

  wrapMessage(){
    this.fullMessage = this.emptyMailHTML.replace(
      '<!--BODY-->',
      this.messageValue
    );
  }


  onAddAttachment() {
    let el: HTMLElement = this.fileinput.nativeElement;
    el.click();
  }

  onFileSelected(files: FileList) {
    const file = files[0];
    const filename = file.name;

    this.attachments.push({ name: filename, ouid: '' });
    let last_item_in_array = this.attachments.length - 1;
    this.fileUploadService.uploadFile(file).subscribe((response) => {
      this.attachments.splice(last_item_in_array, 1);
      this.attachments.push(response[filename]);
    });

    this.fileinput.nativeElement.value = '';
  }

  removeAttachment(index: number) {
    this.attachments.splice(index, 1);
  }

  onGetEmptyMessageTemplate() {
    this.emailsService.getEmptyMessage(this.selectedSendingProfileId).subscribe((response) => {
      if (response) {
        this.emptyMailHTML = response.result.htmlMessage;
        this.onLoadBodyInIframe();
      }
    });
  }

  onGetMessageTemplate() {
    if (this.actionMode == 'replyAll') {
      this.emailsService
        .getReplyAllMessage(this.mailUid, this.selectedSendingProfileId)
        .subscribe((response) => {
          if (response) {
            this.emptyMailHTML = response.result.htmlMessage;

            if(!this.toValues.length){
              for (let toItem of response.result.To) {
                this.toValues.push(toItem.email);
              }
            }

            if(!this.ccValues.length){
              for (let ccItem of response.result.Cc) {
                this.ccValues.push(ccItem.email);
              }
            }

            this.subject = response.result.subject;

            this.onLoadBodyInIframe();
          }
        });
    } else if (this.actionMode == 'forward') {
      this.emailsService
        .getForwardMessage(this.mailUid, this.selectedSendingProfileId)
        .subscribe((response) => {
          if (response) {
            this.emptyMailHTML = response.result.htmlMessage;
            this.subject = response.result.subject;
            if(response.result.Attachments){
              this.attachments = Object.keys(response.result.Attachments).map(key => (
                {
                  name: response.result.Attachments[key].filename,
                  ouid: response.result.Attachments[key].storageFileId,
                  mimeType: response.result.Attachments[key].contentType,
                  size: response.result.Attachments[key].size
                }
              ));
            }
            this.onLoadBodyInIframe();
          }
        });
    } else if (this.actionMode == 'reply') {
      this.emailsService.getReplyMessage(this.mailUid, this.selectedSendingProfileId).subscribe((response) => {
        if (response) {
          this.emptyMailHTML = response.result.htmlMessage;

          this.toValues = [response.result.To[0].email];
          this.subject = response.result.subject;

          if(response.result.Attachments){
            this.attachments = response.result.Attachments;
          }

          this.onLoadBodyInIframe();
        }
      });
    }
  }

  onLoadBodyInIframe() {
    if(this.mailBody.nativeElement.contentWindow){
      var thedoc = this.mailBody.nativeElement.contentWindow.document;
      thedoc.open();
      thedoc.write(this.emptyMailHTML);
      thedoc.close();
      thedoc.body.style.padding = '0';
      thedoc.body.style.margin = '0';
      thedoc.body.style.fontFamily = 'Open sans, sans-serif';
      thedoc.body.style.fontSize = '14px';
      thedoc.body.style.lineHeight = '24px';
      thedoc.body.style.wordBreak = 'break-word';
      thedoc.getElementById('editorAanhef').remove();

      this.mailBody.nativeElement.height = thedoc.body.scrollHeight + 'px';
    }
  }

  onSendMail() {
    // Send the message wrapped within the full HTML or without it
    this.wrapMessage();

    // An email needs at least 1 receiver and at least some text
    if (this.toValues.length > 0 && this.messageValue.length > 0) {
      if (this.subject.length > 0) {
        this.sendMail();
      } else {
        // ASK THE USER IF THEY ARE SURE THEY SEND THE MESSAGE WITHOUT AN SUBJECT
      }
    } else {
      if (this.toValues.length < 1) {
        this.alertService.error(this.translationService.translate('emails.messageForgotToAddReceiver'));
      } else {
        this.alertService.error(this.translationService.translate('emails.messageForgotToAddMessage'));
      }
    }
  }

  onSendMailPostponed(date: Date) {
    this.postpone = date.toISOString();
    this.onSendMail();
  }

  sendMail() {
    this.loadingSend = true;

    const attachmentArray = [];
    for (let attachment of this.attachments) {
      if (attachment.ouid != '') {
        attachmentArray.push({
          storageFileId: attachment.ouid,
        });
      }
    }

    clearInterval(this.conceptInterval);

    this.emailsService
      .sendMail(
        this.toValues,
        this.ccValues,
        this.subject,
        this.fullMessage,
        attachmentArray,
        this.postpone
      )
      .subscribe((response) => {
        if (response) {
          // Reset the postpone variable
          this.postpone = false;
          this.loadingSend = false;
          this.alertService.success(this.translationService.translate('emails.messageSendSuccesfully'));

          if(this.conceptUid){
            this.emailsService.deleteEmail(this.conceptUid).subscribe(res => {
              this._location.back();
            });
          }else{
            this._location.back();
          }


        }
      });
  }

  ngOnDestroy(){
    clearInterval(this.conceptInterval);
  }
}
