<div class="widget noPaddingRight" *transloco="let t; read: 'dashboard'">
  <h2>{{t('remindersWidgetTitle') | capitalize}}</h2>

  <div class="reminders">
    <div class="reminder" *ngFor="let reminder of reminders" [routerLink]="['/reminders/' + reminder.uid]">
      <div class="reminder-date"><i class="fa-solid fa-bell"></i>{{reminder.dateTime | customDate}}</div>
      <div class="reminder-title">{{reminder.title | truncate:[30, '...']}}</div>
      <div class="reminder-description" [innerHTML]="reminder.description | stripHTML | truncate:[100, '...']"></div>
    </div>
  </div>

  <app-no-results *ngIf="!reminders.length" [text]="t('remindersWidgetNoRemindersFound')" (click)="onNoResultsClick()"></app-no-results>

</div>
