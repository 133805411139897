import { TranslocoService } from '@ngneat/transloco';
import { CachingService } from './../../core/services/caching.service';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { StoredqueryService } from 'src/app/core/services/storedquery.service';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-storedqueries-drawer',
  templateUrl: './storedqueries-drawer.component.html',
  styleUrls: ['./storedqueries-drawer.component.sass']
})

export class StoredqueriesDrawerComponent implements OnInit, OnDestroy {

  /*
    These inputs are telling us which entity, title, and drawerState is being used
  */
  @Input() handle:string = '';
  @Input() title:string = 'Stored queries';
  @Input() open:boolean = false;
  /*
    These outputs are emitting the filter that needs to be used and if the drawer should be shown yes/no
  */
  @Output() openClose: EventEmitter<boolean> = new EventEmitter();
  @Output() filterData: EventEmitter<any> = new EventEmitter();
  /*
    This is the title of the filter we are showing as a button text
  */
  currentFilter:string = 'Filter';
  /*
    The default filter config
  */
  defaultFilter:any = null;
  hasDefaultFilter:boolean = false;
  defaultFilterActive:boolean = false;
  /*
    This is telling us which filter is being used
  */
  activeUid:number = 0;
  /*
    The combined stored query object
  */
  storedQueries = [
    {
      name: 'My own filters',
      storedQueries: null
    },
    {
      name: 'Global filters',
      storedQueries: null
    }
  ];
  /*
    Something to save the subs in so we can unsub from them when this component is gone
  */
  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  /*
    Using these booleans we can check if all requests are done
  */
  load1Complete:boolean = false;
  load2Complete:boolean = false;
  load3Complete:boolean = false;
  checkComplete:boolean = false;


  constructor(private translationService: TranslocoService, private storedqueryService: StoredqueryService,private cachingService: CachingService,private readonly tracker: MatomoTracker) {

    this.storedQueries[0].name = this.translationService.translate('storedqueries.myOwnFilters');
    this.storedQueries[1].name = this.translationService.translate('storedqueries.globalFilters');
    this.title = this.translationService.translate('storedqueries.storedQueries');
    this.currentFilter = this.translationService.translate('storedqueries.currentFilter');
  }

  /*
    When the component is loaded we will first load all stored queries
  */
  ngOnInit(): void {
    this.loadStoredQueries();
  }
  /*
    The personal, personal default and global filters are loaded.
  */
  loadStoredQueries(){
    this.$subscription1 = this.storedqueryService.getStoredQueriesPersonal(this.handle).subscribe(response => {
      if(response){
        this.storedQueries[0].storedQueries = response;
        this.load1Complete = true;
        this.checkIfAllIsLoaded();
      }
    });
    this.$subscription2 = this.storedqueryService.getStoredQueriesGlobal(this.handle).subscribe(response => {
      if(response){
        this.storedQueries[1].storedQueries = response;
        this.load2Complete = true;
        this.checkIfAllIsLoaded();
      }
    });
    this.$subscription3 = this.storedqueryService.getDefaultFilter(this.handle).subscribe(response => {
      if(response && response.length !== 0){
        if(response.filter){
          this.hasDefaultFilter = true;
          this.defaultFilter = response.filter;
        }
        this.load3Complete = true;
        this.checkIfAllIsLoaded();
      }
    });
  }

  /*
    When all loads are done we can retrieve which filter to use
  */
  checkIfAllIsLoaded(){
    if(this.load1Complete && this.load2Complete && this.load3Complete && this.checkComplete == false && this.defaultFilter){
      // Mark this check as complete so it wont trigger multiple times
      this.checkComplete = true;
      // When there is no filter currently active & There is at least some data for the default filter and the default filter isnt currently being used go on
      if(this.activeUid == 0 && this.hasDefaultFilter && this.defaultFilterActive == false){
        // When there is a filter saved in local storage we can use that
        if(this.getLastFilter()){

        }else{
        // Otherwise we are going to load the default filter
          this.onDefaultFilterSelect();
        }
      }
    }else{
      // When there is no stored query saved in local storage and there is no default filter data we can simply show all list items
      if(this.load1Complete && this.load2Complete && this.load3Complete && this.checkComplete == false && this.defaultFilter == null){
        // Mark this check as complete so it wont trigger multiple times
        this.checkComplete = true;
        this.filterData.emit({
          dummy: {
            value: 1
          }
        });
      }
    }
  }
  // This will close this component from the parent
  onClose(){
    this.openClose.emit(false);
  }
  // This will close this component from the parent
  onOpen(){
    this.tracker.trackGoal(8);
    this.openClose.emit(true);
  }
  // When a storedquery item is clicked we can emit it's data and save that it was last clicked
  onItemSelect(sqtItem:any){
    // By now we know that the item clicked isnt the default filter
    this.defaultFilterActive = false;
    // If the filter wasnt active already we go on
    if(this.activeUid != sqtItem.uid){
      // Lets set the activeUid to this filter uid
      this.activeUid = sqtItem.uid;
      // Emit the filter data so that the list can be filtered upon these parameters
      this.filterData.emit(sqtItem.data.DATA);
      // Lets update the button name
      this.currentFilter = sqtItem.name;
      // Now lets close the drawer as the user probably is interested in the list now
      setTimeout(() => {
        this.openClose.emit(false);
      }, 200)
      // And lets save the filter in localstorage
      this.saveCurrentFilter();
    }else{
    // When this filter was active already we can reset to empty
      this.onResetFilters();
    }
  }

  // When we like to select or fallback to default filter behaviour
  onDefaultFilterSelect(){
    // When there is a default filter and it's not already active we can go on
    if(this.hasDefaultFilter && this.defaultFilterActive == false){
      // The default filter has an uid of 1
      this.activeUid = 1;
      // The boolean is set to true
      this.defaultFilterActive = true;
      // We can emit it's filter data
      this.filterData.emit(this.defaultFilter);
      // We can update the button name
      this.currentFilter = this.translationService.translate('storedqueries.defaultFilter');
      // Now lets close the drawer as the user probably is interested in the list now
      setTimeout(() => {
        this.openClose.emit(false);
      }, 200)
      // Save in local storage that we wanted to see the default filter
      this.saveCurrentFilter();
    }else if(this.defaultFilterActive == true && this.hasDefaultFilter){
      // There was no default filter data or it was already clicked
      this.onResetFilters();
    }
  }

  // When resetting the filters for whatever reason
  onResetFilters(){
    // We set the boolean to false
    this.defaultFilterActive = false;
    // We set activeUid back to 0 again
    this.activeUid = 0;
    // Lets emit this OWS dummy data
    this.filterData.emit({
      dummy: {
        value: 1
      }
    });
    // Lets instead of showing a selected filter name on the button just show the text Filter
    this.currentFilter = this.translationService.translate('storedqueries.currentFilter');
    // Now lets close the drawer as the user probably is interested in the list now
    setTimeout(() => {
      this.openClose.emit(false);
    }, 200)
    // Reset the last saved filter
    this.saveCurrentFilter();
  }

  // When saving in local storage
  saveCurrentFilter(){
    if(this.activeUid !== null){
      let cacheKey = this.cachingService.createCacheKey('lastStoredQuery', {val: this.handle});
      this.cachingService.createCache(cacheKey, this.activeUid.toString());
    }
  }

  // When retrieving the last saved query
  getStoredFilterUid(){
    let cacheKey = this.cachingService.createCacheKey('lastStoredQuery', {val: this.handle});
    return this.cachingService.getCacheByKey(cacheKey);
  }

  // This will retrieve the last known filter clicked and also tell if it was succesful
  getLastFilter(){
    let lastFilter = this.getStoredFilterUid();
    if(lastFilter){
      // When the last saved filter is 1 it will need to show the default filter
      if(lastFilter == '1'){
          // When we are currently not showing any filtered data at all we can proceed
          if(this.activeUid === 0){
            this.onDefaultFilterSelect();
          }
      }else if(lastFilter == '0'){
        // There is a saved filter with uid 0 which means show EMPTY
        this.onResetFilters();
      }else{
        // We have some filter UID so let's get its filter data from the stored query object
        let storedQueryItemSearch = null;
        this.storedQueries.forEach(function (storedQuery){
          if(storedQuery.storedQueries.find(({ uid }) => uid == lastFilter)){
            storedQueryItemSearch = storedQuery.storedQueries.find(({ uid }) => uid == lastFilter)
          }
        });
        // If we found the last saved query data we can simply trigger this filter
        if(storedQueryItemSearch){
          this.onItemSelect(storedQueryItemSearch);
        }
      }
      return true;
    }else{
      return false;
    }
  }
  // When this component is gone we can unsub
  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
  }

}
