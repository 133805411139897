import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ReminderService } from './../../core/services/reminder.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.sass']
})
export class RemindersComponent implements OnInit, OnDestroy {

  /*We keep variables seperate for past and future reminders*/
  pastReminders:any = [];
  currentPagePast:number = 1;
  showLoadMoreButtonPast = false;

  /*Search results for the search header*/
  searchResults: Subject<any> = new Subject<any>();

  /*We keep variables seperate for past and future reminders*/
  futureReminders:any = [];
  currentPageFuture:number = 1;
  showLoadMoreButtonFuture = false;

  // Define the icon
  floatingActionIcon = '<i class="fa-solid fa-sharp fa-plus"></i>';

  // Loading state
  remindersLoading:boolean = false;

  // The slider
  config: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = ['Past', 'Future'];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    }
  };

  // Subs
  $subscription1:Subscription;
  $subscription2:Subscription;
  $subscription3:Subscription;
  $subscription4:Subscription;

  // Define the slider
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(private reminderService: ReminderService, private router: Router, private translationService: TranslocoService) {
    this.loadTabTranslations();
  }


  ngOnInit(): void {
    this.loadPastReminders();
    this.loadFutureReminders();
  }


  loadPastReminders(){
    this.remindersLoading = true;
    this.$subscription2 = this.reminderService.getPastReminders(this.currentPagePast).subscribe(resData => {
      if(resData){
        if(resData.items.length < 20){
          this.showLoadMoreButtonPast = false;
        }else{
          this.showLoadMoreButtonPast = true;
        }
        if(this.pastReminders.find(o => o.page === this.currentPagePast)){
          this.pastReminders.find((o:{page:number, items:[]}, i:number) => {
            if (o.page === this.currentPagePast) {
                this.pastReminders[i] = resData;
                return true;
            }else{
              return false;
            }
          });
        }else{
          this.pastReminders.push(resData);
        }

        this.remindersLoading = false;
      }
    })
  }

  loadFutureReminders(){
    this.remindersLoading = true;
    this.$subscription3 = this.reminderService.getFutureReminders(this.currentPageFuture).subscribe(resData => {
      if(resData){
        if(resData.items.length < 20){
          this.showLoadMoreButtonFuture = false;
        }else{
          this.showLoadMoreButtonFuture = true;
        }
        if(this.futureReminders.find(o => o.page === this.currentPageFuture)){
          this.futureReminders.find((o:{page:number, items:[]}, i:number) => {
            if (o.page === this.currentPageFuture) {
                this.futureReminders[i] = resData;
                return true;
            }else{
              return false;
            }
          });
        }else{
          this.futureReminders.push(resData);
        }

        console.log(this.futureReminders);

        this.remindersLoading = false;
      }
    })
  }

  onNextPagePast(){
    if(this.remindersLoading == false){
      this.currentPagePast++;
      this.loadPastReminders();
    }
  }

  onNextPageFuture(){
    if(this.remindersLoading == false){
      this.currentPageFuture++;
      this.loadFutureReminders();
    }
  }

  onSingleActionClicked(){
    this.router.navigate(['/reminders/compose']);
  }

  onSearchInput(searchInput:string){

    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }

    // There should be at least 2 characters
    if (searchInput.length > 2) {
      this.$subscription4 = this.reminderService
        .getSearchResults(searchInput)
        .subscribe((resData) => {
          if (resData) {
            this.searchResults.next(resData);
          } else {
            this.searchResults.next([]);
          }
        });
    } else {
      this.searchResults.next([]);
    }
  }


  loadTabTranslations(){
    this.$subscription1 = this.translationService.selectTranslate('reminders.history').subscribe(res => {
      // Get the translation values for the tabs
      let tabs = [
        this.translationService.translate('reminders.history'),
        this.translationService.translate('reminders.future')
      ];
      // Set the new renderBullet function for the new translations
      this.config.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + tabs[index] + '</span>';
        },
      };
    });
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    if(this.$subscription3){
      this.$subscription3.unsubscribe();
    }
    if(this.$subscription4){
      this.$subscription4.unsubscribe();
    }
  }


}
