import { Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LoaderService } from './loader.service';
import { CachingService } from './caching.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AlertService } from './alert.service';
import { User } from '../auth/user.model';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  current_user:User;

  current_requests:any = [];
  offline:boolean = false;

  public readonly store: Record<string, Observable<HttpEvent<any>>> = {};

  constructor(private readonly injector: Injector,private authService: AuthService, private loaderService: LoaderService, private alertService: AlertService){
    this.authService.user.subscribe(data => {
      if(data){
        this.current_user = data;
      }
    });
  }

  handleError(){

  }

  updateLoaderService(methodArray:string[]){
    this.loaderService.loadingMethods = methodArray;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Now we know this is an POST with an OWS service and is called to the API url
    if(req.method == 'POST' && req.headers.get('ows-method')){

      // Lets wrap the body inside an object OWS understands
      req = req.clone({
        headers: req.headers,
        body: {
          jsonrpc: "2.0",
          method: req.headers.get('ows-method'),
          params: req.body,
          id: 1
        }
      });

      // Only add session id when the user is logged in
      if(
        req.headers.get('ows-method') != 'login' &&
        req.headers.get('ows-method') != 'Otys.Services.MultiService.execute' &&
        req.headers.get('ows-method') != 'getTunixIsActiveValue' &&
        req.headers.get('ows-method') != 'Otys.Services.LoginOptionsService.getLoginOptions' &&
        req.headers.get('ows-method') != 'Otys.Services.LoginOptionsService.getAzuretokenStatus'
      ){
        // Add the SESSION id to the params array when logged in
        if(this.current_user.sessionId){
          req.body.params.unshift(this.current_user.sessionId)
        }

      }
    }

    // Check for an already active request
    if(this.current_requests.includes(req.headers.get('ows-method'))){
      // Return empty to cancel the request
      //return EMPTY;
    }else{
      this.current_requests.push(req.headers.get('ows-method'));
    }

    this.loaderService.loadingMethods = this.current_requests;

    return next.handle(req).pipe(
      tap(async event => {
        if(event instanceof HttpResponse){

          const index = this.current_requests.indexOf(req.headers.get('ows-method'));
          if (index > -1) {
            this.current_requests.splice(index, 1);
          }

          this.loaderService.loadingMethods = this.current_requests;

          // The user is logged out
          if(event.body.hasOwnProperty('error') && event.body.error.message == "Access denied; please login again" || event.body.hasOwnProperty('error') && event.body.error.message == "Access denied; please login first"){
            // The session is expired so we logout the user
            const translateService = this.injector.get(TranslocoService);

            this.alertService.error(translateService.translate('shared.sessionExpiredMessage'));
            this.authService.logout();
          }else{
            if(req.headers.get('ows-cachable') == 'true'){
              const cachingService = this.injector.get(CachingService);
              const unique = req.body.params;
              unique.shift();
              const cacheKey = cachingService.createCacheKey(req.headers.get('ows-method'), unique);
              if(req.headers.get('ows-cachettl')){
                let ttlHours = parseInt(req.headers.get('ows-cachettl'));
                cachingService.createCache(cacheKey,event.body, ttlHours)
              }else{
                cachingService.createCache(cacheKey,event.body)
              }
            }
          }
        }
      }),catchError(error => {
        if(error instanceof HttpErrorResponse){
          const index = this.current_requests.indexOf(req.headers.get('ows-method'));
          if (index > -1) {
            this.current_requests.splice(index, 1);
          }
          this.loaderService.loadingMethods = this.current_requests;
        }
        return throwError(error);
       })
    )
  }
}
