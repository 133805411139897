import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable()
export class DashBoardContactsService{

  constructor(private http: HttpClient){}

  getContactList(searchTerm:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.PersonSearchService.findAllWidget';
    // Define the body of the request (params)
    const requestBody = [
      searchTerm,
      [
        "User",
        "RelationContact",
        "Candidate"
      ]
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    // Retrieve live data
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }
}
