<app-email-composer-header (addAttachment)="onAddAttachment()" [mailTitle]="subject" (mailGoBack)="onNavigateBack()" (mailSend)="onSendMail()" (mailSendPostponed)="onSendMailPostponed($event)" [loadingSend]="loadingSend"></app-email-composer-header>
<div class="page">
  <app-email-composer-form
    [toValues]="toValues"
    [ccValues]="ccValues"
    [subjectValue]="subject"
    (toValuesChange)="toValues = $event"
    (ccValuesChange)="ccValues = $event"
    (subjectValueChange)="subject = $event"
  ></app-email-composer-form>

  <div class="fileinput">
    <input type="file" (change)="onFileSelected($event.target.files)" #fileinput>
  </div>

  <div class="attachments" *ngIf="attachments.length">
    <div class="attachment" *ngFor="let attachment of attachments;let i = index" [ngClass]="{'loading' : attachment.ouid == '' }" (click)="removeAttachment(i)">
      <i class="fa-solid fa-paperclip"></i>
      {{attachment.name}}
      <img src="/assets/images/spinner.svg" width="14" height="14" class="loading_spinner">
    </div>
  </div>

  <app-email-composer-message
    (htmlMessage)="messageValue = $event"
  ></app-email-composer-message>

  <div class="sending-profile-select" *ngIf="hasSendingProfiles" (click)="showSendingProfileDrawer = true">
    <span *transloco="let t; read: 'emails'"><i class="fa-solid fa-file-signature"></i>{{t('composerUseSignature')}}</span>
    <div class="sending-profile-name">
      {{selectedSendingProfileName}}
    </div>
  </div>

  <app-drawer-component *transloco="let t; read: 'emails'" [drawerOpen]="showSendingProfileDrawer" (onClicked)="onNewSendingProfileSelected($event)" [drawerTitle]="t('composerUseSignature')" (drawerState)="this.showSendingProfileDrawer = false" [list]="sendingProfilesOptions"></app-drawer-component>

  <div class="space-maker" *ngIf="!hasSendingProfiles">

  </div>

  <div class="htmlBody">
    <iframe #mailbody frameborder="0"></iframe>
  </div>


  <div class="saveConceptConfirmation">
    <div class="saveConceptConfirmation-inner">
      <div class="saveConceptConfirmation-title" *transloco="let t; read: 'emails'">{{t('ConceptConfirmationTitle')}}</div>
      <div class="buttonwrap-saveConceptConfirmation">
        <button class="concept-save green" (click)="onClickSaveConcept()" *transloco="let t; read: 'emails'">{{t('saveAsConcept')}}</button>
        <button class="concept-remove red" (click)="onClickRemoveConcept()" *transloco="let t; read: 'emails'">{{t('removeConcept')}}</button>
      </div>
    </div>
  </div>

</div>
