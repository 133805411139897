<div class="authwrap" *transloco="let t; read: 'auth'">
  <div class="square" [ngStyle]="{'background-image': 'url(/assets/images/' + backgroundImage + ')'}">
    <div class="logobox" (click)="clickLogo()">
      <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37"><g transform="translate(-169 -30)"><path d="M18.5,0A18.5,18.5,0,1,1,0,18.5,18.5,18.5,0,0,1,18.5,0Z" transform="translate(169 30)" fill="#ffd300"/><g transform="translate(174.521 40.306)"><g transform="translate(0 0)"><path d="M733.038,254.593a2.518,2.518,0,0,0-.954-2.035,2.361,2.361,0,0,0-1.835-.338,2.268,2.268,0,0,0-1.513,1.009c-1.3,2.173-4.73,7.227-8.571,7.227s-7.289-5.054-8.586-7.227a2.262,2.262,0,0,0-1.507-1.009,2.365,2.365,0,0,0-1.841.338,2.628,2.628,0,0,0-.608,3.335,14.159,14.159,0,0,0,25.072,0A2.548,2.548,0,0,0,733.038,254.593Z" transform="translate(-707.274 -251.794)" fill="#fff"/><circle cx="2.873" cy="2.873" r="2.873" transform="translate(10.009)" fill="#fff"/><circle cx="2.873" cy="2.873" r="2.873" transform="translate(10.009 13.369)" fill="#fff"/></g></g></g></svg>
      <div class="logo-text">On the Go!</div>
    </div>
  </div>
  <div class="loginform" #formwrap>

    <h1 *ngIf="!azureMode">{{t('title')}}</h1>
    <h1 *ngIf="azureMode">{{t('loginWithAzure')}}</h1>

    <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!loading && !azureMode"  [ngClass]="{'hidden': loading || azureMode}">

      <div class="form-questions">
        <div class="question">
          <label>{{t('username')}}</label>
          <input type="text" name="username" id="username" autocomplete="username" #userNameInput required ngModel>
        </div>
        <div class="question">
          <label for="password">{{t('password')}}</label>
          <div class="password-wrapper">
            <div class="eye" (click)="showPassword = !showPassword">
              <i class="fa-solid fa-eye-slash" *ngIf="!showPassword"></i>
              <i class="fa-solid fa-eye" *ngIf="showPassword"></i>
            </div>
            <input [type]="showPassword ? 'text' : 'password'" name="password" id="password" autocomplete="current-password" #passwordInput required ngModel>
          </div>
        </div>
      </div>

      <div class="formerrors" *ngIf="submittedWithoutData || incorrectCredentials || generalError || tunixDenied || noRights || incorrectSessionIdInUrl">
        <div class="formerror" *ngIf="submittedWithoutData"><i class="fa-solid fa-circle-exclamation"></i>{{t('messageFillInUsernamePassword')}}</div>
        <div class="formerror" *ngIf="incorrectCredentials"><i class="fa-solid fa-circle-exclamation"></i>{{t('messageIncorrectCredentials')}}</div>
        <div class="formerror" *ngIf="tunixDenied"><i class="fa-solid fa-circle-exclamation"></i>{{t('messageTunixDenied')}}</div>
        <div class="formerror" *ngIf="generalError"><i class="fa-solid fa-circle-exclamation"></i>{{t('messageGenericError')}}</div>
        <div class="formerror" *ngIf="noRights"><i class="fa-solid fa-circle-exclamation"></i>{{t('messageOnTheGoDisabled')}}</div>
        <div class="formerror" *ngIf="incorrectSessionIdInUrl"><i class="fa-solid fa-circle-exclamation"></i>{{t('messageIncorrectSessionIdInUrl')}}</div>
      </div>

      <div class="form-buttons">
        <button type="submit" id="authSignInRegular">{{t('loginButton')}}</button>
        <div class="button_divider"><div></div><span>{{t('loginOr')}}</span><div></div></div>
        <button type="button" id="authPickAzureOption" class="secondary" (click)="onClickAzureLogin()"><i class="fa-brands fa-windows"></i>{{t('loginWithAzure')}}</button>
      </div>
    </form>

    <form #azureForm="ngForm" (ngSubmit)="onAzureSubmit(azureForm)" *ngIf="azureMode && !loading">

      <div class="form-questions">
        <div class="question">
          <label>{{t('azurePrefix')}}</label>
          <input type="text" name="prefix" id="prefix" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" required [(ngModel)]="prefix">
          <div class="explain">
            {{t('azureExplain')}}
          </div>
        </div>
      </div>

      <div class="formerrors" *ngIf="azureInvalidPrefix || azureNoPrefix || incorrectSessionIdInUrl">
        <div class="formerror" *ngIf="azureNoPrefix"><i class="fa-solid fa-circle-exclamation"></i>{{t('azureNoPrefix')}}</div>
        <div class="formerror" *ngIf="azureInvalidPrefix"><i class="fa-solid fa-circle-exclamation"></i>{{t('azureInvalidPrefix')}}</div>
        <div class="formerror" *ngIf="incorrectSessionIdInUrl"><i class="fa-solid fa-circle-exclamation"></i>{{t('messageIncorrectSessionIdInUrl')}}</div>
      </div>

      <div class="form-buttons">
        <button type="submit" id="authAzureSignIn">{{t('loginButton')}}</button>
        <div class="button_divider"><div></div><span>{{t('loginOr')}}</span><div></div></div>
        <button type="button" class="secondary" (click)="onGoBackToRegularLogin()">{{t('azureGoBack')}}</button>
      </div>
    </form>

    <a href="keyapp://auth" *ngIf="tunixEnabled">
      <div class="loading" *ngIf="loading">
        <img src="/assets/images/tunix.png" width="50" height="50" class="tunix_logo">
        <img src="/assets/images/spinner.svg" width="70" height="70" class="loading_spinner">
      </div>
    </a>

    <div class="loading" *ngIf="!tunixEnabled && loading">
      <img src="/assets/images/spinner.svg" width="70" height="70" class="loading_spinner">
    </div>

  </div>
</div>
