import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})

export class CapitalizePipe implements PipeTransform {

  transform(value: string) {
    let str:string = value;
    str = str[0].toUpperCase() + str.slice(1);
    return str;
  }
}
