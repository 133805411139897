import { Injectable } from "@angular/core";
import hash from 'object-hash';

@Injectable()
export class HashingService{
  constructor(){}
  public createHash(object:object){
    return hash(object);
  }
}
