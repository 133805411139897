import { storedQueryData, transformedStoredQueryData } from './../interfaces/stored-query.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class StoredQueryTransformerService {

  transform(storedQueryData:storedQueryData):transformedStoredQueryData{

    let transformedData:transformedStoredQueryData = {
      actonomy: {},
      conditional: {
        type: "AND",
        items: [

        ]
      }
    };


    let keys = Object.keys(storedQueryData);
    for(let key in keys){
      let name = keys[key];
      let item = storedQueryData[name];
      if(item['class']){
        let conditionalItem = {
          type: "COND",
          field: item.condition.field,
          op: item.condition.op,
          param: item.condition.param
        };
        transformedData.conditional.items.push(conditionalItem);
      }else{
        transformedData.actonomy[name] = item;
      }
    }

    if(!transformedData.conditional.items.length){
      transformedData.conditional = false;
    }

    return transformedData;
  }


}
