import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getInitials'
})

export class GetInitialsPipe implements PipeTransform {

  transform(value: string) {
    var input = value.replace(/[^a-z0-9 -]/gi, '');
    if(this.onlySpaces(input)){
        var acronym = "";
    }else{
        var str = input.split('|')[0];
        var matches = str.match(/\b(\w)/g);
        if(matches.length > 2){
            matches.splice(2);
        }
        var acronym = matches.join('');
    }
    return acronym;
  }

  onlySpaces(str:string) {
    if(str != null){
        return str.trim().length === 0;
    }else{
        return true
    }
  }
}
