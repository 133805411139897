import { CachingService } from './../../../core/services/caching.service';
import { Subscription } from 'rxjs';
import { CalendarService } from './../../../core/services/calendar.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { day } from './../../../core/interfaces/calendar-interface';
import SwiperCore, { EffectCards, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import * as moment from 'moment';

SwiperCore.use([EffectCards]);

@Component({
  selector: 'app-day-slider',
  templateUrl: './day-slider.component.html',
  styleUrls: ['./day-slider.component.sass']
})
export class DaySliderComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  @Input() dayPopupActive:boolean;
  @Input() dayPopupDays:day[];
  @Input() dayPopDay:number;

  @Output() monthNav: EventEmitter<string> = new EventEmitter();
  @Output() onCloseDayClicked: EventEmitter<any> = new EventEmitter();

  config: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0
  };

  planmode:boolean = false;
  dayStart:string = '03:00';
  dayEnd:string = '22:00';
  daySlots:string[] = [];
  today:Date = new Date();
  swiperDoneLoading:boolean = false;
  $settingSubscription:Subscription;
  useSwiper:boolean = false;
  changesLoading = false;

  constructor(private calendarService: CalendarService, private cachingService: CachingService) {
    if(localStorage.getItem('calendarPlanMode') === 'true'){
      this.planmode = true;
    }else{
      this.planmode = false;
    }
  }

  ngOnInit(): void {
    this.getTimeSettings();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let change = changes[propName];
        switch (propName) {
          case 'dayPopupDays':
            // Convert prev and current value to JSON so we can easily compare for differences
            let jsonString1 = JSON.stringify(change.previousValue);
            let jsonString2 = JSON.stringify(change.currentValue);
            if(jsonString1 != jsonString2){
              this.changesLoading = true;
              setTimeout(() => {
                this.changesLoading = false;
              }, 3500);
            }
            if(this.dayPopupDays.length){
              this.useSwiper = true;
            }

            break;
          case 'dayPopupActive':
            if(change.currentValue == false){
              this.cachingService.removeCacheByString('calendar_history');
            }
            break;
        }
      }
    }


    this.config.initialSlide = this.dayPopDay;
    if(this.swiper){
      this.swiper.swiperRef.slideTo(this.dayPopDay,0, false);
    }
  }

  onSwiperLoaded(){
    this.swiperDoneLoading = true;
  }

  getTimeSettings(){
    this.$settingSubscription = this.calendarService.getTimeSettings().subscribe(response => {
      if(response){
        this.dayStart = response.start_time;
        this.dayEnd = response.end_time;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        this.createSlots(yyyy + '-' + mm + '-' + dd);
      }
    });
  }

  createSlots(dateString:string){
    this.daySlots = this.intervals(dateString + ' ' + this.dayStart , dateString + ' ' + this.dayEnd);
  }

  intervals(startString:string, endString:string) {
    var start = moment(startString, 'YYYY-MM-DD hh:mm a');
    var end = moment(endString, 'YYYY-MM-DD hh:mm a');
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    var result = [];
    var current = moment(start);
    while (current <= end) {
        result.push(current.format('YYYY-MM-DD HH:mm'));
        current.add(60, 'minutes');
    }
    return result;
  }

  onCloseDay(){
    this.onCloseDayClicked.emit();
  }

  onUpdatePlanMode(newval:boolean){
    this.planmode = newval;
    if(newval){
      var stringVal = 'true';
    }else{
      var stringVal = 'false';
    }
    localStorage.setItem('calendarPlanMode', stringVal);
  }

  onIndexChange(index:any){
    if(this.swiperDoneLoading){
      let cacheKey = this.cachingService.createCacheKey('calendar_slider', {val: 'index'});
      this.cachingService.createCache(cacheKey, index);
    }
  }

  onNavigateMonth(mode:string){
    this.monthNav.emit(mode);
  }

  ngOnDestroy(): void {
    if(this.$settingSubscription){
      this.$settingSubscription.unsubscribe();
    }
  }


}
