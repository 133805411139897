import { Subject, Subscription } from 'rxjs';
import { AuthService } from './../../core/auth/auth.service';
import { Component, OnInit, AfterViewInit, AfterContentInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/auth/user.model';
import { CandidateService } from 'src/app/core/services/candidate.service';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.sass']
})
export class CandidatesComponent implements AfterContentInit, OnDestroy{

  candidatesListing:any = [];
  showLoadMoreButton:boolean = false;
  candidatesLoading:boolean = false;
  currentPage:number = 1;
  current_user:User;
  searchResults: Subject<any> = new Subject<any>();

  showStoredQueries:boolean = false;
  storedQueryData:any;

  $subscription1:Subscription;
  $subscription2:Subscription;

  constructor(private candidateService: CandidateService, private authService: AuthService) {
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }

  ngAfterContentInit(): void {
    //this.loadcandidates();
  }

  onStoredQueryClicked(storedQueryData:any){
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    this.storedQueryData = storedQueryData;
    this.currentPage = 1;
    this.candidatesListing = [];
    this.loadcandidates();
  }

  loadcandidates(){
    this.candidatesLoading = true;
    this.$subscription1 = this.candidateService.getCandidatesList(this.currentPage,this.storedQueryData).subscribe(resData => {
      if(resData){
        if(resData.items.length < 20){
          this.showLoadMoreButton = false;
        }else{
          this.showLoadMoreButton = true;
        }
        if(this.candidatesListing.find(o => o.page === this.currentPage)){
          this.candidatesListing.find((o:{page:number, items:[]}, i:number) => {
            if (o.page === this.currentPage) {
                this.candidatesListing[i] = resData;
                return true;
            }else{
              return false;
            }
          });
        }else{
          this.candidatesListing.push(resData);
        }

        this.candidatesLoading = false;

        this.addPhotoUrlToCandidates();
      }
    });
  }


  onNextPage(){
    if(this.candidatesLoading == false){
      this.currentPage++;
      this.loadcandidates();
    }
  }

  addPhotoUrlToCandidates(){
    var sess = this.current_user.sessionId;
    this.candidatesListing.forEach(function(page){
      page.items.forEach(function(candidate){

        // Create a fullname field
        candidate.fullname = '';
        if(candidate.Person.firstName)
          candidate.fullname += candidate.Person.firstName;
        if(candidate.Person.infix)
          candidate.fullname += ' ' + candidate.Person.infix;
        if(candidate.Person.lastName)
          candidate.fullname += ' ' + candidate.Person.lastName;

        if(candidate.Person.photoFileName){
          candidate.photoUrl = environment.fileServiceDownload + '?ouid=' + candidate.uid + '&class=CandidatePhoto&sessionId=' + sess;
        }else{
          candidate.photoUrl = null;
        }
      });
    });
  }


  onSearchInput(searchInput:string){
    if(this.$subscription2){
      this.$subscription2.unsubscribe();
    }
    var sess = this.current_user.sessionId;
    // There should be at least 2 characters
    if (searchInput.length > 2) {
      this.$subscription2 = this.candidateService
        .getSearchResults(searchInput)
        .subscribe((resData) => {
          if (resData) {

            resData.items.forEach(function(candidate){
              // Create a fullname field
              candidate.fullname = '';
              if(candidate.Person.firstName)
                candidate.fullname += candidate.Person.firstName;
              if(candidate.Person.infix)
                candidate.fullname += ' ' + candidate.Person.infix;
              if(candidate.Person.lastName)
                candidate.fullname += ' ' + candidate.Person.lastName;

              if(candidate.Person.photoFileName){
                candidate.photoUrl = environment.fileServiceDownload + '?ouid=' + candidate.uid + '&class=CandidatePhoto&sessionId=' + sess;
              }else{
                candidate.photoUrl = null;
              }
            });

            this.searchResults.next(resData);
          } else {
            this.searchResults.next([]);
          }
        });
    } else {
      this.searchResults.next([]);
    }
  }

  ngOnDestroy(): void {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
  }

}
