<header>
  <div class="detail-page-header" *ngIf="taskLoaded">
    <div class="topbar">
      <app-back-button></app-back-button>
      <div class="status" *ngIf="task.userStatus">
        <div class="dot" [style.backgroundColor]="'#' + task.userStatusColor">

        </div>
        {{task.userStatus}}
      </div>
      <app-favorite-star [entity]="28" [uid]="taskId"></app-favorite-star>
    </div>
    <div class="main-info">
      <div class="detail-image">
        <i class="fa-solid fa-files"></i>
      </div>
      <div class="text-info">
        <h1>{{task.title}}</h1>
      </div>
    </div>
  </div>
  <div class="tabs-nav">
    <div class="swiper-pagination"></div>
  </div>
</header>
<div class="tabswrap" *ngIf="taskLoaded">
  <swiper #swiper [config]="config" *transloco="let t; read: 'tasks'">
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">

            <div class="task_description" [innerHTML]="task.description | sanitizeHtml" *ngIf="task.description">
            </div>

            <app-detailpage-item-value [label]="t('owner')" [value]="task.owner"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('manager')" [value]="task.manager"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('assignedTo')" [value]="task.assignedTo"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('createdBy')" [value]="task.createdBy"></app-detailpage-item-value>

            <app-detailpage-item-value [label]="t('lastModified')" [value]="task.lastModified|customDate"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('modifiedBy')" [value]="task.modifiedBy"></app-detailpage-item-value>

            <app-detailpage-item-value [label]="t('taskGroup')" [value]="task.taskGroup"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('userGroup')" [value]="task.userGroup"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('type')" [value]="task.type"></app-detailpage-item-value>
            <app-detailpage-item-value [label]="t('status')" [value]="task.status"></app-detailpage-item-value>







          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
            <div class="list comment-list">
              <div class="list-item" *ngFor="let comment of comments.slice().reverse()">
                <div class="list-image">
                  <i class="fa-sharp fa-solid fa-comment"></i>
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-left">
                    <div class="list-item-title medium">
                      {{comment.user}}
                    </div>
                    <div class="list-item-fulldescription" [innerHTML]="comment.comment">
                    </div>
                  </div>
                  <div class="list-item-content-right">
                    <div class="top">
                      <span class="list-item-date">{{comment.datetime|customDate}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-no-results *ngIf="comments.length == 0"></app-no-results>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <app-dossier-tab [dossierEntity]="28" [entityUid]="taskId"></app-dossier-tab>
        </div>
      </ng-template>
  </swiper>
</div>
