import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Injectable()
export class FavoriteService {

  $subscription:Subscription;

  constructor(
    private http: HttpClient
  ) {}

  getFavoriteInformation(entity:any, uid:any) {
    // Define the OWS method
    const requestMethod = 'Otys.Services.ExtSearchBookmarkService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        condition: {
          type: "AND",
          invert: false,
          items: [
            {
              type: "COND",
              field: "entityId",
              op: "EQ",
              param: entity
            },
            {
              type: "COND",
              field: "recordUid",
              op: "EQ",
              param: uid
            }
          ],
          limit: 1
        }
      }
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  deleteFavorite(uid:any){
    // Define the OWS method
    const requestMethod = 'Otys.Services.ExtSearchBookmarkService.delete';
    // Define the body of the request (params)
    const requestBody = [
      uid
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  addFavorite(entity:number, uid:any){

    let entityAsString = entity.toString();

    // Define the OWS method
    const requestMethod = 'Otys.Services.ExtSearchBookmarkService.add';
    // Define the body of the request (params)
    const requestBody = [
      {
        entityId: entityAsString,
        recordUid: uid
      }
    ];

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

}
