<app-header
  *transloco="let t; read: 'dashboard'"
  [title]="hour >= 0 && hour < 12 ? t('GoodMorning', { value: firstname }) : this.hour >= 12 && this.hour <= 17 ? t('GoodAfternoon', { value: firstname }) : t('GoodEvening', { value: firstname })"
  [searchPlaceHolder]="t('searchTitle')"
  listType="dashboardContacts"
  (onSearchInput)="onSearchInput($event)"
  [searchResults]="searchResults.asObservable()"
></app-header>

<div class="dashboard-widgets" *ngIf="dashboardReady">
  <app-mailwidget [style.order]="mailWidgetIndex" *ngIf="dashboardLayout[mailWidgetIndex].enabled"></app-mailwidget>
  <app-reminderwidget [style.order]="reminderWidgetIndex" *ngIf="dashboardLayout[reminderWidgetIndex].enabled"></app-reminderwidget>
  <app-candidatewidget *ngIf="canShowCandidateWidget && dashboardLayout[candidateWidgetIndex].enabled" [style.order]="candidateWidgetIndex"></app-candidatewidget>
</div>

<app-action-button-multiple
  (onClick)="onSingleActionClicked($event)"
  (onButtonClicked)="onButtonClicked($event)"
  [icon]="floatingActionIcon"
  [actionButtons]="actionButtons"
  id="dashboardActionButtonMultiple"
></app-action-button-multiple>
