<div class="control control-reply" (click)="onMailAction('reply')">
  <i class="fa-solid fa-reply"></i>
</div>
<div class="control control-remove" (click)="onMailAction('remove')">
  <i class="fa-solid fa-trash"></i>
</div>
<div class="control control-more" (click)="onUpdateDrawerState(true)">
  <i class="fa-solid fa-ellipsis-vertical"></i>
</div>

<app-drawer-component
  *transloco="let t; read: 'emails'"
  [drawerOpen]="moreOptionsShow"
  [list]="drawerList"
  [drawerTitle]="t('moreActions')"
  (onClicked)="onMailAction($event)"
  (drawerState)="onUpdateDrawerState($event)"
></app-drawer-component>
