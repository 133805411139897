import { TranslocoService } from '@ngneat/transloco';
import { AlertService } from './../../../core/services/alert.service';
import { FormControl, FormGroup } from '@angular/forms';
import { EmailsService } from 'src/app/core/services/emails.service';
import { Component, Input } from '@angular/core';
import { autoResponderData } from 'src/app/core/interfaces/mail-interface';
import { formatDate } from '@angular/common';
import * as Editor from 'ckeditor/build/ckeditor';

@Component({
  selector: 'app-autoresponder',
  templateUrl: './autoresponder.component.html',
  styleUrls: ['./autoresponder.component.sass']
})

export class AutoresponderComponent{

  @Input() title = '';

  loaded:boolean = false;

  autoResponderData:autoResponderData;

  autoresponderForm:FormGroup = new FormGroup({
    onderwerp: new FormControl(''),
    startdatum: new FormControl(),
    einddatum: new FormControl(),
    body: new FormControl()
  });

  public Editor = Editor;

  active:boolean = false;

  constructor(private emailsService: EmailsService, private alertService: AlertService, private translationService: TranslocoService) {
    this.loadAutoResponder();
  }

  loadAutoResponder(){
    this.emailsService.getAutoresponder().subscribe(res => {
      if(res && res.result && res.result.length){
        this.autoResponderData = res.result[0];
        this.autoresponderForm.controls['body'].setValue(this.autoResponderData.body);
        this.autoresponderForm.controls['onderwerp'].setValue(this.autoResponderData.onderwerp);
        this.autoresponderForm.controls['startdatum'].setValue(
          formatDate(
            new Date(parseInt(this.autoResponderData.startdatum) * 1000),
            'yyyy-MM-dd',
            'en'
            )
        )
        this.autoresponderForm.controls['einddatum'].setValue(
          formatDate(
            new Date(parseInt(this.autoResponderData.einddatum) * 1000),
            'yyyy-MM-dd',
            'en'
            )
        )
        if(this.autoResponderData.active_flag == '0'){
          this.active = false;
        }else{
          this.active = true;
        }
      }else{
        // There's no autoresp set yet for this user, so lets add some default fields
        this.autoresponderForm.controls['startdatum'].setValue(
          formatDate(
            new Date(),
            'yyyy-MM-dd',
            'en'
            )
        )
        this.autoresponderForm.controls['einddatum'].setValue(
          formatDate(
            new Date(),
            'yyyy-MM-dd',
            'en'
            )
        )
      }
      this.loaded = true;
    });
  }

  onSubmit(){
    let formdata = this.autoresponderForm.getRawValue();
    let startdatum = new Date(formdata.startdatum);
    let startdatumUnix = Math.floor(startdatum.getTime() / 1000);
    let einddatum = new Date(formdata.einddatum);
    let einddatumUnix = Math.floor(einddatum.getTime() / 1000);
    if(this.autoresponderForm.valid){
      if(this.autoResponderData && this.autoResponderData){
        this.emailsService.setAutoresponder(this.autoResponderData.id, startdatumUnix, einddatumUnix, formdata.onderwerp, formdata.body, this.active).subscribe(res => {
          if(res){
            this.alertService.success(this.translationService.translate('shared.updatedFieldSuccess'));
          }
        });
      }else{
        this.emailsService.createAutoresponder(startdatumUnix, einddatumUnix, formdata.onderwerp, formdata.body, this.active).subscribe(res => {
          if(res){
            this.alertService.success(this.translationService.translate('shared.updatedFieldSuccess'));
            this.loadAutoResponder();
          }
        });
      }
    }else{
      this.alertService.error(this.translationService.translate('shared.fillRequiredFields'));
    }
  }

}
