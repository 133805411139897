import { drawerListItem } from 'src/app/core/interfaces/drawer-list.interface';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-email-flag-drawer',
  templateUrl: './email-flag-drawer.component.html',
  styleUrls: ['./email-flag-drawer.component.sass']
})
export class EmailFlagDrawerComponent{

  @Output() flagDrawerOpenState: EventEmitter<any> = new EventEmitter();
  @Output() newFlag: EventEmitter<any> = new EventEmitter();


  @Input() flagDrawerOpen:boolean = false;
  @Input() currentFlag:number;

  list:drawerListItem[] = [
    {
      title: 'emails.flags.noFlag',
      icon: '<i class="fa-solid fa-flag"></i>',
      uid: '0',
      iconColour: '#B5B5CC',
      useTransloco: true
    },
    {
      title: 'emails.flags.important',
      icon: '<i class="fa-solid fa-flag"></i>',
      uid: '1',
      iconColour: '#F15F79',
      useTransloco: true
    },
    {
      title: 'emails.flags.toDo',
      icon: '<i class="fa-solid fa-flag"></i>',
      uid: '2',
      iconColour: '#FCAA3F',
      useTransloco: true
    },
    {
      title: 'emails.flags.later',
      icon: '<i class="fa-solid fa-flag"></i>',
      uid: '3',
      iconColour: '#202020',
      useTransloco: true
    },
    {
      title: 'emails.flags.finished',
      icon: '<i class="fa-solid fa-flag"></i>',
      uid: '4',
      iconColour: '#06BEB6',
      useTransloco: true
    },
    {
      title: 'emails.flags.pending',
      icon: '<i class="fa-solid fa-flag"></i>',
      uid: '5',
      iconColour: '#40ADFA',
      useTransloco: true
    },{
      title: 'emails.flags.personal',
      icon: '<i class="fa-solid fa-flag"></i>',
      uid: '6',
      iconColour: '#B923B4',
      useTransloco: true
    }
  ];

  constructor() {}


  onDrawerItemClicked(id){
    this.newFlag.emit(id);
  }

  onUpdateDrawerState(state){
    this.flagDrawerOpenState.emit(state);
  }

}
