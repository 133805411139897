import { TranslocoService } from '@ngneat/transloco';
import { AlertService } from 'src/app/core/services/alert.service';
import { FileUploadService } from '../../../core/services/file-upload.service';
import { ActivatedRoute } from '@angular/router';
import { DossierService } from 'src/app/core/services/dossier.service';
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dossier-createnote',
  templateUrl: './dossier-createnote.component.html',
  styleUrls: ['./dossier-createnote.component.sass']
})
export class DossierCreatenoteComponent implements OnInit{

  noteTypes:any;
  entityUid:string;
  entityType:number;
  noteSubject:string;
  noteType:string;
  noteOnTop:boolean = false;
  notePrivate:boolean = false;
  noteDescription:string;
  noteLoading:boolean = false;
  @ViewChild('typeSelect') typeSelect:ElementRef;


  constructor(private dossierService: DossierService, private route: ActivatedRoute, private fileUploadService: FileUploadService, private alertService: AlertService,private _location: Location, private translationService: TranslocoService) {
    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.entityUid != params['uid']) {
          // Set this as the current uid
          this.entityUid = params['uid'];
        }
      }

      if (params['entity']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.entityType != parseInt(params['entity'])) {
          // Set this as the current uid
          this.entityType = parseInt(params['entity']);
        }
      }

    });

  }


  ngOnInit(): void {
    this.getNoteTypes();
  }

  getNoteTypes(){

    if(this.entityType == 1){
      this.dossierService.vacancyNoteTypes.subscribe(noteTypes => {
        if(noteTypes){
          this.noteTypes = noteTypes;
        }
      })
    }else if(this.entityType == 2){
      this.dossierService.candidateNoteTypes.subscribe(noteTypes => {
        if(noteTypes){
          this.noteTypes = noteTypes;
        }
      })
    }else if(this.entityType == 3){
      this.dossierService.relationNoteTypes.subscribe(noteTypes => {
        if(noteTypes){
          this.noteTypes = noteTypes;
        }
      })
    }else if(this.entityType == 4){
      this.dossierService.relationContactNoteTypes.subscribe(noteTypes => {
        if(noteTypes){
          this.noteTypes = noteTypes;
        }
      })
    }else if(this.entityType == 28){
      this.dossierService.taskNoteTypes.subscribe(noteTypes => {
        if(noteTypes){
          this.noteTypes = noteTypes;
        }
      })
    }


  }

  onToggleAlwaysOnTop(newval:boolean){
    this.noteOnTop = newval;
  }

  onTogglePrivate(newval:boolean){
    this.notePrivate = newval;
  }

  onTypeSelected(){
    this.noteType = this.typeSelect.nativeElement.value;
  }

  onSaveNote(){
    if(!this.noteLoading){
      if(this.noteSubject){
        if(this.noteType){
          this.noteLoading = true;
          this.dossierService.createNote(this.entityType,this.noteSubject, this.noteType, this.notePrivate, this.noteOnTop, this.noteDescription, this.entityUid).subscribe(res => {
            if(res && res.result){
              this.alertService.success(this.translationService.translate('dossier.messageSuccesfullyCreatedDocument'));
              this._location.back();
              this.noteLoading = false;
            }else{
              this.alertService.error(this.translationService.translate('dossier.messageSomethingWentWrong'));
            }
          });
        }else{
          this.alertService.error(this.translationService.translate('dossier.messagePleaseFillInTheDocumentType'));
        }
      }else{
        this.alertService.error(this.translationService.translate('dossier.messagePleaseAddASubject'));
      }
    }
  }

}
