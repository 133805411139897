import { User } from './../auth/user.model';
import { AuthService } from './../auth/auth.service';
import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Injectable()
export class CalendarService{

  private calendarItems = new BehaviorSubject<any>(null);
  private calendarUsers = new BehaviorSubject<any>(null);
  private calendarTypes = new BehaviorSubject<any>(null);
  private recurrenceTypes = new BehaviorSubject<any>(null);
  private websites = new BehaviorSubject<any>(null);
  private calendarSettings = new BehaviorSubject<any>(null);

  $subscription: Subscription;
  current_user:User;

  constructor(private http: HttpClient, private cachingService: CachingService, private authService: AuthService, private readonly tracker: MatomoTracker){
    this.authService.user.subscribe(res => {
      this.current_user = res;
    })
  }


  getCalendarItems(start:Date, end:Date, forUsers:number[]){

    start.setHours(0o0,0o0,0o0);
    end.setHours(23,59,59);

    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getList';
    // Define the body of the request (params)
    const requestBody = [
    {
      what: {
        uid: 1,
        title: 1,
        startDateTime: 1,
        endDateTime: 1,
        typeColor: 1,
        wholeDay: 1,
        userId: 1,
        initials: 1,
        invitationsCount: 1,
        travelTimeTo: 1,
        travelTimeFrom: 1
      },
      condition: {
        type: "AND",
        invert: false,
        items: [
          {
            type: "COND",
            field: "startDateTime",
            op: "LT",
            param: end.toISOString()
          },
          {
            type: "COND",
            field: "endDateTime",
            op: "GE",
            param: start.toISOString()
          },
          {
            type: "AND",
            invert: false,
            items: [
              {
                type: "OR",
                invert: false,
                items: [
                  {
                    type: "COND",
                    field: "userId",
                    op: "EQ",
                    param: forUsers
                  }
                ]
              }
            ]
          }
        ]
      },
      limit: 1000,
      includeInvitedItems: true
    }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.calendarItems.next(cached_data);
    }

    // When there is no cache, and the cache is older then 1 minute, we can do an OWS call
    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 1){
      // Retrieve live data
      this.$subscription = this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        return this.calendarItems.next(resData)
      });

    }

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.calendarItems.asObservable();
  }

  getSearchResults(searchInput:string, forUsers:number[]){

    let d = new Date();
    let dyear = d.getFullYear();
    let dmonth = d.getMonth();
    let dday = d.getDate();
    let todayPlus5Years = new Date(dyear + 5, dmonth, dday);

    let f = new Date();
    let fyear = d.getFullYear();
    let fmonth = d.getMonth();
    let fday = d.getDate();
    let todayMinus20Years = new Date(fyear - 20, fmonth, fday);

    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          title: 1,
          startDateTime: 1,
          endDateTime: 1,
          description: 1,
          wholeDay: 1,
          userId: 1,
          initials: 1,
          invitationsCount: 1
        },
        condition: {
          type: "AND",
          invert: false,
          items: [
            {
              type: "COND",
              field: "startDateTime",
              op: "LT",
              param: todayPlus5Years.toISOString()
            },
            {
              type: "COND",
              field: "endDateTime",
              op: "GE",
              param: todayMinus20Years.toISOString()
            },
            {
              type: "COND",
              field: "userId",
              op: "EQ",
              param: forUsers
            },
            {
              type: "COND",
              field: "title",
              op: "LIKE",
              param: "%"+searchInput+"%"
            }
          ]
        },
        limit: 100,
        sort: {
          startDateTime: "DESC"
        },
        includeInvitedItems: true
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  getItemsByDateTime(start:string, end:string){

    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getList';
    // Define the body of the request (params)
    const requestBody = [
    {
      what: {
        uid: 1,
        title: 1,
        startDateTime: 1,
        endDateTime: 1,
        typeColor: 1,
        wholeDay: 1
      },
      condition: {
        type: "AND",
        invert: false,
        items: [
          {
            type: "COND",
            field: "startDateTime",
            op: "LT",
            param: end
          },
          {
            type: "COND",
            field: "endDateTime",
            op: "GE",
            param: start
          },
          {
            type: "AND",
            invert: false,
            items: [
              {
                type: "OR",
                invert: false,
                items: [
                  {
                    type: "COND",
                    field: "userId",
                    op: "EQ",
                    param: [
                      this.current_user.id
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      limit: 1000,
      includeInvitedItems: true
    }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  getUsers(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getUserGroupList';
    // Define the body of the request (params)
    const requestBody:any = [];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.calendarUsers.next(cached_data);
    }

    // When there is no cache, and the cache is older then 30 minutes, we can do an OWS call
    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 30){

      // Retrieve live data
      this.$subscription = this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        return this.calendarUsers.next(resData)
      });

    }

    // Return the mailWidget as an observable so other components can listen to any updates
    return this.calendarUsers.asObservable();
  }


  bindInvite(eventUid:number, invite:any){
    // Define the OWS method
    const requestMethod = 'Otys.Services.InvitationService.add';
    // Define the body of the request (params)

    const requestBody:any = [
      {
        calendarItemUid: eventUid,
        autoEmail: true
      }
    ];

    if(invite.entityId == 4){
      // Relation contact
      requestBody[0].contactPersonUid = invite.uid;
    }else if(invite.entityId == 2){
      // Candidate
      requestBody[0].candidateUid = invite.uid;
    }else{
      // user
      requestBody[0].userId = invite.uid;
    }

    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };
    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  addEvent(eventObject:any){

    let event = eventObject;
    if(event.travelTimeFrom){
      event.travelTimeFrom = event.travelTimeFrom + ':00';
    }
    if(event.travelTimeTo){
      event.travelTimeTo = event.travelTimeTo + ':00';
    }

    if(!event.recurrenceType){
      delete event.recurrenceType;
      delete event.repeatCount;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.add';
    // Define the body of the request (params)
    const requestBody:any = [
      event
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.cachingService.removeCacheByString('CalendarService.getList');

    this.tracker.trackGoal(2);

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  saveEvent(eventUid:number, eventObject:any, newInvites:any){

    let event = eventObject;
    if(event.travelTimeFrom){
      event.travelTimeFrom = event.travelTimeFrom + ':00';
    }
    if(event.travelTimeTo){
      event.travelTimeTo = event.travelTimeTo + ':00';
    }

    if(!event.recurrenceType){
      delete event.recurrenceType;
      delete event.repeatCount;
    }

    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.updateAll';
    // Define the body of the request (params)
    const requestBody:any = [
      eventUid,
      event,
      {
        applyFuture: false,
        updateMethod: "updateAll"
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.cachingService.removeCacheByString('CalendarService.getList');

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  deleteEvent(eventUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.delete';
    // Define the body of the request (params)
    const requestBody:any = [
      eventUid
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.cachingService.removeCacheByString('CalendarService.getList');

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }

  deleteAllEvents(eventUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.deleteAll';
    // Define the body of the request (params)
    const requestBody:any = [
      eventUid
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.cachingService.removeCacheByString('CalendarService.getList');

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  respondToInvite(eventUid:number,userId:number, status:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.respondToInvitationByCalendar';
    // Define the body of the request (params)
    const requestBody:any = [
      eventUid,
      userId,
      status
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    this.cachingService.removeCacheByString('CalendarService.getList');

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }



  getEvent(eventUid:number){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getDetail';
    // Define the body of the request (params)
    const requestBody:any = [
      eventUid,
      {
        uid: 1,
        title: 1,
        userCreated: 1,
        user: 1,
        userId: 1,
        typeId: 1,
        invitationFrom: 1,
        Invitations: 1,
        startDateTime: 1,
        endDateTime: 1,
        description: 1,
        wholeDay: 1,
        location: 1,
        initials: 1,
        travelTimeTo: 1,
        travelTimeFrom: 1,
        invitationsCount: 1,
        hasFutureEvents: 1
      },
      null
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'false'
      },
    };

    return this.http.post<any>(environment.owsUrl, requestBody, requestHeaders);
  }


  getTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getOptionLists';
    // Define the body of the request (params)
    const requestBody:any = [
      [
        "typeId"
      ]
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.calendarTypes.next(cached_data.result.typeId);
    }

    // When there is no cache, and the cache is older then 30 minutes, we can do an OWS call
    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 30){
      // Retrieve live data
      this.$subscription = this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        return this.calendarTypes.next(resData.result.typeId)
      });
    }

    // Return the types as an observable so other components can listen to any updates
    return this.calendarTypes.asObservable();
  }

  getRecurrenceTypes(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getOptionLists';
    // Define the body of the request (params)
    const requestBody:any = [
      [
        "recurrenceType"
      ]
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.recurrenceTypes.next(cached_data.result.recurrenceType);
    }

    // When there is no cache, and the cache is older then 30 minutes, we can do an OWS call
    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 5000){
      // Retrieve live data
      this.$subscription = this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        return this.recurrenceTypes.next(resData.result.recurrenceType)
      });
    }

    // Return the types as an observable so other components can listen to any updates
    return this.recurrenceTypes.asObservable();
  }

  getWebsites(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.CalendarService.getOptionLists';
    // Define the body of the request (params)
    const requestBody:any = [
      [
        "invitationFrom"
      ]
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.websites.next(cached_data.result.invitationFrom);
    }

    // When there is no cache, and the cache is older then 30 minutes, we can do an OWS call
    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 5000){
      // Retrieve live data
      this.$subscription = this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        return this.websites.next(resData.result.invitationFrom);
      });
    }

    // Return the types as an observable so other components can listen to any updates
    return this.websites.asObservable();
  }


  getTimeSettings(){
    // Define the OWS method
    const requestMethod = 'Otys.Services.SettingsService.get';
    // Define the body of the request (params)
    const requestBody:any = [
      {
        calendar: [
          "start_time",
          "end_time",
          "start_time_default",
          "end_time_default"
        ]
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method' : requestMethod,
        'ows-cachable' : 'true'
      },
    };
    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if(cached_data){
      this.calendarSettings.next(cached_data.result.calendar);
    }

    // When there is no cache, and the cache is older then 30 minutes, we can do an OWS call
    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 30){
      // Retrieve live data
      this.$subscription = this.http.post<any>(environment.owsUrl, requestBody, requestHeaders).subscribe( resData => {
        return this.calendarSettings.next(resData.result.calendar)
      });
    }

    // Return the types as an observable so other components can listen to any updates
    return this.calendarSettings.asObservable();
  }


}
