import { CachingService } from './caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StoredqueryService{

  storedQueriesPersonal = new BehaviorSubject<any>([]);
  storedQueriesGlobal = new BehaviorSubject<any>([]);
  defaultFilter = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient, private cachingService: CachingService){}

  getStoredQueriesPersonal(handle:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AdvancedSearchStoredQueryService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1,
          isGlobal: 1,
          order: 1,
          data: 1,
          dataFormatVersion: 1
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "handle",
              op: "EQ",
              param: handle
            },
            {
              type: "COND",
              field: "vacancyUid",
              op: "EQ",
              param: null
            },
            {
              type: "COND",
              field: "isGlobal",
              op: "EQ",
              param: false
            }
          ]
        },
        sort: {
          order: "ASC",
          name: "ASC"
        },
        limit: 1000
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    this.storedQueriesPersonal.next(null);

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data && cached_data.result) {
      this.storedQueriesPersonal.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 5){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData && resData.result) {
          this.storedQueriesPersonal.next(resData.result);
        }
      });
    }


    return this.storedQueriesPersonal.asObservable();
  }


  getStoredQueriesGlobal(handle:string){
    // Define the OWS method
    const requestMethod = 'Otys.Services.AdvancedSearchStoredQueryService.getList';
    // Define the body of the request (params)
    const requestBody = [
      {
        what: {
          uid: 1,
          name: 1,
          isGlobal: 1,
          order: 1,
          data: 1,
          dataFormatVersion: 1
        },
        condition: {
          type: "AND",
          items: [
            {
              type: "COND",
              field: "handle",
              op: "EQ",
              param: handle
            },
            {
              type: "COND",
              field: "vacancyUid",
              op: "EQ",
              param: null
            },
            {
              type: "COND",
              field: "isGlobal",
              op: "EQ",
              param: true
            }
          ]
        },
        sort: {
          order: "ASC",
          name: "ASC"
        },
        limit: 1000
      }
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    this.storedQueriesGlobal.next(null);

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data && cached_data.result) {
      this.storedQueriesGlobal.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 5){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData && resData.result) {
          this.storedQueriesGlobal.next(resData.result);
        }
      });
    }
    return this.storedQueriesGlobal.asObservable();
  }

  getDefaultFilter(handle:string){

    var entityName = '';

    if(handle == 'HSRELATIONS'){
      entityName = 'Relation';
    }else if(handle == 'HSCANDIDATES'){
      entityName = 'Candidate';
    }else if(handle == 'RELATIONCONTACTS'){
      entityName = 'RelationContact';
    }else if(handle == 'HSVACANCIES'){
      entityName = 'Vacancy';
    }


    // Define the OWS method
    const requestMethod = 'Otys.Services.SavedFilterService.get';
    // Define the body of the request (params)
    const requestBody = [
      entityName
    ];
    // Define the request headers
    const requestHeaders = {
      headers: {
        'ows-method': requestMethod,
        'ows-cachable': 'true'
      },
    };

    this.defaultFilter.next(null);

    // Retrieve cached data (if theres any)
    let cached_data = this.cachingService.getCache(requestMethod, requestBody);
    if (cached_data && cached_data.result) {
      this.defaultFilter.next(cached_data.result);
    }

    if(this.cachingService.getCacheAge(requestMethod, requestBody) === false || this.cachingService.getCacheAge(requestMethod, requestBody) > 5){
      // Retrieve live data
      this.http
      .post<any>(environment.owsUrl, requestBody, requestHeaders)
      .subscribe((resData) => {
        if (resData && resData.result) {
          this.defaultFilter.next(resData.result);
        }
      });
    }
    return this.defaultFilter.asObservable();
  }


}
