import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mail-with-icon',
  templateUrl: './mail-with-icon.component.html',
  styleUrls: ['./mail-with-icon.component.sass']
})
export class MailWithIconComponent{

  @Input() mail:string;

  constructor() { }
}
