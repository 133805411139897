import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-relation-list',
  templateUrl: './relation-list.component.html',
  styleUrls: ['./relation-list.component.sass']
})
export class RelationListComponent{
  @Input() relationsListing:any = [];
  constructor() { }
}
