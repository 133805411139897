<header>
  <div class="detail-page-header" *ngIf="relationLoaded">
    <div class="topbar">
      <app-back-button></app-back-button>
      <div class="status" *ngIf="relation.status">
        {{relation.status.name}}
      </div>
      <app-favorite-star [entity]="3" [uid]="relationUid"></app-favorite-star>
    </div>
    <div class="main-info">
      <div class="detail-image" [ngStyle]="{'background-image': (relation.logoFileName) ? 'url('+relationLogoUrl+')' : ''}">
        <ng-template  [ngIf]="!relation.logoFileName">
          <span>{{relation.relation|getInitials}}</span>
        </ng-template>
      </div>
      <div class="text-info">
        <h1>{{relation.relation}}</h1>
        <div class="location">
          {{relation.city}}
        </div>
      </div>
    </div>
  </div>
  <div class="tabs-nav">
    <div class="swiper-pagination"></div>
  </div>
</header>

<div class="tabswrap" *ngIf="relation">
  <swiper #swiper [config]="config" *transloco="let t; read: 'relations'">
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
            <div class="icon-and-text-vals" *ngIf="relation.phoneNumberMain || relation.email || (relation.Addresses.main.city || relation.Addresses.main.adress || relation.Addresses.main.postcode)">
              <app-phone-number-with-icons [phone]="relation.phoneNumberMain"></app-phone-number-with-icons>
              <app-mail-with-icon [mail]="relation.email"></app-mail-with-icon>
              <app-adress-with-icon [adress]="relation.Addresses.main"></app-adress-with-icon>
            </div>

            <app-detailpage-item-value [label]="t('relationName')" [value]="relation.relation"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="relation.status" [label]="t('status')" [value]="relation.status.name"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="relation.user" [label]="t('connectedConsultant')" [value]="relation.user"></app-detailpage-item-value>
            <app-detailpage-item-value *ngIf="relation.entryDateTime" [label]="t('entryDate')" [value]="relation.entryDateTime|customDate"></app-detailpage-item-value>

            <div class="crmcrits" *ngIf="relation.Criteria.length !== 0">

              <div *ngFor="let item of crmCriterias">
                <div class="critval" *ngIf="item.values.length > 0">
                  <label>{{item.name}}</label>
                  <div class="value">
                    <span *ngFor="let val of item.values">{{val}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <app-dossier-tab [dossierEntity]="3" [entityUid]="relationUid" *ngIf="showDossier"></app-dossier-tab>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="tab">
          <div class="tab-inner">
            <div class="relation-contacts-list">
              <ng-container *ngFor="let relationContact of relationContacts">
                <div class="list-item list-item-relation" [routerLink]="['/relation-contacts/' + relationContact.uid]" *ngIf="relationContact.fullname">
                  <div class="list-image">
                    <span *ngIf="relationContact.fullname">
                      {{ relationContact.fullname | getInitials}}
                    </span>
                  </div>
                  <div class="list-item-content">
                    <div class="list-item-content-left">
                      <div class="list-item-subject">{{relationContact.fullname}}</div>
                      <div class="list-item-location">{{relationContact.Person.emailPrimary}}</div>
                    </div>
                    <div class="list-item-content-right">
                      <div class="top">
                        <div class="list-item-status" *ngIf="relationContact.jobTitle">{{relationContact.jobTitle}}</div>
                      </div>
                      <div class="bottom">
                        <div class="relconphone">{{relationContact.phone}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              </div>
              <app-no-results *ngIf="!relationContacts.length"></app-no-results>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngIf="this.current_user.modules.includes('Vacancies')">
        <div class="tab">
          <div class="tab-inner">
            <div class="relation-contacts-list">
              <div class="list-item list-item-relation" *ngFor="let relationVacancy of relationVacancies" [routerLink]="['/vacancies/' + relationVacancy.uid]">
                <div class="list-image">
                  <i class="fa-solid fa-briefcase"></i>
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-left">
                    <div class="list-item-subject">{{relationVacancy.title}}</div>
                    <div class="list-item-location">{{relationVacancy.location}}</div>
                  </div>
                  <div class="list-item-content-right">
                    <div class="top">
                      <div class="list-item-status" *ngIf="relationVacancy.status">{{relationVacancy.status}}</div>
                    </div>
                    <div class="bottom">
                      <div class="job-applycount"><i class="fa-solid fa-user"></i>{{relationVacancy.applyCount}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <app-no-results *ngIf="!relationVacancies.length"></app-no-results>

          </div>
        </div>
      </ng-template>
  </swiper>
</div>
