import { ActionButtonInterface } from '../../core/interfaces/action-button.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-button-multiple',
  templateUrl: './action-button-multiple.component.html',
  styleUrls: ['./action-button-multiple.component.sass']
})

export class ActionButtonMultipleComponent{

  expandOptions:boolean = false;

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter();

  @Input() icon:any;
  @Input() actionButtons:ActionButtonInterface[];

  constructor() { }

  onToggleExpandOptions(){
    this.expandOptions = !this.expandOptions;
  }

  onClicked(event:MouseEvent){
    const canVibrate = window.navigator.vibrate;
    if (canVibrate){
      window.navigator.vibrate(1);
    }
    this.onClick.emit(event);
  }

  onItemClicked(theButton:any){
    this.onButtonClicked.emit(theButton);
    this.expandOptions = false;
  }

}
