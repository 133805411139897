import { TranslocoService } from '@ngneat/transloco';
import { FileUploadService } from './../../../core/services/file-upload.service';
import { AlertService } from './../../../core/services/alert.service';
import { InteractionService } from './../../../core/services/interaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { NgForm } from '@angular/forms';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-fill-in-form',
  templateUrl: './fill-in-form.component.html',
  styleUrls: ['./fill-in-form.component.sass']
})

export class FillInFormComponent implements OnInit {

  formuid:number;
  form:any;
  session:string;
  formTabs:any = [];
  formTabActive:number = 0;
  formFields:any = [];
  formSaveLoading:boolean = false;
  formValue:any = {};
  config: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        let tabs = [];
        return '<span class="' + className + '">' + tabs[index] + '</span>';
      },
    },
  };

  uploadedFiles:any = {};

  relationUid:any;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('relationSelectInput') relationSelectInput:ElementRef;

  @ViewChild('relationContactSelectInput') relationContactSelectInput:ElementRef;
  @ViewChild('fileUidInput') fileUidInput:ElementRef;


  @ViewChild('fimForm') fimForm:NgForm;


  constructor(private route: ActivatedRoute, private interactionService: InteractionService, private alertService: AlertService, private router: Router, private fileUploadService: FileUploadService, private translationService: TranslocoService) {
    // When the component is loaded trough an URL including the uid param in the route, we should read it
    this.route.params.subscribe((params) => {
      if (params['uid']) {
        // If uid is present in the route and it isn't equal to the current uid
        if (this.formuid != parseInt(params['uid'])) {
          // Set this as the current uid
          this.formuid = parseInt(params['uid']);
        }
      }
    });
   }

  ngOnInit(): void {
    this.loadForm();
  }


  loadForm(){
    this.interactionService.getInteractionForm(this.formuid).subscribe(response => {
      if(response && response.result){
        this.form = response.result.form;
        this.session = response.result.sessionId;
        const tabObj = response.result.form.Tabs;
        if(tabObj && tabObj.length != 0){
          this.formTabs = Object.entries(tabObj).map(([key, value]) => ({key, value}));
          let tabCount = 0;
          this.formTabs.map(function(e){
            if(tabCount == 0){
              e.active = true;
            }else{
              e.active = false;
            }
            tabCount++;
          });
        }else{
          this.formTabs = [{
            uid : -1,
            value : {
              uid: -1,
              name: 'General'
            },
            active: true
          }];
        }
        const fieldObj = response.result.form.Fields;
        this.formFields = Object.entries(fieldObj).map(([key, value]) => ({key, value}));
        let tabstrings = [];
        this.formTabs.forEach(function(tab){
          tabstrings.push(tab.value.name);
        });
        this.config = {
          slidesPerView: 1,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              let tabs = tabstrings;
              return '<span class="' + className + '">' + tabs[index] + '</span>';
            },
          },
        };
      }
    });
  }

  onNavToTab(tab:number){
    this.swiper.swiperRef.slideTo(tab,0, false);
  }

  onSubmit(form:NgForm){
    if(form.valid && this.formSaveLoading == false){
      let formControls = form.controls;
      let formControlArray = Object.entries(formControls).map(([key, value]) => ({key, value}));
      let formValObj = {};
      let componentScope = this;
      formControlArray.forEach(function(formControl){
        // Create the value within the object
        formValObj[formControl.key] = formControl.value.value;
        // When theres a file in the file array with this fieldkey we should add the uploaded value
        if(componentScope.uploadedFiles[formControl.key]){
          formValObj[formControl.key] = componentScope.uploadedFiles[formControl.key];
        }
      });
      this.formValue = formValObj;

      this.formSaveLoading = true;
      this.interactionService.submitForm(this.session, this.formValue).subscribe(res => {
        if(res){
          this.interactionService.commitForm(this.session).subscribe(response => {

            this.formSaveLoading = false;
            form.reset();

            if(response.result.addedEntity == 'Vacancy'){
                this.router.navigate(['/vacancies/' + response.result.addedRecordUid])
            }

            if(response.result.addedEntity == 'Relation'){
              this.router.navigate(['/relations/' + response.result.addedRecordUid])
            }

            if(response.result.addedEntity == 'Candidate'){
              this.router.navigate(['/candidates/' + response.result.addedRecordUid])
            }
          });
        }
      });
    }else{
      this.alertService.error(this.translationService.translate('shared.fillRequiredFields'));
    }
  }

  /*When relationContact or Relation question is there we can pass the id to the contact Input*/
  onRelationUid(key:number, newUid:any){
    this.fimForm.form.controls[key].setValue(newUid)
    this.relationUid = newUid;
  }
  onRelationContact(key:number, newUid:any){
    this.fimForm.form.controls[key].setValue(newUid);
  }


  fileUpload(formFieldKey:number ,files: FileList){
    const file = files[0];
    const filename = file.name;

    let attachments = [];

    attachments.push({ name: filename, ouid: '' });
    let last_item_in_array = attachments.length - 1;
    this.fileUploadService.uploadFile(file).subscribe((response) => {
      attachments.splice(last_item_in_array, 1);
      attachments.push(response[filename]);

      this.uploadedFiles[formFieldKey] = response[filename];
      this.uploadedFiles[formFieldKey]['class'] = 'Generic';

      this.fimForm.form.controls[formFieldKey].setValue(response[filename].ouid);
    });

  }

}

