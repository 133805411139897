<div class="adress-with-icon" *ngIf="validobj">
  <div class="iconwrap">
    <a class="icon" href="https://maps.google.com/?q={{urlAdress}}" target="_blank">
      <i class="fa-solid fa-paper-plane"></i>
    </a>
  </div>
  <div class="adress-text">
    <a href="https://maps.google.com/?q={{urlAdress}}" target="_blank">
      {{fulladress}}
    </a>
  </div>
</div>
