<div class="list">
  <div class="listpage page_{{page.page}}" *ngFor="let page of relationsListing;">
    <div class="list-item list-item-relation" *ngFor="let relation of page.items" [routerLink]="['/relations/' + relation.uid]">
      <div class="list-image" [ngStyle]="{'background-image': (relation.logoFileName) ? 'url('+relation.logoUrl+')' : ''}">
        <ng-template [ngIf]="!relation.logoFileName">
          <span *ngIf="relation.relation">
            {{ relation.relation | getInitials}}
          </span>
        </ng-template>
      </div>
      <div class="list-item-content">
        <div class="list-item-content-left">
          <div class="list-item-subject semibold">{{relation.relation}}</div>
          <div class="list-item-subtitle">{{relation.city}}</div>
        </div>
        <div class="list-item-content-right">
          <div class="top">
            <div class="list-item-status" *ngIf="relation.status">{{relation.status.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
